import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { int_img } from "../../../../assets/images/images";
import { Navbar } from "../../../../components/DashboardComponents/Navbar/Navbar";
import { createInterview } from "../../../../redux/actions/jobSeekerActions";
import "../InterviewModule/InterviewModule.css";
import usePracticeInterviewStore from "../../../../zustand/practiceInterviewStore";

const InterviewInstruction = () => {
  const { createPracticeInterview } =
    usePracticeInterviewStore();
  const jobDetails = useSelector((state) => state?.job?.details);
  const { jobId, jobname, companyName, assignment_id } = useParams();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  const startPracticeInterview = async () => {
    try {
      const jobIdNumber =
        typeof jobId === "string" ? jobDetails?.id : parseInt(jobId);
      await createPracticeInterview(token, jobIdNumber);

      // Access the practice interview ID from the Zustand store
      const practiceId =
        usePracticeInterviewStore.getState().practiceInterviewId;

      if (practiceId) {
        await navigate(
          `/applicant-dashboard/related-jobs/${jobId}/start-interview/${practiceId}/ai-practice-interview-guideline/${jobname}/${companyName}/${assignment_id}`
        );
      }
    } catch (error) {
      console.error("Error creating interview:", error);
    }
  };

  const startInterview = async () => {
    try {
      const jobIdNumber =
        typeof jobId === "string" ? jobDetails?.id : parseInt(jobId);
      const data = await dispatch(createInterview(jobIdNumber, token, 1));
      const newInterviewId = data.data.id;

      // Encode jobname and companyName to handle special characters
      const jobname = encodeURIComponent(data.data.job_title);
      const companyName = encodeURIComponent(data.data.company_name);
      navigate(
        `/applicant-dashboard/related-jobs/${jobId}/start-interview/${newInterviewId}/ai-interview-guideline/${jobname}/${companyName}`,
        { state: { data: data.data } }
      );
    } catch (error) {
      console.error("Error creating interview:", error);
    }
  };

  const onClickStart = () => {
    startPracticeInterview();
  };

  const onClickSkip = () => {
    startInterview();
  };

  return (
    <>
      <Navbar />
      <section className="hire_ai_interview max_margin">
        <div className="container-fluid">
          <div className="row">
            <div className="hire_ai_interview_inner">
              <div className="hire_ai_interview_inner_left">
                <h4>Haire AI</h4>
                <span>Welcome to our AI Interview platform! </span>
                <h6>
                  {jobname} - {companyName}
                </h6>
                <ul className="hire_left_btn_sec">
                  <li>
                    <button className="assesment_btn" onClick={onClickStart}>
                      Mock AI Avatar Interview
                    </button>
                  </li>
                  <li>
                    <button className="skip_btn" onClick={onClickSkip}>
                      Skip Mock
                    </button>
                  </li>
                </ul>
              </div>
              <div className="hire_ai_interview_inner_right">
                <img src={int_img} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InterviewInstruction;
