import React, { useEffect, useState } from "react";
import InterviewModuleNav from "../InterviewModuleNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { spin_img } from "../../../../../assets/images/images";
import Modal from "react-bootstrap/Modal";
import CodeEditor from "../../../../CodeEditor/CodeEditor";
import { useNavigate, useParams } from "react-router-dom";
import PracticeSummary from "../AssessmentSummary/PracticeSummary";
import { useDispatch, useSelector } from "react-redux";
import instance from "../../../../../api/instance";
import useCodeEditorStore from "../../../../../zustand/codeEditorStore";
import styles from "./practiceAssessment.module.css";

const PracticeAssessment = () => {
  // get all params from the url with react
  const { jobId, jobname, companyName, assignment_id } = useParams();
  const { code, language, setCode, setLanguage, setOutput } =
    useCodeEditorStore();

  const decodedJobTitle = decodeURIComponent(jobname);
  const decodedCompanyName = decodeURIComponent(companyName);
  const [allQuestions, setAllQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const navData = {
    jobname: decodedJobTitle,
    CompanyName: decodedCompanyName,
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.token);
  // const quizess = useSelector((state) => state?.quizess);
  const [quizess, setQuizess] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutes (300 seconds) for the entire assessment
  const [timerRunning, setTimerRunning] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [attempData, setAttempData] = useState(null);
  const [assessmentResult, setAssessmentResult] = useState(null);
  const [nextLoading, setNextLoading] = useState(false);

  // create attemp for the quiz
  const create_attempt = async (payload) => {
    // payload
    // job: jobId,
    // assignment: assignment_id,
    try {
      const response = await instance.post("/practice/attempts/", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      });

      if (response.status === 200 || response.status === 201) {
        setAttempData(response.data);
      }
    } catch (error) {
      console.error("Error creating company:", error);
    }
  };

  const store_single_answer = async (payload) => {
    //     payload:
    // {
    //     "attempt": 8,
    //     "question": 4,
    //     "answer": "C",
    //     "is_correct": false,
    //     "score": 1.0
    // }
    try {
      const response = await instance.post(`/practice/responses/`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      });

      if (response.status === 200) {
      }
    } catch (error) {
      console.error("Error creating company:", error);
    }
  };
  const check_answer = async (payload) => {
    try {
      const response = await instance.post(`/ai/answer_check/`, payload, {
        // json data
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
      return {
        score: response?.data?.result_json?.score || 0,
        passed: response?.data?.result_json?.is_correct || false,
      };
    } catch (error) {
      console.error("Error creating company:", error);
    }
  };

  const get_quiz_list = async () => {
    try {
      const response = await instance.get(`/practice/latest-assignments/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      if (response.status === 200) {
        if (response?.data && response?.data?.length > 0) {
          setQuizess(response.data[0]);
          if (response.data[0]?.id) {
            create_attempt({
              job: jobId,
              assignment: response.data[0]?.id,
            });
          }
        }
      }
    } catch (error) {
      console.error("Error creating company:", error);
    }
  };

  const get_quiz_result = async () => {
    try {
      const response = await instance.get(
        `/practice/attempts/${attempData?.id}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setShowResult(true);
        handleClose();
        window.scrollTo({ top: 0, behavior: "smooth" });
        setAssessmentResult(response.data);
      }
    } catch (error) {
      setShowResult(true);
      handleClose();
      window.scrollTo({ top: 0, behavior: "smooth" });
      console.error("Error creating company:", error);
    }
  };

  useEffect(() => {
    // call api to get questions
    setCode("");
    setOutput("");
    setLanguage("javascript");
    async function fetchQuizList() {
      if (token) {
        await get_quiz_list();
      }
    }
    fetchQuizList();
  }, [token]);

  useEffect(() => {
    if (quizess) {
      if (quizess?.questions && quizess?.questions?.length > 0) {
        const newQuestions = quizess?.questions?.map((item, index) => {
          let options = null;
          if (item?.question_type === "MCQ") {
            const result = Object.keys(item?.options).map((key) => ({
              id: key,
              value: item?.options[key],
            }));
            options = result;
          }
          return {
            index: index + 1,
            id: item?.id,
            type: item?.question_type,
            question: item?.question,
            code: item?.code,
            options: options,
            answer: item?.correct_answer,
            user_answer: "",
            mark: item?.mark,
            code_score: 0,
            passed: false,
          };
        });
        setAllQuestions(newQuestions);
        setCurrentQuestion(newQuestions[0]);
      }
    }
  }, [quizess]);

  const onCodeChange = (value, language, code_score, passed) => {
    setAllQuestions(
      allQuestions.map((item) => {
        if (item.id === currentQuestion.id) {
          if (code_score) {
            return {
              ...item,
              user_answer: value,
              lang: language,
              code_score,
              passed,
            };
          }
          return {
            ...item,
            user_answer: value,
            lang: language,
          };
        }
        return item;
      })
    );
    setCurrentQuestion({
      ...currentQuestion,
      user_answer: value,
      lang: language,
    });
  };

  // // Timer logic
  // useEffect(() => {
  //   if (timerRunning && timeLeft > 0) {
  //     const timer = setInterval(() => {
  //       setTimeLeft((prevTime) => prevTime - 1);
  //     }, 1000);

  //     return () => clearInterval(timer);
  //   } else if (timeLeft === 0) {
  //     handleSubmit(); // Automatically submit when time runs out
  //   }
  // }, [timerRunning, timeLeft]);

  // Format time into MM:SS
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // Handle next question
  const nexthandel = async () => {
    setNextLoading(true);
    let code_score = 0;
    let passed = false;
    try {
      if (currentQuestion?.type === "CODING") {
        if (code) {
          let data = await check_answer({
            question_details: {
              question: currentQuestion?.question,
              question_type: currentQuestion?.type,
              correct_answer: currentQuestion?.answer,
              inputs: [],
              expected_output: "Array of user objects with status 'active'",
              test_cases: [],
            },
            user_answer: code,
            total_marks: currentQuestion?.mark,
          });
          code_score = data?.score;
          passed = data?.passed;
        }
        onCodeChange(code, language, code_score, passed);
      }
      if(currentQuestion?.type === "MCQ") {
      store_single_answer({
        attempt: attempData?.id,
        question: currentQuestion?.id,
        answer: currentQuestion?.user_answer,
        is_correct:
          currentQuestion?.answer === currentQuestion?.user_answer
            ? "True"
            : "False",
        score:
          currentQuestion?.answer === currentQuestion?.user_answer
            ? currentQuestion?.mark
            : 0,

            
      });
    }

    
     else if(currentQuestion?.type === "CODING") {
      store_single_answer({
        attempt: attempData?.id,
        question: currentQuestion?.id,
        answer: code,
        is_correct: passed ? "True"
        : "False",
        score: code_score,
      });
    }
      if (currentStep < allQuestions.length) {
        let nextQuestion = allQuestions.find(
          (v) => v.index === currentStep + 1
        );
        setCode(nextQuestion?.user_answer);
        setOutput("");
        setLanguage(nextQuestion?.lang || "javascript");
        setCurrentStep(currentStep + 1);
        setCurrentQuestion(nextQuestion);
      }
      setNextLoading(false);
    } catch (error) {
      console.error("Error creating company:", error);
      setNextLoading(false);
    }
  };

  // Handle previous question
  const prevhandel = () => {
    if (currentStep > 1) {
      if (currentQuestion?.type === "CODING") {
        onCodeChange(code, language);
      }
      let prevQuestion = allQuestions.find((v) => v.index === currentStep - 1);
      setCode(prevQuestion?.user_answer);
      setOutput("");
      setLanguage(prevQuestion?.lang || "javascript");
      setCurrentStep(currentStep - 1);
      setCurrentQuestion(prevQuestion);
    }
  };

  // Handle microphone test (mock functionality)
  const [mictest, SetMictest] = useState(false);
  const micFun = () => {
    SetMictest(!mictest);
    setTimeout(() => {
      const textArea = document.getElementById("text_area");
      textArea.value =
        "Input Validation: Numbers less than or equal to 1 are not prime, so we immediately return False for these cases.";
      SetMictest(mictest);
    }, 3000);
  };

  // Modal state and handlers
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Handle assessment submission
  const handleSubmit = async () => {
    setTimerRunning(false); // Stop the timer
    handleShow(); // Show the submission modal

    // Simulate API call to submit assessment
    let code_score = 0;
    let passed = false;
    if (currentQuestion?.type === "CODING") {
      if (code) {
        let data = await check_answer({
          question_details: {
            question: currentQuestion?.question,
            question_type: currentQuestion?.type,
            correct_answer: currentQuestion?.answer,
            inputs: [],
            expected_output: "Array of user objects with status 'active'",
            test_cases: [],
          },
          user_answer: code,
          total_marks: currentQuestion?.mark,
        });
        code_score = data?.score;
        passed = data?.passed;
      }
      // onCodeChange(code, language, code_score);
    }

    store_single_answer({
      attempt: attempData?.id,
      question: currentQuestion?.id,
      answer: currentQuestion?.user_answer,
      is_correct:
        currentQuestion?.answer === currentQuestion?.user_answer
          ? "True"
          : "False",
      score:
        currentQuestion?.answer === currentQuestion?.user_answer
          ? currentQuestion?.mark
          : 0,
    });

    setTimeout(() => {
      // Simulate API call to submit assessment
      let payload = allQuestions.map((item) => {
        if (item.id === currentQuestion.id) {
          if (code_score) {
            return {
              ...item,
              user_answer: code,
              lang: language,
              code_score,
              passed,
            };
          }
          return {
            ...item,
            user_answer: code,
            lang: language,
          };
        }
        return item;
      });
      calculateResult(payload);
    }, 2000);
  };

  // Handle MCQ answer selection
  const handleCheck = (e) => {
    setAllQuestions(
      allQuestions.map((item) => {
        if (item.id === currentQuestion.id) {
          return {
            ...item,
            user_answer: e.target.value,
            passed: item?.answer === e.target.value,
          };
        }
        return item;
      })
    );
    setCurrentQuestion({
      ...currentQuestion,
      user_answer: e.target.value,
      passed: currentQuestion?.answer === e.target.value,
    });
  };

  const calculateResult = () => {
    get_quiz_result();
  };

  if (showResult) {
    return (
      <>
        <InterviewModuleNav data={navData} />
        <PracticeSummary
          backUrl={`/applicant-dashboard`}
          proceedUrl={`/applicant-dashboard/related-jobs/${jobId}/written-assessment-instruction/${jobname}/${companyName}/${assignment_id}`}
          assessmentResult={assessmentResult}
        />
      </>
    );
  }

  return (
    <>
      <InterviewModuleNav data={navData} />
      <section className='interview_mscq_module'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12 col-md-12'>
              <div className='interview_mscq_module_inner'>
                <div className='interview_mscq_module_inner_head'>
                  <div className='interview_mscq_module_inner_head_list'>
                    <ul>
                      {allQuestions.map((item, index) => (
                        <li
                          key={index}
                          className={currentStep === index + 1 ? "active" : ""}
                        >
                          {index + 1}
                        </li>
                      ))}
                    </ul>
                  </div>
                  {/* <div className="list_sec_timer">
                    <span>Time Remaining</span>
                    <div>
                      <FontAwesomeIcon icon={faClock} />
                      {formatTime(timeLeft)}
                    </div>
                  </div> */}
                </div>
                <div className={`interview_mscq_module_inner_body`}>
                  {currentQuestion?.type === "MCQ" && (
                    <div className="ques_ans_sec">
                      <div
                        className="ques_ans_sec_main_top"
                        style={{ marginTop: "20px" }}
                      >
                        <h6>{currentQuestion.question}</h6>
                        <h6>{currentQuestion.code}</h6>
                      </div>
                      <div className="ques_ans_sec_main_btm">
                        <ul>
                          {currentQuestion.options.map((item, index) => (
                            <li key={index}>
                              <div className="ques_ans_single">
                                <label
                                  className={`container_mcq ${
                                    currentQuestion.user_answer === item.id
                                      ? "checked_class"
                                      : ""
                                  }`}
                                >
                                  {item.id}) {item.value}
                                  <input
                                    type="radio"
                                    name="radio"
                                    onChange={handleCheck}
                                    checked={
                                      currentQuestion.user_answer === item.id
                                    }
                                    value={item.id}
                                  />
                                  <span className="checkmark_mcq"></span>
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}

                  {currentQuestion?.type === "CODING" && (
                    <div className={styles.question_container}>
                      <div className="ques_ans_sec_main_top">
                        <h6>{currentQuestion.question}</h6>
                      </div>
                      <div className={styles.code_editor}>
                        <CodeEditor />
                      </div>
                    </div>
                  )}

                  <div className={`ques_ans_sec_btn`}>
                    {currentStep !== 1 ? (
                      <button className="ques_btn_prev" onClick={prevhandel}>
                        Back
                      </button>
                    ) : (
                      <div></div>
                    )}
                    {currentStep <= allQuestions.length - 1 ? (
                      <>
                        {!nextLoading ? (
                          <button
                            className="ques_btn_next"
                            onClick={nexthandel}
                          >
                            Next
                          </button>
                        ) : (
                          <button className="ques_btn_next" disabled>
                            loading...
                          </button>
                        )}
                      </>
                    ) : (
                      <button className="ques_btn_next" onClick={handleSubmit}>
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        show={show}
        onHide={handleClose}
        className="allset_modal_sec"
        backdrop="static"
      >
        <div className="allset_modal_sec_inner">
          <h6>Stay Connected!</h6>
          <p>Your assessment is currently being analyzed.</p>
          <img src={spin_img} alt="Microphone animation" />
        </div>
      </Modal>
    </>
  );
};

export default PracticeAssessment;
// question types

{
  /* {currentStep === 2 && (
  <div className='ques_ans_sec'>
      <div className='ques_ans_sec_main_top'>
          <h6>Question 2 - The pass statement in Python is used to terminate a loop.</h6>
      </div>
      <div className='ques_ans_sec_main_btm'>
        <ul>
          <li>
              <div className='ques_ans_single'>
                <label className={`container_mcq ${isChecked === 'True' ? 'checked_class' : ''}`}
                >True
                  <input type="radio" name="radio" onChange={handleCheck} value="True" />
                  <span class="checkmark_mcq"></span>
                </label>
              </div>
          </li>
          <li>
              <div className='ques_ans_single'>
                <label className={`container_mcq ${isChecked === 'False' ? 'checked_class' : ''}`}
                >False
                  <input type="radio" name="radio" onChange={handleCheck} value="False" />
                  <span class="checkmark_mcq"></span>
                </label>
              </div>
          </li>
        </ul>
      </div>
  </div>
)}
{currentStep === 3 && (
  <div className='ques_ans_sec'>
      <div className='ques_ans_sec_main_top'>
          <h6>Question 3 - Write a Python function called is_prime that takes an integer as input and returns True if the number is a prime number and False otherwise.</h6>
      </div>
      <div className='ques_ans_sec_text_area'>
          <textarea placeholder='Write your answer here' id='text_area'></textarea>
          {mictest && 
            <div className='mic_wave_cin'>
              <img src={mic_gif} alt="Microphone animation" />
            </div>
          }
          <div className='mic_icn_new'>
            <button onClick={micFun}>
              <FontAwesomeIcon icon={mictest ? faMicrophone : faMicrophoneSlash} />
            </button>
          </div>
      </div>
  </div>
)}
{currentStep === 4 && (
  <div className='ques_ans_sec'>
      <div className='ques_ans_sec_main_top'>
          <h6>Question 4 - Write a Python program to generate the Fibonacci series up to n terms.</h6>
      </div>
      <div className='code_ediotr_sec_new' style={{textAlign: 'center'}}>
          <h2>Code Editor</h2>
      </div>
  </div>
)}
{currentStep === 5 && (
  <div className='ques_ans_sec'>
      <div className='ques_ans_sec_main_top'>
          <h6>Question 2 - The pass statement in Python is used to terminate a loop.</h6>
      </div>
      <div className='code_ediotr_sec_new' style={{textAlign: 'center'}}>
          <h2>Code Editor</h2>
      </div>
  </div>
)} */
}
