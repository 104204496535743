import { useEffect, useState } from "react";

const useTabSwitchHandler = (onMaxTabSwitches, maxTabSwitches = 3) => {
  const [tabSwitchCount, setTabSwitchCount] = useState(0);
  const [tabSwitchWarningVisible, setTabSwitchWarningVisible] = useState(false);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        const newCount = tabSwitchCount + 1;
        setTabSwitchCount(newCount);

        if (newCount < maxTabSwitches) {
          setTabSwitchWarningVisible(true);
        } else if (newCount >= maxTabSwitches) {
          setTabSwitchWarningVisible(false);
          onMaxTabSwitches(); // Trigger action on max tab switches
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [tabSwitchCount, maxTabSwitches, onMaxTabSwitches]);

  return { tabSwitchWarningVisible, setTabSwitchWarningVisible, tabSwitchCount };
};

export default useTabSwitchHandler;
