import { create } from 'zustand';
import axios from 'axios';
// @ts-ignore
import { LANGUAGE_VERSIONS } from "../utils/constants";

// Define types for the store state and actions
interface CodeEditorState {
  code: string;
  output: string;
  loading: boolean;
  language: string;
  languageSampleCode: Record<string, string>;
  setCode: (code: string) => void;
  setOutput: (output: string) => void;
  setLanguage: (language: string) => void;
  executeCode: () => Promise<void>;
}

// Define the Piston API response type
interface PistonExecuteResponse {
  run: {
    output: string;
    stderr?: string;
    stdout?: string;
  };
}

const codeEditorStore = create<CodeEditorState>((set, get) => ({
  code: '// Write your JavaScript code here\n',
  output: '',
  loading: false,
  language: 'javascript',
  languageSampleCode: {
    javascript: '// Write your JavaScript code here\nconsole.log("Hello, World!");',
    python: '# Write your Python code here\nprint("Hello, World!")',
    csharp: '// Write your C# code here\nusing System;\npublic class Program\n{\n public static void Main(string[] args)\n {\n Console.WriteLine("Hello, World!");\n }\n}',
    java: '// Write your Java code here\npublic class Main {\n public static void main(String[] args) {\n System.out.println("Hello, World!");\n }\n}',
    php: '<?php\n// Write your PHP code here\necho "Hello, World!";\n?>'
  },
  setCode: (code) => set({ code }),
  setOutput: (output) => set({ output }),
  setLanguage: (language) => set((state) => ({ 
    language, 
    // code: state.languageSampleCode[language] 
  })),
  executeCode: async () => {
    set({ loading: true, output: '' });
    try {
      const apiUrl = "https://emkc.org/api/v2/piston/execute";
      const postData = {
        language: get().language,
        version: LANGUAGE_VERSIONS[get().language],
        files: [
          {
            content: get().code,
          },
        ],
      };
      const response = await axios.post<PistonExecuteResponse>(apiUrl, postData);
      set({ 
        output: response.data.run.output || response.data.run.stderr || '', 
        loading: false 
      });
    } catch (error) {
      set({ 
        output: `Error: ${error instanceof Error ? error.message : 'Unknown error'}`, 
        loading: false 
      });
    }
  },
}));

export default codeEditorStore;