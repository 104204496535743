import {
  Checkbox,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  Tooltip,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ChipsInputComponent from "../../../../components/CommonComponents/ChipsInput";
import useSuggestionsGenerator from "../../../../hooks/useAISuggestionsGenerator";
import { updateJobDetails } from "../../../../redux/reducers/jobReducer";
import Autocomplete from "react-google-autocomplete";
import "./JobDetails.css";
import DOMPurify from "dompurify";
import {
  employmentType,
  getModeOfWork,
  qualifications,
  quillFormats,
  quillModules,
} from "../../../../utils/constants";
import useWebSocket from "../../../../hooks/useWebSocket";
import ReactQuill from "react-quill";
import GenerateWithAi from "../../../../components/CommonComponents/GenerateWithAi";
import { marked } from "marked";
import { websocketUrl } from "../../../../api/instance";
import { info_cin_new, magic_stick } from "../../../../assets/images/images";
import { mapCountryToCurrency } from "../../../../utils/utils";
import { fetchCurrencies } from "../../../../redux/actions/jobActions";
import CustomCurrencyDropdown from "../../../../components/CommonComponents/CustomCurrecyDropdown";

const currencyMapping = {
  IN: { currency: "INR", name: "Rupee", symbol: "₹" },
  US: { currency: "USD", name: "Dollar", symbol: "$" },
  AE: { currency: "AED", name: "Dirham", symbol: "د.إ" },
  EU: { currency: "EUR", name: "Euro", symbol: "€" },
  GB: { currency: "GBP", name: "Pound", symbol: "£" },
};

const JobDetailsForm1 = ({ form, quillModules, quillFormats }) => {
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const companyDetails = useSelector((state) => state.company?.details);
  const jobDetails = useSelector((state) => state?.job?.details);
  const currencies = useSelector((state) => state.job.currencies);
  const userId = user.user_id;
  const url = `${websocketUrl}/job_descrption/${userId}/`;
  const { sendData, socketLoading, combinedMessage } = useWebSocket(url);
  const dispatch = useDispatch();
  const location = useSelector((state) => state.location?.userCountry);
  const formRef = useRef(null);
  const [formChanged, setFormChanged] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [isInitialMessageSet, setIsInitialMessageSet] = useState(false);
  const [isButtonAnimating, setIsButtonAnimating] = useState(false);
  const [discloseSalary, setDiscloseSalary] = useState(
    jobDetails?.disclose_salary || false
  );
  const [progress, setProgress] = useState(0);

  const inputRef = useRef(null);
  const mode_of_work = getModeOfWork(jobDetails, jobDetails?.mode_of_work);

  const computeResponsibilities = () => {
    let responsibilityArray = [];

    if (typeof jobDetails?.responsibilities === "string") {
      responsibilityArray = jobDetails?.responsibilities.split(",");
    } else {
      responsibilityArray = Object.values(jobDetails?.responsibilities || {});
    }

    return responsibilityArray;
  };

  const computeSkills = () => {
    let skillsArray = [];

    if (typeof jobDetails?.skills === "string") {
      skillsArray = jobDetails?.skills.split(",");
    } else {
      skillsArray = Object.entries(jobDetails?.skills || {}).map(
        ([key, value]) => value
      );
    }

    return skillsArray;
  };

  const [selectedSkills, setSelectedSkills] = useState(computeSkills() ?? []);
  const [selectedResponsibilities, setSelectedResponsibilities] = useState(
    computeResponsibilities() ?? []
  );

  const aiSkillsSuggestions = useSuggestionsGenerator({
    type: "skills",
    keyword: jobDetails?.title,
    previousSuggestions: selectedSkills.join(","),
    jobProfile: jobDetails?.title,
    experience: null,
  });

  // const aiResponsibilitiesSuggestions = useSuggestionsGenerator({
  //   type: "responsibilities",
  //   keyword: jobDetails?.title,
  //   previousSuggestions: selectedResponsibilities?.join(","),
  //   jobProfile: jobDetails?.title,
  //   experience: jobDetails?.experience,
  // });

  const isGenerateButtonDisabled = () => {
    return !jobDetails?.title;
  };

  const handleAIGeneration = () => {
    formRef.current
      .validateFields()
      .then(() => {
        toast.dismiss();
        setIsInitialMessageSet(false);
        form.setFields([
          {
            name: "description",
            errors: [],
          },
        ]);
        setProgress(0);
        const salaryDisclosure = form.getFieldValue("disclose_salary");
        const payload = {
          job_title: jobDetails?.title || "[Job title]",
          skills_required: [jobDetails?.skills],
          employment_type:
            employmentType[jobDetails?.employment_type] || "Unknown",
          // seniority_level: seniority_level,
          start_year: jobDetails?.experience - 1,
          end_year: jobDetails?.experience,
          number_of_vacancies: jobDetails?.no_of_vacancies,
          qualification_eligibility:
            qualifications[jobDetails?.qualification] || "Unknown",
          mode_of_work: mode_of_work,
          job_location: jobDetails?.location,
          benefits_and_opportunities: [companyDetails?.perks_benefits],
          // responsibilities: jobDetails?.responsibilities,
        };
        if (!salaryDisclosure) {
          payload.salary = {
            from: jobDetails?.salary_min,
            to: jobDetails?.salary_max,
            currency: jobDetails?.currency,
          };
        }
        sendData(payload);
        let progressValue = 0;
        const interval = setInterval(() => {
          progressValue += 1;
          setProgress((prevProgress) => {
            if (prevProgress < 99) {
              return progressValue;
            } else {
              clearInterval(interval);
              return prevProgress;
            }
          });
        }, 100);
        setIsButtonAnimating(true);
        setTimeout(() => {
          setIsButtonAnimating(false);
        }, 1000);
      })
      .catch((errorInfo) => {
        console.error("Validation errors:", errorInfo);
        const nonDescriptionErrors = errorInfo.errorFields.filter(
          (field) => field.name[0] !== "description"
        );
        if (nonDescriptionErrors.length > 0) {
          const errorField = nonDescriptionErrors[0];
          const fieldElement = document.querySelector(
            `[id="${errorField.name[0]}"]`
          );
          if (fieldElement) {
            fieldElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
            fieldElement.focus();
          }
        } else {
          // If the only error is related to the description field, proceed with AI generation
          toast.dismiss();
          setIsInitialMessageSet(false);
          form.setFields([
            {
              name: "description",
              errors: [],
            },
          ]);
          setProgress(0);
          const salaryDisclosure = form.getFieldValue("disclose_salary");
          const payload = {
            job_title: jobDetails?.title || "[Job title]",
            skills_required: [jobDetails?.skills],
            employment_type:
              employmentType[jobDetails?.employment_type] || "Unknown",
            // seniority_level: seniority_level,
            start_year: jobDetails?.experience - 1,
            end_year: jobDetails?.experience,
            number_of_vacancies: jobDetails?.no_of_vacancies,
            qualification_eligibility:
              qualifications[jobDetails?.qualification] || "Unknown",
            mode_of_work: mode_of_work,
            job_location: jobDetails?.location,
            benefits_and_opportunities: [companyDetails?.perks_benefits],
            // responsibilities: jobDetails?.responsibilities,
          };
          if (!salaryDisclosure) {
            payload.salary = {
              from: jobDetails?.salary_min,
              to: jobDetails?.salary_max,
              currency: jobDetails?.currency,
            };
          }
          sendData(payload);
          let progressValue = 0;
          const interval = setInterval(() => {
            progressValue += 1;
            setProgress((prevProgress) => {
              if (prevProgress < 99) {
                return progressValue;
              } else {
                clearInterval(interval);
                return prevProgress;
              }
            });
          }, 100);
          setIsButtonAnimating(true);
          setTimeout(() => {
            setIsButtonAnimating(false);
          }, 1000);
        }
      });
  };

  const getValueFromEvent = (e) => {
    return e.target.checked; // Return boolean value from the event (true or false)
  };

  // Custom onChange handler for Checkbox
  const handleCheckboxChange = (e) => {
    const newCheckedValue = e.target.checked; // Boolean (true or false)

    // Update both state and form to ensure they are in sync with the boolean value
    setDiscloseSalary(newCheckedValue);
    form.setFieldsValue({ disclose_salary: newCheckedValue }); // Sync with form (boolean value)
  };

  const [value, setValue] = useState(jobDetails?.description || "");
  const [hasText, setHasText] = useState(false);

  useEffect(() => {
    setHasText(value.trim().length > 0);
  }, [value]);

  const handleDescriptionChange = (content) => {
    setValue(content);
    const sanitizedContent = DOMPurify.sanitize(content);

    // Further normalize spacing between paragraphs
    const normalizedContent = sanitizedContent
      // Remove empty paragraphs with just br
      .replace(/<p><br><\/p>/g, "<p></p>")
      // Remove paragraphs with just whitespace
      .replace(/<p>(\s|&nbsp;)*<\/p>/g, "<p></p>")
      // Collapse multiple empty paragraphs
      .replace(/(<p><\/p>){2,}/g, "<p></p>")
      // Replace 3+ newlines with 2
      .replace(/\n{3,}/g, "\n\n")
      // Replace multiple spaces/tabs with single space
      .replace(/[ \t]+/g, " ")
      // Remove empty list items with just br
      .replace(/<li><br\s*\/?><\/li>/g, "")
      // Remove empty list items with just whitespace
      .replace(/<li>(\s|&nbsp;)*<\/li>/g, "")
      // Remove empty lists
      .replace(/<(ul|ol)>\s*<\/(ul|ol)>/g, "")
      // Remove paragraph tags inside list items while preserving content
      .replace(/(<li>[^<]*)<\/?p>/g, "$1")
      // Clean up any remaining whitespace
      .trim()
      // Normalize remaining whitespace
      .replace(/\s+/g, " ");

    const isContentEmpty =
      sanitizedContent.replace(/<[^>]*>/g, "").trim() === "";

    dispatch(
      updateJobDetails({
        ...jobDetails,
        description: isContentEmpty ? "" : normalizedContent,
      })
    );
    form.validateFields(["description"]);
  };

  const validateMaxSalary = (_, value) => {
    return new Promise((resolve, reject) => {
      if (value === undefined || value === null || value === "") {
        reject(new Error("Please fill in the maximum salary"));
      } else {
        const minSalary = form.getFieldValue("salary_min");
        if (Number(value) <= Number(minSalary)) {
          reject(new Error("Enter valid salary range."));
        } else if (!Number.isInteger(Number(value))) {
          reject(new Error("Salary should not be in decimal."));
        } else {
          resolve();
        }
      }
    });
  };

  const validateMinSalary = (_, value) => {
    return new Promise((resolve, reject) => {
      if (value === undefined || value === null || value === "") {
        reject(new Error("Please fill in the minimum salary"));
      } else {
        if (!Number.isInteger(Number(value))) {
          reject(new Error("Salary should not be in decimal."));
        } else {
          resolve();
        }
      }
    });
  };

  useEffect(() => {
    if (
      combinedMessage !== null &&
      combinedMessage !== "" &&
      !isInitialMessageSet
    ) {
      setProgress(100);
      form.setFields([
        {
          name: "description",
          value: "",
          errors: [],
        },
      ]);
      setTimeout(() => {
        dispatch(
          updateJobDetails({ ...jobDetails, description: combinedMessage })
        );
        setIsInitialMessageSet(true);
      }, 500);
      form.setFieldsValue({ description: combinedMessage });
    }
  }, [combinedMessage, dispatch, jobDetails, isInitialMessageSet]);

  useEffect(() => {
    const initialDiscloseSalary = jobDetails?.disclose_salary ?? false;
    setDiscloseSalary(initialDiscloseSalary);
    form.setFieldsValue({
      disclose_salary: initialDiscloseSalary, // Ensure it's a boolean (false/true)
    });
  }, [jobDetails, form]);

  useEffect(() => {
    if (jobDetails) {
      form.setFieldsValue(jobDetails);
    }
  }, [form, jobDetails]);

  useEffect(() => {
    setSelectedSkills(computeSkills());
  }, [jobDetails]);

  // useEffect(() => {
  //   setSelectedResponsibilities(computeResponsibilities());
  // }, [jobDetails]);

  const handleFormKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleSkillsChange = (newChips) => {
    // Remove empty strings or spaces from the newChips before processing
    const validSkills = newChips.filter((chip) => chip.trim() !== "");

    // Only update if the validSkills array has changed
    if (
      validSkills.length !== selectedSkills.length ||
      validSkills.some((skill, index) => skill !== selectedSkills[index])
    ) {
      setSelectedSkills(validSkills); // Update state only if there are valid changes
      dispatch(
        updateJobDetails({ ...jobDetails, skills: validSkills.join(",") })
      );

      if (jobDetails?.id && !isToastOpen) {
        toast.dismiss();
        toast.warning(
          "Click 'Generate with AI' to apply changes to the job description.",
          {
            autoClose: false,
            onOpen: () => setIsToastOpen(true),
            onClose: () => {
              setIsToastOpen(false);
              setFormChanged(false);
            },
          }
        );
      }
    }
  };

  const handleResponsibilitiesChange = (newChips) => {
    // Remove empty strings or spaces from the newChips before processing
    const validResponsibilities = newChips.filter((chip) => chip.trim() !== "");

    // Only update if the validResponsibilities array has changed
    if (
      validResponsibilities.length !== selectedResponsibilities.length ||
      validResponsibilities.some(
        (resp, index) => resp !== selectedResponsibilities[index]
      )
    ) {
      setSelectedResponsibilities(validResponsibilities); // Update state only if there are valid changes
      dispatch(
        updateJobDetails({
          ...jobDetails,
          responsibilities: validResponsibilities.join(","),
        })
      );

      if (jobDetails?.id && !isToastOpen) {
        toast.dismiss();
        toast.warning(
          "Click 'Generate with AI' to apply changes to the job description.",
          {
            autoClose: false,
            onOpen: () => setIsToastOpen(true),
            onClose: () => {
              setIsToastOpen(false);
              setFormChanged(false);
            },
          }
        );
      }
    }
  };

  const onValuesChange = (_, allValues) => {
    dispatch(updateJobDetails(allValues));
    setFormChanged(true);
  };

  const addSuggestedSkill = (skill) => {
    if (!selectedSkills.includes(skill)) {
      const updatedSkills = [...selectedSkills, skill];
      handleSkillsChange(updatedSkills);
    }
  };

  const removeSelectedSkill = (skill) => {
    // const updatedSkills = selectedSkills.filter((s) => s !== skill);
    // handleSkillsChange(updatedSkills);
  };

  const addSuggestedResponsibility = (responsibility) => {
    if (!selectedResponsibilities.includes(responsibility)) {
      const updatedResponsibilities = [
        ...selectedResponsibilities,
        responsibility,
      ];
      handleResponsibilitiesChange(updatedResponsibilities);
    }
  };

  const removeSelectedResponsibility = (responsibility) => {
    // const updatedResponsibilities = selectedResponsibilities.filter(
    //   (s) => s !== responsibility
    // );
    // handleResponsibilitiesChange(updatedResponsibilities);
    if (jobDetails?.id && !isToastOpen) {
      toast.dismiss();
      toast.warning(
        "Click 'Generate with AI' to apply changes to the job description.",
        {
          autoClose: false,
          onOpen: () => setIsToastOpen(true),
          onClose: () => {
            setIsToastOpen(false);
            setFormChanged(false);
          },
        }
      );
    }
  };
  const handleVacancyBlur = () => {
    form
      .validateFields(["no_of_vacancies"])
      .then(() => {
        // Validation successful, blur the input
        if (inputRef.current) {
          inputRef.current.blur();
        }
      })
      .catch((errorInfo) => {
        // Validation failed
        console.error("Validation failed:", errorInfo);
      });
  };

  const fetchUserLocationAndSetCurrency = async () => {
    if (location !== null && currencies === null) {
      try {
        const currencyCode = await mapCountryToCurrency(location);

        form.setFieldsValue({ currency: currencyCode });
      } catch (error) {
        console.error("Error fetching location-based currency", error);
      }
    }
  };

  useEffect(() => {
    if (token) {
      dispatch(fetchCurrencies(token));
    }
    fetchUserLocationAndSetCurrency();
  }, [location]);

  useEffect(() => {
    if (formChanged && jobDetails?.id && !isToastOpen) {
      toast.dismiss(); // Dismiss all existing toasts before showing a new one
      toast.warning(
        "Click 'Generate with AI' to apply changes to the job description.",
        {
          autoClose: false,
          onOpen: () => setIsToastOpen(true), // Set isToastOpen to true when the toast opens
          onClose: () => {
            setIsToastOpen(false); // Reset isToastOpen when the toast closes
            setFormChanged(false);
          },
        }
      );
    }
  }, [formChanged, jobDetails?.id]);

  return (
    <>
      <section className="form_sec_new_inner_first new_form_sec_tool">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div id="form_box_sec">
              <Form
                ref={formRef}
                onKeyDown={handleFormKeyDown}
                form={form}
                name="jobDetailsForm1"
                onValuesChange={onValuesChange}
                className="job_details_from"
              >
                <div className="row">
                  <div className="col-6 text-start">
                    <Form.Item
                      name="title"
                      label="Job Position"
                      rules={[
                        {
                          required: true,
                          message: "Please input the job position!",
                        },
                        {
                          max: 50,
                          message: "Job position cannot exceed 50 characters!",
                        },
                      ]}
                    >
                      <Input placeholder="Job Position" id="title" />
                    </Form.Item>
                  </div>
                  <div className="col-6 text-start">
                    <Form.Item
                      name="location"
                      label="Job Location"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the job location",
                        },
                      ]}
                    >
                      <Autocomplete
                        id="location"
                        apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                        onPlaceSelected={(place) => {
                          if (place && place.formatted_address) {
                            const location = place.formatted_address;
                            form.setFieldsValue({ location });
                            onValuesChange(null, { location: location });
                          }
                        }}
                        defaultValue={form.getFieldValue("location")}
                        onChange={(event) => {
                          form.setFieldsValue({ location: event.target.value });
                        }}
                        options={{
                          types: ["(cities)"],
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
                <Form.Item
                  name="skills"
                  label={
                    <span>
                      Skills Required
                      {/* <Tooltip title="Limit: 75 character per chip. Sentences will split at commas.">
                        <Button type="text" icon={<InfoCircleOutlined />} />
                      </Tooltip> */}
                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please add at least one skill",
                    },
                  ]}
                >
                  <ChipsInputComponent
                    key="skills"
                    placeholder="Type a keyword and press enter"
                    value={selectedSkills}
                    onChipsChange={handleSkillsChange}
                    onChipRemoved={removeSelectedSkill}
                    id={"skills"}
                    formatCase="camelCase"
                  />
                </Form.Item>
                <div className="suggestion_sec_new_main">
                  <div className="suggestion_sec_new">
                    <ul className="list_item_sec">
                      {aiSkillsSuggestions?.map((skill, index) => (
                        <li
                          style={{ cursor: "pointer" }}
                          key={index}
                          onClick={() => addSuggestedSkill(skill)}
                        >
                          {skill}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6 text-start">
                    <Form.Item
                      name="employment_type"
                      label="Employment Type"
                      rules={[
                        {
                          required: true,
                          message: "Please select an employment type",
                        },
                      ]}
                    >
                      <Select
                        id="employment_type"
                        placeholder="Select employment type"
                        className="EmployeeType"
                      >
                        <Select.Option value={1}>Full-time</Select.Option>
                        <Select.Option value={2}>Part-time</Select.Option>
                        <Select.Option value={3}>Remote</Select.Option>
                        <Select.Option value={4}>Temporary</Select.Option>
                        <Select.Option value={5}>Contract</Select.Option>
                        <Select.Option value={6}>Freelancer</Select.Option>
                        <Select.Option value={7}>Internship</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="col-6 text-start">
                    <Form.Item
                      name="no_of_vacancies"
                      label={
                        <span>
                          No of Vacancies
                          <div className="inof_icn">
                            {/* <FontAwesomeIcon icon={faCircleInfo} style={{fontWeight:'500'}} /> */}
                            <img src={info_cin_new} alt="" />
                            <div className="budget_sec_tool">
                              <h6>
                                Enter the total number of positions open for
                                this role.
                              </h6>
                            </div>
                          </div>
                        </span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please enter valid digit in vacancy",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (value === 0 || value === "0") {
                              return Promise.reject(
                                "Please enter a valid digit"
                              );
                            }
                            if (value) {
                              if (
                                isNaN(value) ||
                                value < 0 ||
                                !/^\d+$/.test(value)
                              ) {
                                return Promise.reject(
                                  "Please enter a valid digit"
                                );
                              }
                            }
                            if (value && value.toString().length > 5) {
                              return Promise.reject(
                                "Only 5 digits are allowed."
                              );
                              // setVacanciesLimit(true)
                              // value = parseInt(value.toString().slice(0, 5), 10);
                            }
                            // else {
                            //   // setVacanciesLimit(false);
                            // }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input
                        id="vacancies"
                        ref={inputRef}
                        min={0}
                        style={{ width: "100%" }}
                        placeholder="Enter Vacancies"
                        type="text"
                        onBlur={handleVacancyBlur}
                        maxLength={6}
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6 text-start">
                    <Form.Item
                      name="experience"
                      label={
                        <span>
                          Years of Experience Required
                          <div className="inof_icn">
                            <img src={info_cin_new} alt="" />
                            <div className="budget_sec_tool">
                              <h6>
                                Enter the minimum years of professional
                                experience required for this position.
                              </h6>
                            </div>
                          </div>
                        </span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please select Years of Experience",
                        },
                      ]}
                    >
                      {/* <Input
                        min={1}
                        style={{ width: "100%" }}
                        placeholder="0"
                      /> */}
                      <Select
                        id="experience"
                        placeholder="Select experience"
                        className="experience"
                      >
                        <Select.Option value={1}>0-1</Select.Option>
                        <Select.Option value={2}>1-2</Select.Option>
                        <Select.Option value={3}>2-3</Select.Option>
                        <Select.Option value={4}>3-4</Select.Option>
                        <Select.Option value={5}>4-5</Select.Option>
                        <Select.Option value={6}>5+ years</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="col-6 text-start">
                    {/* <Form.Item
                      name="seniority_level"
                      label="Seniority Level"
                      rules={[
                        {
                          required: true,
                          message: "Please select a seniority level",
                        },
                      ]}
                    >
                      <Select
                        id="seniority"
                        placeholder="Select Seniority type"
                        className="EmployeeType"
                      >
                        <Select.Option value={1}>Entry-Level</Select.Option>
                        <Select.Option value={2}>Mid-Level</Select.Option>
                        <Select.Option value={3}>Senior-Level</Select.Option>
                        <Select.Option value={4}>Executive</Select.Option>
                        <Select.Option value={5}>Director-Level</Select.Option>
                      </Select>
                    </Form.Item> */}
                    <Form.Item
                      name="qualification"
                      label={
                        <span>
                          Qualification / Eligibility
                          <div className="inof_icn">
                            <img src={info_cin_new} alt="" />
                            <div className="budget_sec_tool">
                              <h6>
                                Mention the required educational qualifications
                                and criteria for this role.
                              </h6>
                            </div>
                          </div>
                        </span>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please select a Qualification",
                        },
                      ]}
                    >
                      <Select
                        id="qualification"
                        placeholder="Select eligibility"
                        className="select_industry text-left"
                      >
                        <Select.Option value={1}>Unspecified</Select.Option>
                        <Select.Option value={2}>
                          High School or Equivalent
                        </Select.Option>
                        <Select.Option value={3}>Diploma</Select.Option>
                        <Select.Option value={4}>
                          Bachelors Degree
                        </Select.Option>
                        <Select.Option value={5}>Masters Degree</Select.Option>
                        <Select.Option value={6}>Doctorate</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 text-start">
                    <Form.Item
                      name="mode_of_work"
                      label="Mode of work"
                      rules={[
                        {
                          required: true,
                          message: "Please select a mode of work",
                        },
                      ]}
                    >
                      <Radio.Group className="custom_radio" id="mode_of_work">
                        <Radio value={1} className="workmode_location">
                          On-site
                        </Radio>
                        <Radio value={2} className="workmode_location">
                          Remote
                        </Radio>
                        <Radio value={3} className="workmode_location">
                          Hybrid
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <div className="col-6 text-start">
                    <div className="row new_padd_cls">
                      <div className="row" style={{ height: "32px" }}>
                        <div className="col-6 anual_class">
                          <Form.Item
                            name="annual_compensation"
                            label="Annual Salary"
                            required // This will show the asterisk
                            rules={[]} // Empty rules array means no validation
                            className="custom-form-item-new"
                          ></Form.Item>
                        </div>
                        <div className="col-6 new_ant_class">
                          <Form.Item
                            label=""
                            name="disclose_salary"
                            valuePropName="checked" // Ensure value is passed as checked state of the checkbox
                            getValueFromEvent={getValueFromEvent}
                          >
                            <div className="salary_check_box_main_sec">
                              <Checkbox
                                checked={discloseSalary} // Controlled by state (boolean)
                                onChange={handleCheckboxChange} // Handle the state update on change
                                className="saalry_check_box"
                              ></Checkbox>
                              <label>Do not disclose</label>
                            </div>
                          </Form.Item>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-4 p-0 text-start years_exp new_width_sec">
                        <Form.Item
                          name="currency"
                          // label="Currency"
                          rules={[
                            {
                              required: true,
                              message: "Please select the currency",
                            },
                          ]}
                        >
                          <CustomCurrencyDropdown
                            currencyMapping={currencyMapping}
                            onChange={(currency) => {
                              form.setFieldsValue({ currency: currency });
                              dispatch(
                                updateJobDetails({
                                  ...jobDetails,
                                  currency,
                                })
                              );
                            }}
                            value={form.getFieldValue("currency")}
                            placeholder="Select Currency"
                          />
                        </Form.Item>
                      </div>
                      <div className="col-sm-12 col-md-4 p-0  text-start years_exp salary_error">
                        <Form.Item
                          name="salary_min"
                          rules={[
                            {
                              required: true,
                              validator: validateMinSalary,
                            },
                          ]}
                        >
                          <InputNumber
                            id="salary_min"
                            min={1}
                            placeholder="0"
                            style={{ width: "100%" }}
                            prefix={"From:"}
                          />
                        </Form.Item>
                      </div>
                      <div className=" col-sm-12 col-md-4 p-0  text-start years_exp salary_error">
                        <Form.Item
                          name="salary_max"
                          rules={[
                            { required: true, validator: validateMaxSalary },
                          ]}
                        >
                          <InputNumber
                            id="salary_max"
                            placeholder="0"
                            min={1}
                            style={{ width: "100%" }}
                            prefix={"To:"}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row new_row_tolerance">
                  <div className="col-1 text-start">
                    <Form.Item
                      label=""
                      name="tolerance_margin"
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                  </div>

                  <div className="col-11 text-start">
                    <label>Tolerance Margin</label>
                    <p>
                      Tolerance Margin allows considering candidates with
                      experience close to, but not exactly matching the
                      requirement, ensuring they are not disqualified.
                    </p>
                  </div>
                </div>
                <div className="mt-3">
                  <Form.Item
                    name="description"
                    label="Job Description"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the job overview",
                        validator: (_, value) => {
                          const sanitizedValue = DOMPurify.sanitize(value);
                          const isValueEmpty =
                            sanitizedValue.replace(/<[^>]*>/g, "").trim() ===
                            "";
                          if (isValueEmpty) {
                            return Promise.reject(
                              new Error("Please enter the job overview")
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <button
                      type="button"
                      className={`data_generator ${
                        isButtonAnimating ? "animated" : ""
                      }`}
                      onClick={handleAIGeneration}
                      disabled={isGenerateButtonDisabled()}
                    >
                      <img src={magic_stick} alt="" /> Generate with A.I
                    </button>
                    {socketLoading ? (
                      <div className="new_data_sec_com">
                        <GenerateWithAi progress={progress} />
                      </div>
                    ) : (
                      <div
                        className={`react-quill-container ${
                          hasText ? "has-text" : ""
                        }`}
                      >
                        <ReactQuill
                          id="description"
                          theme="snow"
                          value={marked(jobDetails?.description || "")}
                          key={"description"}
                          onChange={handleDescriptionChange}
                          modules={quillModules}
                          formats={quillFormats}
                          placeholder="Enter Company Overview. For Example: Perks and benefits, Culture and work environment, Career development Opportunities, Community Engagement and Responsibilities, Latest News And Updates"
                          rows={4}
                        />
                      </div>
                    )}
                  </Form.Item>
                </div>
                {/* <Form.Item
                  name="responsibilities"
                  label={
                    <span>
                      <Tooltip title="Limit: 75 character per chip. Sentences will split at commas.">
                        <Button type="text" icon={<InfoCircleOutlined />} />
                      </Tooltip>
                      Key Duties
                    </span>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please add at least one responsiblity",
                    },
                  ]}
                >
                  <ChipsInputComponent
                    key="responsibilities"
                    placeholder="Type a keyword and press enter"
                    value={selectedResponsibilities}
                    onChipsChange={handleResponsibilitiesChange}
                    onChipRemoved={removeSelectedResponsibility}
                    id={"responsibilities"}
                    formatCase="sentenceCase"
                  />
                </Form.Item>
                <div style={{ paddingBottom: "30px" }}>
                  <div className="suggestion_sec_new">
                    <ul className="list_item_sec">
                      {aiResponsibilitiesSuggestions?.map(
                        (responsibility, index) => (
                          <li
                            style={{ cursor: "pointer" }}
                            key={index}
                            onClick={() =>
                              addSuggestedResponsibility(responsibility)
                            }
                          >
                            {responsibility}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div> */}
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JobDetailsForm1;
