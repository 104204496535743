import React from "react";
import { java } from "@codemirror/lang-java";
import { javascript } from "@codemirror/lang-javascript";
import { php } from "@codemirror/lang-php";
import { python } from "@codemirror/lang-python";
import { oneDark } from "@codemirror/theme-one-dark";
import { csharp } from "@replit/codemirror-lang-csharp";
import CodeMirror from "@uiw/react-codemirror";
import ThreeDotLoader from "../../components/Loaders/ThreeDotLoader/ThreeDotLoader";
import useCodeEditorStore from "../../zustand/codeEditorStore";
import styles from "./CodeEditor.module.css"; // Import module CSS

const CodeEditor = ({ userCode, userOutput, isRecruiter }) => {
  const { code, output, loading, language, setCode, setLanguage, executeCode } =
    useCodeEditorStore();

  const handleCodeChange = (value) => {
    setCode(value);
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleRunCode = async () => {
    executeCode();
  };

  const getLanguageExtension = () => {
    switch (language) {
      case "javascript":
        return javascript();
      case "python":
        return python();
      case "csharp":
        return csharp();
      case "java":
        return java();
      case "php":
        return php();
      default:
        return javascript();
    }
  };

  const languages = Object.entries(
    useCodeEditorStore.getState().languageSampleCode
  );

  return (
    <div className={styles.code_mirror_sec}>
      <div className={styles.code_edit_lang_sec}>
        <div className={styles.code_edit_lang_sec_inner}>
          <select
            className={styles.language_dropdown}
            value={language}
            onChange={handleLanguageChange}
            disabled={isRecruiter}
          >
            {languages.map(([lang]) => (
              <option key={lang} value={lang}>
                {lang}
              </option>
            ))}
          </select>
        </div>

        <CodeMirror
          value={isRecruiter ? userCode : code}
          height={isRecruiter ? "400px" : "200px"}
          maxHeight="400px"
          theme={oneDark}
          extensions={[getLanguageExtension()]}
          onChange={handleCodeChange}
          className={styles.cm_editor}
          style={{ width: "100%" }}
        />
        {!isRecruiter && (
          <button onClick={handleRunCode} className={styles.btn_code_edt}>
            Run
          </button>
        )}
      </div>
      <div className={styles.output_sec_new}>
        <h4>Output:</h4>
        <div>
          {loading ? (
            <div className={styles.loader_new}>
              <ThreeDotLoader />
            </div>
          ) : (
            <pre
              style={{
                margin: 0,
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                color: "white",
              }}
            >
              {isRecruiter ? userOutput : output}
            </pre>
          )}
        </div>
      </div>
    </div>
  );
};

export default CodeEditor;
