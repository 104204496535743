import React, { useState, useEffect } from "react";
import InterviewModuleNav from "./InterviewModuleNav";
import "../InterviewModule/InterviewModule.css";
import { hairoAvatar, thick_icn_new } from "../../../../assets/images/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophoneSlash,
  faChevronRight,
  faMicrophone,
} from "@fortawesome/free-solid-svg-icons";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Modal } from "react-bootstrap";

const AiPracticeInterview = () => {
  const location = useLocation();
  const isPracticeInterview = location.pathname.includes(
    "ai-practice-interview-tour"
  );
  const { jobId, jobname, companyName, interviewId } = useParams();
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Accessing the state data
  const routeStateData = location.state;
  const decodedJobTitle = decodeURIComponent(jobname);
  const decodedCompanyName = decodeURIComponent(companyName);
  const navData = {
    jobname: decodedJobTitle,
    CompanyName: decodedCompanyName,
  };

  const [allsetModalStatus, setAllsetModalStatus] = useState(false);

  const handleStartInterviewClick = async () => {
    try {

      navigate(
        `/applicant-dashboard/related-jobs/${jobId}/start-interview/${interviewId}/interview-panel/interview-panel-ready/${jobname}/${companyName}${
          isPracticeInterview ? "/practice-interview" : ""
        } `,
        { state: { data: routeStateData } }
      );
    } catch (error) {
      console.error("Error creating interview:", error);
    }
  };

  useEffect(() => {
    if (allsetModalStatus) {
      setTimeout(() => {
        setAllsetModalStatus(false);
        handleStartInterviewClick();
      }, 3000);
    }
  }, [allsetModalStatus]);

  const [currentStep, setCurrentStep] = useState(0);
  const steps = [
    <div className="getting_start_sec" key="step_0">
      <h5>Getting Started</h5>
      <p>Haire.ai Interview Walkthrough</p>
      <button onClick={() => setCurrentStep(1)}>Next</button>
    </div>,
    <div className="getting_start_sec mian_start_sec step_1" key="step_1">
      <div className="getting_start_sec_left">
        <span>1</span>
      </div>
      <div className="getting_start_sec_right">
        <h5>Tap to Answer</h5>
        <p>Click on Tap to Answer and speak</p>
        <button onClick={() => setCurrentStep(2)}>Next</button>
      </div>
    </div>,
    <div className="getting_start_sec mian_start_sec step_1" key="step_2">
      <div className="getting_start_sec_left">
        <span>2</span>
      </div>
      <div className="getting_start_sec_right">
        <h5>Tap to Stop</h5>
        <p>Click on Tap to stop and when you're done speaking.</p>
        <div className="getting_right_btns">
          <button onClick={() => setCurrentStep(1)}>Previous</button>
          <button onClick={() => setCurrentStep(3)}>Next</button>
        </div>
      </div>
    </div>,
    <div className="getting_start_sec mian_start_sec step_3" key="step_3">
      <div className="getting_start_sec_left">
        <span>3</span>
      </div>
      <div className="getting_start_sec_right">
        <h5>Skip Question</h5>
        <p>Click on "Skip question" if you want to skip this question</p>
        <div className="getting_right_btns">
          <button onClick={() => setCurrentStep(2)}>Previous</button>
          <button onClick={() => setCurrentStep(4)}>Next</button>
        </div>
      </div>
    </div>,
    <div className="getting_start_sec mian_start_sec step_4" key="step_4">
      <div className="getting_start_sec_left">
        <span>4</span>
      </div>
      <div className="getting_start_sec_right">
        <h5>End Interview</h5>
        <p>Click "End Interview" to submit and exit.</p>
        <div className="getting_right_btns">
          <button onClick={() => setCurrentStep(3)}>Previous</button>
          <button onClick={() => setAllsetModalStatus(true)}>Finish</button>
        </div>
      </div>
    </div>,
  ];

  return (
    <>
      <div className="prac_main_aft">
        <InterviewModuleNav data={navData} />
        <section className="int_panel_body_sec practice_ovelay_sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="int_panel_body_sec_inner">
                  <img
                    src={hairoAvatar}
                    alt=""
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <div className="ftr_menu_sec_new_int">
                  <div
                    className={`skip_qu_btn ${
                      currentStep !== 3 ? "" : "z_index"
                    }`}
                  >
                    <button>Skip Question</button>
                  </div>
                  <div
                    className={`tap_to_speak_sec ${
                      currentStep !== 1 ? "" : "z_index"
                    }`}
                  >
                    <button
                      className={`tap_ans_btn ${
                        currentStep === 2 ? "active" : ""
                      }`}
                    >
                      {currentStep === 3 ? (
                        <span>
                          <FontAwesomeIcon icon={faMicrophone} />
                        </span>
                      ) : (
                        <span>
                          <FontAwesomeIcon icon={faMicrophoneSlash} />
                        </span>
                      )}
                      Tap to answer
                    </button>
                  </div>
                  <div
                    className={`end_btn_new ${
                      currentStep !== 4 ? "" : "z_index"
                    }`}
                  >
                    <button className="btn_new_end_sec">End Interview</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="practice_ovelay_sec_main">
            {steps[currentStep]}
            <div className="practice_step_sec">
              <h6>
                Total 4 Steps |
                <button onClick={() => setCurrentStep(steps.length - 1)}>
                  Skip <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </h6>
            </div>
          </div>
        </section>
        <Modal
          show={allsetModalStatus}
          onHide={() => {
            setAllsetModalStatus(false);
          }}
          className="allset_modal_sec"
        >
          <div className="allset_modal_sec_inner">
            <img src={thick_icn_new} alt="Microphone animation" />
            <h6>You're all set!</h6>
            <p>Everything's set up! It's time to start your interview.</p>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default AiPracticeInterview;
