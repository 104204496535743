import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {ai_btn_img , gen_img , dollar_img , Question_Mark} from "../../assets/images/images"
import { set } from 'lodash';

const AiAvatarWrittenTest = ({handleModalClick}) => {

    const [addData, setAddData] = useState(false);
    const addModal = () => {
        setAddData(true);
    }
    const CloseModal = () => {
        setAddData(false);
    }

    const [geneAddData, setgeneAddData] = useState(false);
    const [aiInterviewMod, setAiInterviewMod] = useState(false);

    const generateBtnClick = () => {
        setAddData(false);
        setgeneAddData(true);
    }
    const geneCloseModal = () => {
        setgeneAddData(false);
    }
    useEffect(() => {
    if (!geneAddData) return;
    
    const timer = setTimeout(() => {
        setgeneAddData(false);
        setAiInterviewMod(true);
    }, 2000);
    return () => clearTimeout(timer);
    }, [geneAddData]);
    
    const aiInterviewModClose = () => {
        setAiInterviewMod(false);
    }

    const [quesSec,setQuesSec] = useState(false);
    const addQuesSec = () => {
        return setQuesSec(prev => !prev);
    }


    const [deleteModal, setdeleteModal] = useState(false);
    const deletMod = () => {
        setAiInterviewMod(false);
        setdeleteModal(true);
    }
    const deleteModalClose = () => {
        setdeleteModal(false);
        setAiInterviewMod(true);
    }

    const [regenrateBtnSec, setRegenrateBtnSec] = useState(false);

    const regenrateBtn = () => {
        setRegenrateBtnSec(true);
        setTimeout(() => {
            setRegenrateBtnSec(false);
        }, 5000);
    };


    const [count, setCount] = useState(15);
    const increment = () => {
        setCount(prevCount => prevCount + 1);
    };
    const decrement = () => {
        setCount(prevCount => (prevCount > 0 ? prevCount - 1 : 0));
    };

  return (
    <>
    <div className='recriuter_step_2_inner_btm_single'>
        <div className='recriuter_step_2_inner_btm_single_top'>
            <div className='recriuter_step_2_inner_btm_single_top_left new_left'>
                <div className='toggle_sec'>
                    <label class="switch">
                        <input type="checkbox" />
                        <span class="slider round"></span>
                    </label>
                </div>
                <h5>
                    Written Test
                    <button onClick={handleModalClick}>What is a Written Test?</button>
                </h5>
            </div>
            <div className='recriuter_step_2_inner_btm_single_top_right'>
                <h6>1 Credit for 5 Questions</h6>
            </div>
        </div>
        <div className='recriuter_step_2_inner_btm_single_btm_inner'>
            <p>A written test evaluates candidates' skills before the interview, and AI enhances this process by generating role-specific, relevant, and balanced questions.</p>
            <button className='fa_plus_btn' onClick={addModal}><i class="fas fa-plus"></i> Generate</button>
        </div>
        <div className='ques_add_tag'>
            <span><i class="fas fa-check-circle"></i>5 Questions Added</span>
        </div>
        <div className='written_test_duration_sec'>
            <div className='written_test_duration_sec_left'>
                <h4>Written Test Timer</h4>
                <p>Time Duration for completion of the written test <span>(in minutes).</span></p>
            </div>
            <div className='written_test_duration_sec_right'>
                <div className='written_test_duration_sec_right_inner'>
                    <button onClick={decrement} className='inc_btn'>-</button>
                    <div className='count_btn_sec'>{count}</div>
                    <button onClick={increment} className='inc_btn'>+</button>
                </div>
            </div>
        </div>
    </div>

    <Modal show={addData} onHide={CloseModal} className='generate_modal_wriitten centered'>
        <div className='generate_modal_wriitten_inner'>
            <div className='generate_modal_wriitten_inner_head'>
                <h6>Generate written test with A.I</h6>
                <button onClick={CloseModal}><i class="fas fa-times"></i></button>
            </div>
            <div className='generate_modal_wriitten_inner_body'>
                <div className='generate_modal_select_box'>
                    <div className='generate_modal_select_box_single'>
                        <label>Question Type<pre>*</pre></label>
                        <select>
                            <option>Multiple Choice Question</option>
                            <option>Coding</option>
                            <option>Mixed Questions</option>
                        </select>
                    </div>
                    <div className='generate_modal_select_box_single'>
                        <label>No of questions<pre>*</pre></label>
                        <select>
                            <option>5</option>
                            <option>10</option>
                            <option>15</option>
                            <option>20</option>
                            <option>25</option>
                            <option>30</option>
                        </select>
                    </div>
                </div>
                <div className='ques_add_tag_new'>
                    <span>5 Questions = 1 Credit</span>
                </div>
                <div className='enhance_new'>
                    <button onClick={generateBtnClick}>
                        <img src={ai_btn_img} />
                        Generate questions
                    </button>
                </div>
            </div>
        </div>
    </Modal>

    <Modal show={geneAddData} onHide={geneCloseModal} className='generate_questions_modal centered_modal'>
        <div className='generate_questions_modal_inner'>
            <p>Generating questions...</p>
            <img src={gen_img} />
        </div>
    </Modal>
    
    <Modal show={aiInterviewMod} onHide={aiInterviewModClose} className='written_test_modal_sec'>
        <div className='written_test_modal_sec_inner'>
            <div className='written_test_modal_sec_inner_head'>
                <h6>Written Test for AI interview</h6>
                <div className='enhance_new'>
                    <button>
                        <img src={ai_btn_img} />
                        Regenerate with A.I
                    </button>
                </div>
            </div>
            <div className='written_test_modal_sec_inner_body'>
                <div className='written_test_modal_sec_bo_head'>
                    <div className='written_test_modal_sec_bo_head_left'>
                        <img src={dollar_img} /> 
                        <h6>1 Credit will be consumed per 5 Questions</h6>
                    </div>
                    <div className='written_test_modal_sec_bo_head_right'>
                        <button onClick={addQuesSec}><i class="fas fa-plus"></i>Add Questions</button>
                        {quesSec && 
                            <div className='add_que_sec_new'>
                                <h3>Add Questions</h3>
                                <ul className='ques_list_sec'>
                                    <li>
                                        <div className='qc_body_radio'>
                                            <label class="container">5 Questions
                                                <input type="radio" checked="checked" name="radio" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className='ques_list_sec_text'>
                                            <h6>1 Credit</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='qc_body_radio'>
                                            <label class="container">10 Questions
                                                <input type="radio" name="radio" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className='ques_list_sec_text'>
                                            <h6>2 Credit</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='qc_body_radio'>
                                            <label class="container">15 Questions
                                                <input type="radio" name="radio" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className='ques_list_sec_text'>
                                            <h6>3 Credit</h6>
                                        </div>
                                    </li>
                                    <li>
                                        <div className='qc_body_radio'>
                                            <label class="container">20 Questions
                                                <input type="radio" name="radio" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className='ques_list_sec_text'>
                                            <h6>4 Credit</h6>
                                        </div>
                                    </li>
                                </ul>
                                <Link className='add_questionj_tag' onClick={() => setQuesSec(!quesSec)}>Add Questions</Link>
                            </div>
                        }
                    </div>
                </div>
                <div className='written_test_modal_sec_inner_new'>
                    <div className='written_test_modal_sec_inner_single'>
                        <div className='written_test_modal_sec_inner_single_text'>
                            <h4>1. Which of the following is NOT a valid keyword in Python?</h4>
                            <ul>
                                <li>a) class</li>
                                <li>b) finally</li>
                                <li>c) throw</li>
                                <li>d) import</li>
                            </ul>
                        </div>
                        <div className='written_test_modal_sec_inner_single_icons'>
                            <ul>
                                <li>
                                    <Link><i class="fas fa-pen"></i></Link>
                                </li>
                                <li>
                                    <Link onClick={deletMod}><i class="fas fa-trash-alt"></i></Link>
                                </li>
                                <li>
                                    <button onClick={regenrateBtn}><i class="fas fa-sync-alt"></i>Regenerate</button>
                                </li>
                            </ul>
                        </div>
                        {regenrateBtnSec && 
                            <div className='overlay_sec_regenerate_box'>
                                <div className='overlay_sec_regenerate_box_inner'>
                                    <p>Generating questions...</p>
                                    <img src={gen_img} />
                                </div>
                            </div>
                        }
                    </div>

                </div>
                <div className='add_cancel_btn_sec_modal'>
                    <button className='draft_btn'>Cancel</button>
                    <button className='save_btn'>Add</button>
                </div>
            </div>
        </div>
    </Modal> 
    
    {deleteModal && 
        <Modal show={deleteModal} onHide={deleteModalClose} className='delete_modal_sec_new'>
            <div className='delete_modal_sec_new_inner'>
                <img src={Question_Mark} />
                <p>Are you sure you want to remove this question?</p>
                <ul className='delete_modal_sec_new_btns'>
                    <li>
                        <Link className='cance_btn_mod' onClick={deleteModalClose}>Cancel</Link>
                    </li>
                    <li>
                        <Link className='remove_btn_mod'>Remove</Link>
                    </li>
                </ul>
            </div>
        </Modal>
    }

    

    </>
  )
}

export default AiAvatarWrittenTest
