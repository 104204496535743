import moment from "moment";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  int_frm,
  int_img,
  spin_circle,
  thick_right_icn,
  time,left_panel_img,mic_check,wave_gif,brand_awareness,sound_waves,sound_waves_new,sound_waves1,network_check,network_check_out,camera_start,three_dots
} from "../../../../assets/images/images";
import DateCalendarValue from "../../../../components/CommonComponents/Calendar";
import LoaderApply from "../../../../components/CommonComponents/Loader/LoaderApply";
import ResponsiveTimePickers from "../../../../components/CommonComponents/Timepicker";
import { Navbar } from "../../../../components/DashboardComponents/Navbar/Navbar";
import { createInterview } from "../../../../redux/actions/jobSeekerActions";
import "../InterviewModule/InterviewModule.css";

/* import TestInternetConnection from "./TestInternetConnection";
import MicrophoneCheck from "./MicrophoneCheck";
import SpeakerCheck from "./SpeakerCheck";
import CameraAccess from "./CameraAccess"; */

const InterviewModule = () => {
  const UserName = useSelector((state) => state?.auth?.user);
  const SceduleInterviewJob = useSelector((state) => state?.job?.details);
  const jobDetails = useSelector((state) => state?.job?.details);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const { jobId, jobname, companyName, assignment_id } = useParams();
  const navigate = useNavigate();
  const [lgShow, setLgShow] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const handleDateChange = (formattedDate) => {
    setSelectedDate(formattedDate);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleTimeChange = (formattedTime) => {
    setSelectedTime(formattedTime);
  };
  // const [loading, setLoading] = useState(false);

  const [continueLoading, setContinueLoading] = useState(false);

  /* const handleStartInterviewClick = async () => {
    setLoading(true);
    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const jobIdNumber =
        typeof jobId === "string" ? jobDetails?.id : parseInt(jobId);
      const data = await dispatch(
        createInterview(jobIdNumber, token, 1, undefined, undefined, timezone)
      );
      const newInterviewId = data.data.id;
  
      // Encode jobname and companyName to handle special characters
      const jobname = encodeURIComponent(data.data.job_title);
      const companyName = encodeURIComponent(data.data.company_name);
  
      navigate(
        `/applicant-dashboard/related-jobs/${jobId}/start-interview/${newInterviewId}/${jobname}/${companyName}`,
        { state: { data: data.data } }
      );
    } catch (error) {
      setLoading(false);
    }
  }; */
  const ScheduleInterviewButton = async () => {
    setContinueLoading(true);
    try {
      const jobIdNumber =
        typeof jobId === "string" ? jobDetails?.id : parseInt(jobId);
      const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
      const formattedTime = moment(selectedTime, "HH:mm").format("HH:mm");
      const data = await dispatch(
        createInterview(jobIdNumber, token, 2, formattedDate, formattedTime)
      );
      setLgShow(false);
      setShow(true);
    } catch (error) {
      console.error("Error creating interview:", error);
      setShow(false);
      setContinueLoading(false);
    }
  };

  const startInterview = async () => {
    try {
      const jobIdNumber =
        typeof jobId === "string" ? jobDetails?.id : parseInt(jobId);
      const data = await dispatch(createInterview(jobIdNumber, token, 1));
      const newInterviewId = data.data.id;

      // Encode jobname and companyName to handle special characters
      const jobname = encodeURIComponent(data.data.job_title);
      const companyName = encodeURIComponent(data.data.company_name);
      navigate(`/applicant-dashboard/related-jobs/${jobId}/start-interview/${newInterviewId}/ai-interview-guideline/${jobname}/${companyName}`,
        { state: { data: data.data } }
      );

    } catch (error) {
      console.error("Error creating interview:", error);
    }
  };


  const [modshow, setModShow] = useState(false);
  const handleCloseMod = () => setModShow(false);
  const handleShowMod = () => setModShow(true); 
  const handlGoBack = () => {
    handleCloseMod();
  }
  const onClickAssessmentTest = () => {
    navigate(`/applicant-dashboard/related-jobs/${jobId}/practice-assessment/${jobname}/${companyName}/${assignment_id}`);
  }

  return (
    <>
      <Navbar />
      <section className="hire_ai_interview max_margin">
        <div className="container-fluid">
          <div className="row">
            <div className="hire_ai_interview_inner">
              <div className="hire_ai_interview_inner_left">
                <h4>Haire AI</h4>
                <span>Welcome to our AI Interview platform! </span>
                <h6>{jobname} - {companyName}</h6>
                {/* <p>
                  Welcome to our AI Interview platform! If you're ready to begin
                  your interview right away, Please specify your interview
                  preference.
                </p> */}
                <div className="interview_list_part">
                  <h3>This interview consists of 2 parts:</h3>
                  <ul>
                    <li><b>Written Assessment -</b> A short test to evaluate your knowledge and skills.</li>
                    <li><b>AI Avatar Interview -</b> Interactive session where the AI Avatar will ask you questions in real time.</li>
                  </ul>
                </div>
                <ul className="hire_left_btn_sec">
                  <li>
                    {/* <button
                      onClick={handleStartInterviewClick}
                      className="start_interview"
                      disabled={loading}
                    >
                      {loading ? <LoaderApply /> : "Start Interview"}
                    </button> */}
                    {/* <button onClick={handleShowMod} className="start_interview">
                      {loading ? <LoaderApply /> : "Start Interview"}
                    </button> */}
                    <button className="start_interview" onClick={handleShowMod}>
                      Start Interview
                    </button>
                    {/* <Link to={`/applicant-dashboard/related-jobs/${jobId}/start-interview/${interviewId}/ai-interview-guideLine/${jobname}/${companyName}`} className="start_interview">Start Interview</Link> */}
                  </li>
                </ul>
              </div>
              <div className="hire_ai_interview_inner_right">
                <img src={int_img} alt="" />
              </div>
              
            </div>
          </div>
        </div>
      </section>

      <Modal show={modshow} onHide={handleCloseMod} className="modal_ass_instruction">
          <div className="modal_ass_instruction_inner">
            <h6>Assessment Instructions</h6>
            <div className="modal_ass_instruction_inner_body">
                <h5>{jobname} - {companyName} (Mock Written Assessment)</h5>
                <ul>
                  {/* <li>
                      <h4>Time Limit:</h4>
                      <p>Complete the assignment within the allocated time of timing. Keep track of the timer displayed on the screen.</p>
                  </li> */}
                  <li>
                      <h4>Answer Format:</h4>
                      {/* <p>Provide detailed responses for paragraph-based questions or select the most appropriate option for the MCQ question. </p>
                      <p>Write your code in the provided editor. Ensure it meets the required specifications.</p> */}
                      <p>Provide the most appropriate option for the MCQ question.</p>
                      <p>Write your code in the provided editor. Ensure it meets the required specifications.</p>
                  </li>
                  <li>
                      <h4>Submission: </h4>
                      <p>Click on the “Submit” button once you’ve completed the assignment. Ensure all questions are answered before submitting. </p>
                  </li>
                  <li>
                      <h4>Eligibility:</h4>
                      <p>A score of 70% or above is required to move forward to the interview round.</p>
                  </li>
                </ul>
            </div>
            <div className="back_skp_btn">
                <div className="back_skp_btn_left">
                  <button className="inst_back" onClick={handlGoBack}>Back</button>
                </div> 
                <div className="back_skp_btn_right">
                    {/* <button className="skip_btn" onClick={startInterview}>
                      Skip Mock 
                    </button> */}
                    <button className="skip_btn" onClick={() => {
                      navigate(`/applicant-dashboard/related-jobs/${jobId}/written-assessment-instruction/${jobname}/${companyName}/${assignment_id}`)
                    }}>
                      Skip Mock 
                    </button>
                    <button className="assesment_btn" onClick={onClickAssessmentTest}>
                      Proceed Mock Assessment
                    </button>
                </div>     
            </div>
          </div>
      </Modal>
      <Modal
        size="xl"
        show={lgShow}
        // dialogClassName="modal-100w"
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body className="mod_body_padding">
          <Container>
            <Row>
              <Col xs={6} md={4}>
                <div>
                  <DateCalendarValue onDateChange={handleDateChange} />
                  <div className=" selected_date">
                    <p>
                      {selectedDate},{selectedTime}
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={6} md={4}>
                <div className="time">
                  <ResponsiveTimePickers onTimeChange={handleTimeChange} />
                </div>
              </Col>
              <Col xs={6} md={4}>
                <div className="candidate_data">
                  <div>
                    <div>
                      <h6>{UserName.full_name}</h6>
                      <p className="des_name">{SceduleInterviewJob.title}</p>
                    </div>
                    <div>
                      <p className="image_time">
                        <span>
                          <img src={time} alt="" />
                          {/* <i class="fas fa-clock clock-icon"></i> */}
                        </span>
                        45 Mins
                      </p>
                      <p>
                        {selectedDate},{selectedTime}
                      </p>
                    </div>
                  </div>
                  <div className="button_section">
                    {/* Conditional rendering of loader or confirm text */}
                    <button
                      className="confirm_btn"
                      onClick={ScheduleInterviewButton}
                      disabled={continueLoading}
                    >
                      {continueLoading ? <LoaderApply /> : "Confirm"}
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          {/* <div className="datedetails_main">
            <div className="datetimepicker_container">
              <div>
                <DateCalendarValue onDateChange={handleDateChange} />
                <div className=" selected_date">
                  <p>
                    {selectedDate},{selectedTime}
                  </p>
                </div>
              </div>
              <div>
                <ResponsiveTimePickers onTimeChange={handleTimeChange} />
              </div>
            </div>
            <div className="candidate_data">
              <div>
                <div>
                  <h6>{UserName.full_name}</h6>
                  <p className="des_name">{SceduleInterviewJob.title}</p>
                </div>
                <div>
                  <p className="image_time">
                    <span>
                      <img src={time}/>
                    </span>
                    45 Mins
                  </p>
                  <p>
                    {selectedDate},{selectedTime}
                  </p>
                </div>
              </div>

              <div className="button_section" onClick={ScheduleInterviewButton}>
                <button className="confirm_btn">Confirm</button>
              </div>
            </div>
          </div> */}
        </Modal.Body>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        className="confirm-modal"
      >
        <Modal.Body>
          <section className="spacer">
            <div className="cong_sec_new_inner_head_inner bg-blue">
              <img src={thick_right_icn} alt="" />
              <h6>This Interview is scheduled Successfully</h6>
              <p>We'll Notify You Before Your Interview Begins</p>
            </div>
            <div className="intervier_details_main">
              <div>
                <p>
                  <span>What </span> {UserName.full_name}
                </p>
              </div>
              <div>
                <p>
                  <span>When </span> {selectedDate},{selectedTime}
                </p>
              </div>
              <div>
                <p>
                  <span>Who </span> Artificial Intelligent (AI)
                </p>
              </div>
            </div>
            <div className="bestof">
              <h4> We wish you the best of luck!</h4>
              <button
                variant="secondary"
                onClick={handleClose}
                className="back_job"
              >
                Back to Job
              </button>
            </div>
          </section>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default InterviewModule;

export const StayPop = (status) => {
  return (
    <div className="stay_connect_sec">
      <h5>Stay Connected!</h5>
      <p>Your application is currently being analyzed.</p>
      <img src={spin_circle} alt="" />
    </div>
  );
};

export const Congrats = (status) => {
  return (
    <div className="congratu_sec">
      <img src={thick_right_icn} alt="" />
      <h5>Congratulations! </h5>
      <p>You have qualified for the interview with AI.</p>
    </div>
  );
};

export const Sheduleint = ({ jobId, companyName, jobname, onlyBackButton, assignment_id }) => {
  return (
    <div className="Sheduleint_sec">
      {onlyBackButton ? (
        <>
          <img src={int_frm} alt="" />
          <h6>Your application is under review.</h6>
          <ul className="Sheduleint_sec_list">
            <li>
              <Link className="back_jobs" to="/applicant-dashboard">
                Back to Jobs
              </Link>
            </li>
          </ul>
        </>
      ) : (
        <>
          <img src={int_frm} alt="" />
          <h6>Would you like to move forward with your AI interview now?</h6>
          <ul className="Sheduleint_sec_list">
            <li>
              <Link className="back_jobs" to="/applicant-dashboard">
                Back to Jobs
              </Link>
            </li>
            <li>
              <Link
                to={`${!assignment_id ? `/applicant-dashboard/related-jobs/${jobId}/interview-instruction/${jobname}/${companyName}` : 
                  `/applicant-dashboard/related-jobs/${jobId}/interview-module/${jobname}/${companyName}/${assignment_id}`}`}
                className="yes_btn"
              >
                Yes
              </Link>
            </li>
          </ul>
        </>
      )}
    </div>
  );
};

// const createInterview = async (jobId, token,status) => {
//   const { date, time } = getCurrentFormattedDateTime();
//   const url =
//     "https://backend-haire-web.mycro.news/interviews/create-interview/";
//   const body = {
//     date,
//     start_time: time,
//     job: jobId,
//   };
//   const headers = {
//     Authorization: `Token ${token}`,
//     "Content-Type": "application/json",
//   };

//   try {
//     const response = await axios.post(url, body, { headers });
//     return response.data;
//   } catch (error) {
//     console.error(
//       "Error creating interview:",
//       error.response?.data || error.message
//     );
//     throw error; // Rethrow to handle it in the component
//   }
// };
