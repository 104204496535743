import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  ai_btn_img,
  gen_img,
  dollar_img,
  Question_Mark,
} from "../../../../../assets/images/images";
import assignmentStore from "../../../../../zustand/assignmentStore";
import { useDispatch, useSelector } from "react-redux";
import { updateJobDetails } from "../../../../../redux/reducers/jobReducer";
import {
  carousel_3,
  carousel_2,
  carousel_1,
} from "../../../../../assets/images/images";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { toast } from "react-toastify";
import { Typography } from "@mui/material";
import ScoreDisplay from "./ScoreDisplay";
import ThreeDotLoader from "../../../../../components/Loaders/ThreeDotLoader/ThreeDotLoader";
import QuestionTypeDropdown from "../../../../../components/CommonComponents/QuestionTypeDropdown";

// Constants to avoid magic numbers/strings
const CONSTANTS = {
  REGENERATE_TIMEOUT: 5000,
  GENERATE_MODAL_TIMEOUT: 2000,
  DEFAULT_COUNT: 15,
  MIN_COUNT: 0,
  QUESTION_OPTIONS: [
    { value: "MCQ", label: "Multiple Choice Question" },
    { value: "CODING", label: "Coding" },
    { value: "SHORT", label: "Short Answers" },
    { value: "ESSAY", label: "Essay" },
  ],
  QUESTIONS_COUNT_OPTIONS: [5, 10].map((num) => ({
    value: num,
    label: num.toString(),
    credits: Math.ceil(num / 5),
  })),
};

const QuestionModal = ({
  form,
  isOpen,
  onClose,
  onGenerate,
  questions,
  loading,
  dispatch,
  questionType,
  questionCount,
  generateQuestions,
  duration,
  setQuestions,
}) => {
  const token = useSelector((state) => state.auth.token);
  const jobDetails = useSelector((state) => state.job.details);
  const user_id = useSelector((state) => state.auth.user?.user_id);
  const jobPosition = form.getFieldValue("title");
  const jobDescription = form.getFieldValue("description"); // Get current value from form
  const [showAddQuestions, setShowAddQuestions] = useState(false);
  const [manualQuestions, setManualQuestions] = useState(
    jobDetails?.assignment?.questions || []
  );
  const defaut_count = 5;
  const [selectedCount, setSelectedCount] = useState(defaut_count); //default count
  const [selectedQuestionType, setSelectedQuestionType] = useState(
    questionType ?? []
  );
  const [questionToDelete, setQuestionToDelete] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editQuestionIndex, setEditQuestionIndex] = useState(null);
  const [singleQuestionLoadingIndex, setSingleQuestionLoadingIndex] =
    useState(null);
  const questionModalRef = useRef(null);
  const [editedQuestion, setEditedQuestion] = useState({
    question: "",
    options: {},
  });
  const [isValid, setIsValid] = useState(true); // State to track validation

  // Calculate total points from manualQuestions
  const totalPoints = manualQuestions?.reduce(
    (acc, question) => acc + (question?.score || 0),
    0
  );
  const handleClickOutside = (event) => {
    if (
      questionModalRef.current &&
      !questionModalRef.current.contains(event.target)
    ) {
      setShowAddQuestions(false);
      setSelectedCount(defaut_count);
    }
  };
  useEffect(() => {
    if (showAddQuestions) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showAddQuestions]);
  // Initialize manualQuestions when modal opens
  useEffect(() => {
    if (isOpen) {
      // If there are existing questions in jobDetails, use those
      if (jobDetails?.assignment?.questions?.length > 0) {
        setManualQuestions(jobDetails.assignment.questions);
      }
      // If new questions are being generated, use those
      else if (questions?.length > 0) {
        setManualQuestions(questions);
      }
      // Otherwise start with empty array
      else {
        setManualQuestions([]);
      }
    }
  }, [isOpen, jobDetails?.assignment?.questions]);

  // Track the latest questions for comparison
  const previousQuestionsRef = useRef([]);

  // Update previousQuestionsRef when questions change
  useEffect(() => {
    if (questions?.length > 0) {
      // const newQuestions = questions.filter(
      //   (newQ) =>
      //     !previousQuestionsRef.current.some(
      //       (prevQ) => prevQ.question === newQ.question
      //     )
      // );

      // setManualQuestions((prevManualQuestions) => [
      //   ...prevManualQuestions,
      //   ...newQuestions,
      // ]);
      setManualQuestions(questions);
      previousQuestionsRef.current = questions;
      setSingleQuestionLoadingIndex(null);
    }
  }, [questions]);

  const addQuestions = async () => {
    if (!selectedCount || !selectedQuestionType) return;
    setShowAddQuestions(false);
    setSelectedCount(defaut_count);

    try {
      await generateQuestions(
        token,
        {
          job_title: jobPosition,
          job_description: jobDescription,
          question_types: selectedQuestionType,
          num_questions: selectedCount,
          previous_questions: manualQuestions,
        },
        user_id,
        "batch"
      );
      // setManualQuestions((prevQuestions) => [...prevQuestions, ...questions]);
    } catch (error) {
      console.error("Error generating questions:", error);
      toast.error("Failed to generate questions. Please try again.");
    }
  };

  const confirmDeleteQuestion = (index) => {
    setQuestionToDelete(index);
    setDeleteModal(true);
  };

  const deleteQuestion = () => {
    if (questionToDelete !== null) {
      setManualQuestions((prevQuestions) => {
        const updatedQuestions = prevQuestions.filter(
          (_, index) => index !== questionToDelete
        );
        return updatedQuestions;
      });
      setDeleteModal(false);
      setQuestionToDelete(null);
    }
  };

  const handleAddQuestions = async () => {
    await form.setFieldsValue({
      assignment: { questions: manualQuestions },
      is_active_written_assessment: manualQuestions.length > 0,
    });
    dispatch(
      updateJobDetails({
        ...jobDetails,
        assignment: {
          // title: jobDetails?.title,
          total_marks: totalPoints,
          time_to_complete: duration,
          questions: manualQuestions,
        },
        is_active_written_assessment: manualQuestions.length > 0,
      })
    );
    handleCloseModal();
  };

  const handleEditQuestion = (index) => {
    setEditQuestionIndex(index);
    setEditedQuestion({ ...manualQuestions[index] });
  };

  const validateInputs = () => {
    // Ensure the question text is not empty
    if (!editedQuestion.question.trim()) {
      // setIsValid(false);
      return false;
    }

    // Ensure there are options, if the question has options
    if (
      editedQuestion.options &&
      Object.values(editedQuestion.options).some((option) => !option.trim())
    ) {
      // setIsValid(false);
      return false;
    }

    // setIsValid(true);
    return true;
  };

  const handleSaveEdit = () => {
    if (validateInputs()) {
      setManualQuestions((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        updatedQuestions[editQuestionIndex] = editedQuestion;
        setQuestions(updatedQuestions);
        return updatedQuestions;
      });
      setEditQuestionIndex(null);
      setEditedQuestion({ question: "", options: {} });
    } else {
      toast.error("Question text and options cannot be empty.");
    }
  };

  const handleRegenerate = async () => {
    setShowAddQuestions(false);
    setSelectedCount(defaut_count);
    onGenerate({
      questionType,
      questionCount: questionCount,
      previousQuestions: questions,
    });
  };

  console.log(questionType);

  const handleRegenerateSingleQuestion = async (index, question) => {
    try {
      // Create a copy of the current questions and exclude the question being regenerated
      setSingleQuestionLoadingIndex(index);
      // const previousQuestions = manualQuestions.filter((_, i) => i !== index);

      // Generate a single new question

      try {
        await generateQuestions(
          token,
          {
            job_title: jobPosition,
            job_description: jobDescription,
            question_types: [question?.question_type] || ["MCQ"],
            num_questions: 1,
            previous_questions: manualQuestions, // Pass the updated list excluding the current question
          },
          user_id,
          "single",
          index
        ); // Pass the index to update the specific question
      } catch (error) {
        console.error("Error regenerating question:", error);
        toast.error("Failed to regenerate question. Please try again.");
        setSingleQuestionLoadingIndex(null);
      }

      // Check if we received a new question
      // if (questions && questions[0]) {
      //   // Update only the specific question at the given index
      //   setManualQuestions((prevQuestions) => {
      //     const updatedQuestions = [...prevQuestions];
      //     updatedQuestions[index] = questions[0]; // Replace the question at the specified index
      //     return updatedQuestions;
      //   });
      // } else {
      //   console.error("No question was generated");
      //   toast.error("Failed to regenerate question. Please try again.");
      // }
    } catch (error) {
      console.error("Error regenerating question:", error);
      toast.error("Failed to regenerate question. Please try again.");
      setSingleQuestionLoadingIndex(null);
    }
  };

  const handleCloseModal = () => {
    onClose();
    setEditQuestionIndex(null);
    setShowAddQuestions(false);
    setSelectedCount(defaut_count);
  };

  const handleAddQuestionModalChange = () => {
    setShowAddQuestions(!showAddQuestions);

    if (showAddQuestions) {
      setSelectedCount(defaut_count);
    }
  };

  return (
    <Modal
      show={isOpen}
      onHide={handleCloseModal}
      className="written_test_modal_sec"
      backdrop="static"
    >
      <div className="written_test_modal_sec_inner">
        <div className="written_test_modal_sec_inner_head">
          <h6>Written Test for AI interview</h6>
          <div className="enhance_new">
            <button disabled={loading} onClick={handleRegenerate}>
              <img src={ai_btn_img} />
              Regenerate with A.I
            </button>
          </div>
        </div>
        <div className="written_test_modal_sec_inner_body">
          <div className="written_test_modal_sec_bo_head">
            <div className="written_test_modal_sec_bo_head_left">
              <img src={dollar_img} />
              <h6>1 Credit will be consumed per 5 Questions</h6>
            </div>
            <div className="written_test_modal_sec_bo_head_right">
              <button
                onClick={handleAddQuestionModalChange}
                disabled={loading}
                style={{ backgroundColor: loading ? "#f0f0f0" : "#fff" }}
              >
                <i className="fas fa-plus"></i> Add Questions
              </button>
              {showAddQuestions && (
                <div className="add_que_sec_new" ref={questionModalRef}>
                  <div className="generate_modal_wriitten_inner_head">
                    <h6>Add Questions</h6>
                  </div>
                  <div className="generate_modal_wriitten_inner_body">
                    <div className="generate_modal_select_box2">
                      <div className="generate_modal_select_box_single">
                        <QuestionTypeDropdown
                          options={CONSTANTS.QUESTION_OPTIONS}
                          selectedOptions={selectedQuestionType}
                          setSelectedOptions={setSelectedQuestionType}
                        />
                      </div>
                      <div className="generate_modal_select_box_single">
                        <label>
                          No of questions<pre>*</pre>
                        </label>
                        <select
                          value={selectedCount}
                          className="ques_list_sec"
                          onChange={(e) =>
                            setSelectedCount(Number(e.target.value))
                          }
                        >
                          {[5, 10].map((count) => (
                            <option
                              key={count}
                              value={count}
                              className="ques_list_sec_text2"
                            >
                              <h6>
                                Upto {count}{" "}
                                {count === 1 ? "Question" : "Questions"}
                              </h6>
                              <h6>
                                {`${
                                  count / 5 <= 1
                                    ? ` ${count / 5} Credit`
                                    : ` ${count / 5} Credits`
                                }`}
                              </h6>
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="ques_add_tag_new">
                      <span>5 Questions = 1 Credit</span>
                    </div>
                    <div className="enhance22">
                      <button
                        disabled={loading || selectedQuestionType?.length === 0}
                        onClick={addQuestions}
                      >
                        <img src={ai_btn_img} />
                        Regenerate with A.I
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="points-wrapper">
            <ScoreDisplay manualQuestions={manualQuestions} />
            {!loading && (
              <Typography
                component={"span"}
                fontSize={14}
                fontWeight={900}
                color={"#0B4A96"}
              >
                Total: {totalPoints} Points
              </Typography>
            )}
          </div>
          <div
            className="written_test_modal_sec_inner_new"
            style={{ overflow: loading ? "hidden" : "scroll" }}
          >
            {manualQuestions.length > 0 && !loading ? (
              manualQuestions.map((question, index) => (
                <div
                  key={index}
                  className="written_test_modal_sec_inner_single"
                >
                  <div className="written_test_modal_sec_inner_single_text">
                    {editQuestionIndex === index ? (
                      <>
                        <input
                          type="text"
                          value={editedQuestion.question}
                          onChange={(e) =>
                            setEditedQuestion({
                              ...editedQuestion,
                              question: e.target.value,
                            })
                          }
                          placeholder="Enter question"
                        />
                        {editedQuestion.options && (
                          <ul className="custom_list">
                            {Object.entries(editedQuestion.options).map(
                              ([key, value], optionIndex) => (
                                <li key={optionIndex}>
                                  <input
                                    type="text"
                                    value={value}
                                    onChange={(e) =>
                                      setEditedQuestion({
                                        ...editedQuestion,
                                        options: {
                                          ...editedQuestion.options,
                                          [key]: e.target.value,
                                        },
                                      })
                                    }
                                    placeholder={`Option ${key}`}
                                  />
                                </li>
                              )
                            )}
                          </ul>
                        )}
                      </>
                    ) : (
                      <>
                        <h4>
                          {index + 1}. {question?.question}
                        </h4>
                        {question?.options && (
                          <ul>
                            {Object.entries(question?.options).map(
                              ([key, value]) => (
                                <li key={key}>
                                  {key}) {value}
                                </li>
                              )
                            )}
                          </ul>
                        )}
                      </>
                    )}
                  </div>
                  <div className="written_test_modal_sec_inner_single_icons">
                    <ul>
                      <li>
                        {editQuestionIndex === index ? (
                          <button
                            onClick={handleSaveEdit}
                            // disabled={!isValid} // Disable save button if not valid
                          >
                            <i className="fas fa-save"></i> Save
                          </button>
                        ) : (
                          <Link
                            onClick={() => handleEditQuestion(index)}
                            style={{
                              pointerEvents:
                                singleQuestionLoadingIndex === index
                                  ? "none"
                                  : "auto",
                            }}
                          >
                            <i className="fas fa-pen"></i>
                          </Link>
                        )}
                      </li>
                      <li
                        style={{
                          pointerEvents:
                            editQuestionIndex === index ||
                            singleQuestionLoadingIndex === index
                              ? "none"
                              : "auto",
                        }}
                      >
                        <Link onClick={() => confirmDeleteQuestion(index)}>
                          <i className="fas fa-trash-alt"></i>
                        </Link>
                      </li>
                      <li
                        style={{
                          pointerEvents:
                            editQuestionIndex === index ? "none" : "auto",
                        }}
                      >
                        {singleQuestionLoadingIndex === index ? (
                          <ThreeDotLoader />
                        ) : (
                          <button
                            disabled={loading}
                            onClick={() =>
                              handleRegenerateSingleQuestion(index, question)
                            }
                          >
                            <i className="fas fa-sync-alt"></i> Regenerate
                          </button>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              ))
            ) : manualQuestions.length > 0 ? (
              <></>
            ) : (
              <p></p>
            )}
            {loading && (
              <div className="overlay_sec_regenerate_box">
                <div className="overlay_sec_regenerate_box_inner">
                  <p>Generating questions...</p>
                  <img src={gen_img} alt="Generating" />
                </div>
              </div>
            )}
          </div>
          <div className="add_cancel_btn_sec_modal">
            <button onClick={handleCloseModal} className="draft_btn">
              Cancel
            </button>
            <button onClick={handleAddQuestions} className="save_btn">
              Save
            </button>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {deleteModal && (
        <Modal
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          className="delete_modal_sec_new"
        >
          <div className="delete_modal_sec_new_inner">
            <img src={Question_Mark} />
            <p>Are you sure you want to remove this question?</p>
            <ul className="delete_modal_sec_new_btns">
              <li>
                <Link
                  className="cance_btn_mod"
                  onClick={() => setDeleteModal(false)}
                >
                  Cancel
                </Link>
              </li>
              <li>
                <Link className="remove_btn_mod" onClick={deleteQuestion}>
                  Remove
                </Link>
              </li>
            </ul>
          </div>
        </Modal>
      )}
    </Modal>
  );
};

const GenerateModal = ({
  isOpen,
  onClose,
  onGenerate,
  loading,
  questionType,
  questionCount,
  setQuestionType,
  setQuestionCount,
}) => {
  const handleGenerate = () => {
    onGenerate({
      questionType,
      questionCount,
    });
  };

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      className="generate_modal_wriitten centered"
    >
      {loading ? (
        <div className="generate_questions_modal_inner">
          <p>Generating questions...</p>
          <img src={gen_img} />
        </div>
      ) : (
        <div className="generate_modal_wriitten_inner">
          <div className="generate_modal_wriitten_inner_head">
            <h6>Generate written test with A.I</h6>
            <button onClick={onClose}>
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div className="generate_modal_wriitten_inner_body">
            <div className="generate_modal_select_box">
              <div className="generate_modal_select_box_single new_ques_type">
                <QuestionTypeDropdown
                  options={CONSTANTS.QUESTION_OPTIONS}
                  selectedOptions={questionType}
                  setSelectedOptions={setQuestionType}
                />
              </div>
              <div className="generate_modal_select_box_single">
                <label>
                  No of questions<pre>*</pre>
                </label>
                <select
                  value={questionCount}
                  onChange={(e) => setQuestionCount(Number(e.target.value))}
                >
                  {CONSTANTS.QUESTIONS_COUNT_OPTIONS.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="ques_add_tag_new">
              <span>5 Questions = 1 Credit</span>
            </div>
            <div className="enhance_new">
              <button
                disabled={loading || questionType?.length === 0}
                onClick={handleGenerate}
              >
                <img src={ai_btn_img} />
                Generate questions
              </button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

const WrittenTestQuestions = ({ form }) => {
  const dispatch = useDispatch();
  const jobDetails = useSelector((state) => state.job.details);
  const assignmentQuestions = jobDetails?.assignment?.questions || [];
  const is_active_written_assessment = jobDetails?.is_active_written_assessment;
  const token = useSelector((state) => state.auth.token);
  const { questions, isLoading, error, generateQuestions, setQuestions } =
    assignmentStore();
  const jobPosition = form.getFieldValue("title");
  const user_id = useSelector((state) => state.auth.user?.user_id);
  const jobDescription = form.getFieldValue("description"); // Get current value from form
  const [addData, setAddData] = useState(false);
  const [questionType, setQuestionType] = useState([]);
  const [questionCount, setQuestionCount] = useState(
    CONSTANTS.QUESTIONS_COUNT_OPTIONS[0].value
  );
  const formQuestions = form.getFieldValue("assignment")?.questions;

  const addModal = () => {
    // if (!jobDetails?.is_active_written_assessment) {
    //   toast.info("Please enable Written Assessment before generating.");
    //   return;
    // }
    if (formQuestions?.length > 0) {
      setAiInterviewMod(true);
    } else {
      setAddData(true);
    }
  };
  const CloseModal = () => {
    setQuestionType([]);
    setAddData(false);
  };

  useEffect(() => {
    if (formQuestions?.length > 0) {
      setQuestions(formQuestions);
    }
  }, []);

  const [geneAddData, setgeneAddData] = useState(false);
  const [aiInterviewMod, setAiInterviewMod] = useState(false);

  const handleGenerate = async ({
    questionType,
    questionCount,
    previousQuestions,
  }) => {
    try {
      await generateQuestions(
        token,
        {
          job_title: jobPosition,
          job_description: jobDescription,
          question_types: questionType,
          num_questions: questionCount,
          previous_questions: previousQuestions,
        },
        user_id,
        "all"
      );
    } catch (error) {
      console.error("Error generating questions:", error);
      toast.error("Failed to generate questions. Please try again.");
    }

    setAddData(false);
    setgeneAddData(true);
  };

  useEffect(() => {
    if (!geneAddData) return;

    setgeneAddData(false);
    setAiInterviewMod(true);
  }, [geneAddData]);

  const aiInterviewModClose = () => {
    setAiInterviewMod(false);
  };

  const [count, setCount] = useState(
    jobDetails?.assignment?.time_to_complete || 15
  );

  const updateDuration = (newDuration) => {
    form.setFieldsValue({ assignment: { time_to_complete: newDuration } });
    dispatch(
      updateJobDetails({
        ...jobDetails,
        assignment: {
          ...jobDetails?.assignment,
          time_to_complete: newDuration,
        },
      })
    );
  };
  const increment = () => {
    setCount((prevCount) => Math.min(prevCount + 5, 30)); // Increase by 5, max 30
    updateDuration(Math.min(count + 5, 30)); // Update form and store
  };

  const decrement = () => {
    setCount((prevCount) => Math.max(prevCount - 5, 10)); // Decrease by 5, min 10
    updateDuration(Math.max(count - 5, 10)); // Update form and store
  };

  const handleCheck = (e) => {
    form.setFieldsValue({ is_active_written_assessment: e.target.checked });
    dispatch(
      updateJobDetails({
        ...jobDetails,
        is_active_written_assessment: e.target.checked,
      })
    );
  };

  const [carousel, setCarousel] = useState(false);
  const carouselMod = () => {
    setCarousel(true);
  };
  const carouselClose = () => {
    setCarousel(false);
  };

  return (
    <>
      <div className="recriuter_step_2_inner_btm_single">
        <div className="recriuter_step_2_inner_btm_single_top">
          <div className="recriuter_step_2_inner_btm_single_top_left new_left">
            <div className="toggle_sec">
              <label class="switch">
                <label className="switch">
                  <input
                    type="checkbox"
                    // value={is_active_written_assessment}
                    checked={jobDetails?.is_active_written_assessment}
                    onChange={handleCheck}
                    disabled={assignmentQuestions?.length === 0}
                  />
                  <span className="slider round"></span>
                </label>
              </label>
            </div>
            <h5>
              Written Test
              <button onClick={carouselMod}>What is a Written Test?</button>
            </h5>
          </div>
          <div className="recriuter_step_2_inner_btm_single_top_right">
            <h6>1 Credit for 5 Questions</h6>
          </div>
        </div>
        <div className="recriuter_step_2_inner_btm_single_btm_inner">
          <p>
            A written test evaluates candidates' skills before the interview,
            and AI enhances this process by generating role-specific, relevant,
            and balanced questions.
          </p>
          <button className="fa_plus_btn" onClick={addModal}>
            {formQuestions?.length > 0 ? (
              <>
                <i class="fas fa-pencil"></i> {"Edit"}
              </>
            ) : (
              <>
                <i class="fas fa-plus"></i> {"Generate"}
              </>
            )}
          </button>
        </div>
        {assignmentQuestions?.length > 0 && (
          <>
            <div className="ques_add_tag">
              <span>
                <i class="fas fa-check-circle"></i>
                {`${assignmentQuestions?.length} Questions Added`}
              </span>
            </div>
            <div className="written_test_duration_sec">
              <div className="written_test_duration_sec_left">
                <h4>Written Test Timer</h4>
                <p>
                  Once you set the timer, the assessment will auto-submit when
                  time is up. {/* <span>(in minutes).</span> */}
                </p>
              </div>
              <div className="written_test_duration_sec_right">
                <div className="written_test_duration_sec_right_inner">
                  <button onClick={decrement} className="inc_btn">
                    -
                  </button>
                  <div className="count_btn_sec">{count}</div>
                  <button onClick={increment} className="inc_btn">
                    +
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <Modal
        show={carousel}
        onHide={carouselClose}
        className="modal_main_sec owl_modal_sec"
      >
        <div className="modal_main_sec_inner">
          <div className="modal_main_sec_inner_head">
            <h6>Written Test for AI interview</h6>
            <button onClick={carouselClose} className="modal_close_btn">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div className="carousel_main_sec_body">
            <OwlCarousel
              loop={true}
              margin={10}
              nav
              items={1}
              dots={true}
              autoPlay={true}
              autoplayTimeout={3000}
            >
              <div className="carousel_main_sec_body_single">
                <img src={carousel_2} />
              </div>
              <div className="carousel_main_sec_body_single">
                <img src={carousel_1} />
              </div>
            </OwlCarousel>
          </div>
        </div>
      </Modal>

      <GenerateModal
        isOpen={addData}
        onClose={CloseModal}
        onGenerate={handleGenerate}
        loading={isLoading}
        questionType={questionType}
        questionCount={questionCount}
        setQuestionType={setQuestionType}
        setQuestionCount={setQuestionCount}
      />

      <QuestionModal
        form={form}
        questions={questions ?? formQuestions}
        isOpen={aiInterviewMod}
        onClose={aiInterviewModClose}
        onGenerate={handleGenerate}
        loading={isLoading}
        dispatch={dispatch}
        questionType={questionType}
        questionCount={questionCount}
        generateQuestions={generateQuestions}
        duration={count}
        setQuestions={setQuestions}
      />
    </>
  );
};

export default WrittenTestQuestions;
