import { useEffect, useState } from "react";
import instance from "../api/instance";
import { useSelector } from "react-redux";

const useSuggestionsGenerator = ({
  type,
  previousSuggestions,
  keyword,
  experience,
  jobProfile,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [debouncedKeyword, setDebouncedKeyword] = useState(keyword);
  const token = useSelector((state) => state.auth.token);

  // Debounce logic
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedKeyword(keyword);
    }, 500); // Adjust the delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [keyword]);

  useEffect(() => {
    const generateResponsibilities = async () => {
      if (debouncedKeyword?.trim() !== "") {
        try {
          const response = await instance.post(
            "/ai/keyword_generate/",
            {
              type: type,
              previous: previousSuggestions ?? "",
              keyword: debouncedKeyword ?? "",
              experience: experience ?? "",
              job_profile: jobProfile ?? "",
            },
            {
              headers: {
                Authorization: `Token ${token}`,
              },
            }
          );

          // Assuming the response includes a list of responsibilities
          const newResponsibilities = response.data.newkeyword;
          setSuggestions(newResponsibilities);
        } catch (error) {
          console.error("Error generating responsibilities:", error);
        }
      } else {
        setSuggestions([]); // Clear responsibilities if keyword is empty
      }
    };

    generateResponsibilities();
  }, [debouncedKeyword, experience, previousSuggestions]); // Only re-run the effect if debouncedKeyword, experience, or previousResponsibilities change

  return suggestions;
};

export default useSuggestionsGenerator;
