import React, { useEffect, useRef, useState } from "react";
import { dollar_img } from "../../assets/images/images";
import { useSelector } from "react-redux";

const TotalCreditSec = () => {
  const jobDetails = useSelector((state) => state.job.details);
  const isAssingmentActive = jobDetails?.is_active_written_assessment;
  const assignmentQuestions = jobDetails?.assignment?.questions;
  const interviewDuration = jobDetails?.interview_duration;
  const [credit, setCredit] = useState(false);
  const creditRef = useRef(null);

  // Calculate credits based on number of questions
  const calculateQuestionCredits = () => {
    if (!isAssingmentActive || !assignmentQuestions?.length) return 0;
    return Math.ceil(assignmentQuestions.length / 5);
  };

  // Calculate interview credits based on duration
  const calculateInterviewCredits = () => {
    if (!interviewDuration) return 0;

    switch (interviewDuration) {
      case "00:15:00":
        return 1;
      case "00:30:00":
        return 2;
      case "00:45:00":
        return 3;
      default:
        return 0;
    }
  };

  // Calculate total credits
  const calculateTotalCredits = () => {
    const questionCredits = calculateQuestionCredits();
    const interviewCredits = calculateInterviewCredits();
    return questionCredits + interviewCredits;
  };

  const handleClick = () => setCredit((prev) => !prev);

  // Close when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (creditRef.current && !creditRef.current.contains(event.target)) {
        setCredit(false);
      }
    };
    if (credit) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [credit]);

  const totalCredits = calculateTotalCredits();
  const interviewCredits = calculateInterviewCredits();

  const testData = [
    {
      id: "1",
      title: "Written Test",
      credit: calculateQuestionCredits(),
      imgSrc: dollar_img,
    },
    {
      id: "2",
      title: "Credit per Interview",
      credit: interviewCredits,
      imgSrc: dollar_img,
    },
  ];

  return (
    <>
      <div className="recriuter_step_2_inner_top">
        <div className="recriuter_step_2_inner_top_left">
          <h6>Total Credits Used for this Job per Interview</h6>
          <p>(Credits will be deducted only after interviewing for this job)</p>
          <button className="active" onClick={handleClick}>
            Credit Summary<i className="fas fa-chevron-down"></i>
          </button>
          {credit && (
            <div className="credit_summary" ref={creditRef}>
              <div className="credit_summary_inner">
                <ul>
                  {testData.map((data) => (
                    <li key={data.id}>
                      <h6>{data.title}</h6>
                      <div className="dollar_sign_img">
                        <img src={data.imgSrc} alt="credit" />
                        <span>
                          {data.credit}&nbsp;{data.credit === 1 ? "Credit" : "Credits"}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className="recriuter_step_2_inner_top_right">
          <img src={dollar_img} alt="credit" />
          <span>
            {totalCredits} {totalCredits === 1 ? "Credit" : "Credits"}
            <i style={{fontWeight:400}}>per Interview</i>
          </span>
        </div>
      </div>
    </>
  );
};

export default TotalCreditSec;
