import { ReactComponent as CongratulationsIcon } from "../../../../assets/images/thick-right-icn.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createInterview } from "../../../../redux/actions/jobSeekerActions";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import LoaderApply from "../../../../components/CommonComponents/Loader/LoaderApply";

export const PracticeResult = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const token = useSelector((state) => state.auth.token);
  const { jobId, jobname, companyName } = useParams();
  const routeStateData = location.state;

  const [loading, setLoading] = useState(false);

  const handleBacktoJobs = () => {
    navigate("/applicant-dashboard", { replace: true });
  };

  const handleStartInterview = async () => {
    try {
      setLoading(true);
      const data = await dispatch(createInterview(jobId, token, 1));
      const newInterviewId = data.data.id;

      navigate(
        `/applicant-dashboard/related-jobs/${jobId}/start-interview/${newInterviewId}/interview-panel/interview-panel-ready/${jobname}/${companyName}`,
        { replace: true, state: { data: routeStateData } }
      );
    } catch (error) {
      console.error("Error creating interview:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="cong_sec_new">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="cong_sec_new_inner">
                <div className="cong_sec_new_inner_head">
                  <div className="cong_sec_new_inner_head_inner">
                    <CongratulationsIcon />
                    <br />
                    <h6>Mock Interview Result</h6>
                    <p>
                      Your mock session is completed. Please proceed to the
                      AI Avatar interview
                    </p>
                  </div>
                </div>
                <div className="bavk_jobs">
                  <button
                    disabled={loading}
                    className="skip_btn"
                    onClick={handleBacktoJobs}
                  >
                    Back to Jobs
                  </button>
                  <button
                    disabled={loading}
                    className="start_interview"
                    onClick={handleStartInterview}
                  >
                    {loading ? (
                      <LoaderApply color="white" />
                    ) : (
                      "Start AI Avatar Interview"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
