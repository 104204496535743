import React from 'react'

const BudgetForJob = () => {
  return (
    <>
       <div className='recriuter_step_2_inner_btm_single brdr_none'>
            <div className='recriuter_step_2_inner_btm_single_top'>
                <div className='recriuter_step_2_inner_btm_single_top_left new_left'>
                    <h5>
                        Budget for the Job<pre>*</pre>
                        <span className='inof_icn'>
                            <i class="fas fa-info-circle"></i>
                            <p className='budget_sec_tool'>Budget for the job represents the total number of credits available for this job listing. Credit usage will remain within the assigned credit budget and will not exceed the allocated limit.</p>
                        </span>
                    </h5>
                </div>
                <div className='recriuter_step_2_inner_btm_single_top_right'>
                    <h6>Recommended: 50 Credits</h6>
                </div>
            </div>
            <div className='budget_job_inner_btm'>
                <div className='budget_job_inner_btm_left'>
                    <p>Set the number of credits you want to allocate from your available balance for this job listing.</p>
                    <input type='text' placeholder='Enter credits per job listing' />
                </div>
                <div className='budget_job_inner_btm_right'></div>
            </div>
        </div>
    </>
  )
}

export default BudgetForJob
