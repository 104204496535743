import React from "react";
import styles from "./AssessmentSummary.module.css";
import { useNavigate } from "react-router-dom";

const PracticeSummary = ({ backUrl, proceedUrl, assessmentResult }) => {
  const navigate = useNavigate();

  const navigateTo = (url) => {
    navigate(url);
  };

  return (
      <section className="haire_bg_ass">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-12">
            <div className={styles.wrapper}>
                <p className={styles.pageTitle}>
                  Haire.ai Mock Assessment - Result
                </p>
                <div className={styles.container}>
                  <h2 className={styles.heading}>
                    Your mock session is completed. Please proceed to the Written
                    Assessment
                  </h2>
                  <div className={styles.summaryContainer}>
                    <table className={styles.table_sec_written}>
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>No. of Questions</th>
                          <th>Points Obtained</th>
                          <th>Maximum Points</th>
                        </tr>
                      </thead>
                      {/* <div className={styles.horizontal_line}></div> */}
                      <tbody>
                        {assessmentResult?.stats_by_question_type &&
                          Object.entries(assessmentResult?.stats_by_question_type).map(
                            ([key, section], index) => (
                              <tr
                                key={index}
                                className={key === "total" ? styles.totalRow : ""}
                              >
                                <td>
                                  <strong>{key.toUpperCase()}</strong>
                                </td>
                                <td>{section.total_questions}</td>
                                {/* <td>{section.correct_questions}</td> */}
                                <td>{section.marks_obtained}</td>
                                <td>{section.total_marks}</td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                  </div>
                  <div className={styles.buttonContainer}>
                    <button
                      className={styles.backButton}
                      onClick={() => navigateTo(backUrl)}
                    >
                      Back to Jobs
                    </button>
                    <button
                      className={styles.proceedButton}
                      onClick={() => navigateTo(proceedUrl)}
                    >
                      Proceed to Written Assessment
                    </button>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PracticeSummary;
