import React from "react";
import styles from "./AssessmentSummary.module.css";
import { useNavigate } from "react-router-dom";


// ASS_COMPLETED = 'COMPLETED'
// ASS_INCOMPLETE = 'INCOMPLETE'
// ASS_FAILED = 'FAILED'
// ASS_PASSED = 'PASSED'
// ASS_ABONDONED = 'ABONDONED'

const WrittenSummary = ({ backUrl, proceedUrl, assessmentResult }) => {
  const navigate = useNavigate();

  const navigateTo = (url) => {
    navigate(url);
  };

  const handleBacktoJobs = () => {
    navigate("/applicant-dashboard", { replace: true });
  };
  


  return (
      <section className="haire_bg_ass">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-12">
            <div className={styles.wrapper}>
                <p className={styles.pageTitle}>Haire.ai Written Assessment - Result</p>
                <div className={styles.container}>
                  {assessmentResult?.assignment_result?.status === "PASSED" ? <h2 className={styles.heading}>Your Written session is completed. Please proceed to the AI Avatar Interview</h2>
                  : <h2 className={styles.heading_failed}>Thanks for applying! We’re not moving forward, but best of luck ahead.</h2>
                  }
                  
                  <div className={styles.summaryContainer}>
                    <table className={styles.table_sec_written}>
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>No. of Questions</th>
                          <th>Points Obtained</th>
                          <th>Maximum Points</th>
                        </tr>
                      </thead>
                      <tbody>
                        {assessmentResult?.stats_by_question_type &&
                          Object.entries(assessmentResult?.stats_by_question_type).map(
                            ([key, section], index) => (
                              <tr
                                key={index}
                                className={key === "total" ? styles.totalRow : ""}
                              >
                                <td>
                                  <strong>{key.toUpperCase()}</strong>
                                </td>
                                <td>{section.total_questions}</td>
                                {/* <td>{section.correct_questions}</td> */}
                                <td>{section.marks_obtained}</td>
                                <td>{section.total_marks}</td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                    </div>
                    </div>
                    <div className={styles.buttonContainer}>
                      <button className={styles.backButton} onClick={handleBacktoJobs}>
                        Back to Jobs
                      </button>
                      <button
                        className={styles.proceedButton}
                        onClick={() => navigateTo(proceedUrl)}
                        disabled={assessmentResult?.assignment_result?.status !== "PASSED"}
                      >
                        Proceed to AI Avatar Interview
                      </button>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </section>    
    
  );
};

export default WrittenSummary;
