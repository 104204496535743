import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { network_check } from "../../../../assets/images/images";
import { random } from "lodash";
const TestInternetConnection = (props) => {
  const imageAddr =
    "https://haire.ai/static/media/haire+node+head.255f888f6dbdf6500436.gif"; // Larger file
  const downloadSize = 6595481; // 100 MB in bytes
  const [isTroubleshoot, setIsTroubleshoot] = useState(false);
  const [speedResult, setSpeedResult] = useState({
    status: false,
    progesStatus: false,
    testError: "",
    mbps: 0,
  });

  // Dynamically calculate ranges based on speed
  const calculateRanges = (status, speed) => {
    if (!status) {
      return [{ color: "gray", count: 48 }];
    } else if (speed >= 0 && speed <= 20) {
      return [
        { color: "red", count: 7 },
        { color: "gray", count: 41 },
      ];
    } else if (speed > 20 && speed <= 40) {
      return [
        { color: "yellow", count: 23 },
        { color: "gray", count: 25 },
      ];
    } else {
      return [{ color: "green", count: 48 }];
    }
  };

  const measureConnectionSpeed = () => {
    let startTime, endTime;
    const download = new Image();

    try {
      startTime = new Date().getTime();
      const cacheBuster = "?nnn=" + startTime;

      download.onload = () => {
        endTime = new Date().getTime();
        showResults();
      };

      download.onerror = (error) => {
        console.error("Error loading image:", error);
        setIsTroubleshoot(true);
        setSpeedResult({
            status: false,
            progesStatus: false,
            testError: "",
            mbps: 0,
          });
      };

      // Start downloading the image
      download.src = imageAddr + cacheBuster;

      const showResults = () => {
        const duration = (endTime - startTime) / 1000; // in seconds
        const bitsLoaded = downloadSize * 8;
        const speedBps = (bitsLoaded / duration).toFixed(2);
        const speedKbps = (speedBps / 1024).toFixed(2);
        const speedMbps = (speedKbps / 1024).toFixed(2);

        setSpeedResult({
          status: true,
          progesStatus: false,
          mbps: speedMbps,
        });
        props.permissionResultRef.current.internet = true;
        props.setPermissionResult("internet");
      };
    } catch (error) {
      console.error("Unexpected error:", error);
      setIsTroubleshoot(true);
      setSpeedResult({
        ...speedResult,
        testError: "An unexpected error occurred while measuring speed",
        progesStatus: false,
      });
    }
  };

  const initiateSpeedDetection = () => {
    setSpeedResult({
      status: false,
      progesStatus: true,
      mbps: 0,
    });
    measureConnectionSpeed();
  };

  // retry 
  const retry = () => {
    props.permissionResultRef.current.internet = false;
    props.setPermissionResult(random(1, 100));
    setSpeedResult({
        status: false,
        progesStatus: true,
        mbps: 0,
      });
      measureConnectionSpeed();
  }
  const ranges = calculateRanges(speedResult.status, speedResult.mbps); // Get ranges dynamically based on speed
  return (
    <>
      <div className="modal_permission_sec_inner_body_right_single">
        <div className="modal_permission_sec_inner_body_right_single_sec">
          <div className="modal_permission_sec_inner_body_right_single_sec_head">
            <div className="head_single_left_sec">
              <img src={network_check} alt="" />
              <h5>Test Internet Connection</h5>
            </div>
            {speedResult.status && (
              <div className="head_single_right_sec">
                <h6>
                  Internet Speed :{" "}
                  <span style={{ color: `${ranges[0]?.color}` }}>
                    {speedResult.mbps} Mbps
                  </span>
                </h6>
              </div>
            )}
          </div>
          <div className="modal_permission_sec_inner_body_right_single_sec_body">
            <h4>Output Level</h4>
            <div className="out_level_internet">
              {ranges.map((range, index) =>
                Array.from({ length: range.count }).map((_, i) => (
                  <div
                    key={`${range.color}-${index}-${i}`}
                    className={`int_box ${range.color}`}
                  ></div>
                ))
              )}
            </div>
          </div>
        </div>
        <div className="right_test_btn">
          {speedResult.status == false && speedResult.progesStatus == false && !isTroubleshoot && (
            <button
              type="button"
              className="test_btn_check"
              onClick={initiateSpeedDetection}
            >
              Test
            </button>
          )}
          {speedResult.status == false &&
            speedResult.progesStatus == false &&
            isTroubleshoot && (
              <button
                type="button"
                className="test_btn_check troubleshoot"
                onClick={() => setIsTroubleshoot(false)}
              >
                Troubleshoot
              </button>
            )}
          {speedResult.status == true && speedResult.progesStatus == false ? (
            <>
                        <button
              type="button"
              className="test_btn_check"
              onClick={retry}
            >
              Retry
            </button>
              <span className="tested_class">
                Tested <FontAwesomeIcon icon={faCheck} />
              </span>
            </>
          ) : speedResult.progesStatus == true ? (
            <>
              <span className="tested_class">Processing..</span>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default TestInternetConnection;
