import { Tabs } from "antd";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Profile from "./profile";
import Assesment from "./assessment";
import Transcript from "./transcript";
import DashboardHeader from "../../../../components/CommonComponents/DashboardHeader";
import { Navbar } from "../../../../components/DashboardComponents/Navbar/Navbar";
import { useEffect, useRef, useState } from "react";
import Written from "./written";
import { useDispatch, useSelector } from "react-redux";
import { downloadVideo, getVideoType } from "../../../../utils/utils";
import { generateDemoPdf } from "../../../../utils/pdfFiles/demoTranscriptPdf";
import { fetchJobApplicantsResult } from "../../../../redux/actions/jobActions";
import useQueryParams from "../../../../hooks/useQueryParams";

const Results = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const {jobId, page} = useQueryParams();
  const token = useSelector((state) => state.auth.token);
  const applicant = useSelector((state) => state?.job?.applicationResult);

  const transcript = applicant?.transcript;
  const assessment = applicant?.assessment;
  const transcriptVideoLink = applicant?.interview_details?.video_link;
  const transcriptVideoType = getVideoType(transcriptVideoLink);
  const [activeTabKey, setActiveTabKey] = useState(location.pathname + location.search);
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const jobProfileDetails = {
    full_name: applicant?.user_full_name,
    job_profile: applicant?.job_title,
  };

  const jobInterviewDetails = {
    title: applicant?.job_title,
    interview: {
      started_at: applicant?.interview_details?.started_at,
      ended_at: applicant?.interview_details?.ended_at,
      date: applicant?.interview_details?.date,
    },
  };

  const handleTabChange = (key) => {
    window.dispatchEvent(new CustomEvent("routeChanged"));
    navigate(key);
    setActiveTabKey(key);
  };

  const getTabItems = () => {
    const items = [
      {
        key: `/view-jobs-data/results/Profile/${id}?jobId=${jobId}&page=${page}`,
        label: "Profile",
        children: <Profile />,
      },
      {
        key: `/view-jobs-data/results/Written/${id}?jobId=${jobId}&page=${page}`,
        label: "Written",
        children: <Written />,
      },
    ];

    if (transcript) {
      items.push({
        key: `/view-jobs-data/results/Transcript/${id}?jobId=${jobId}&page=${page}`,
        label: "Transcript",
        children: <Transcript />,
      });
    }

    if (assessment) {
      items.push({
        key: `/view-jobs-data/results/Assessment/${id}?jobId=${jobId}&page=${page}`,
        label: "Assessment",
        children: <Assesment />,
      });
    }

    return items;
  };

  const handleBackButtonClick = () => {
    window.dispatchEvent(new CustomEvent("routeChanged"));
    navigate(
      `/dashboard/my-jobs/myJobs/${jobId}/view-jobs-data?page=${page}`
    );
  };

  const handleDownloadPdf = async () => {
    if (applicant.transcript && jobProfileDetails && jobInterviewDetails) {
      await generateDemoPdf(
        applicant.transcript,
        jobProfileDetails,
        jobInterviewDetails
      );
      toggleDropdown();
    } else {
      console.error("Selected transcript or applicant is undefined");
    }
  };

  const handleDownloadVideo = async () => {
    await downloadVideo(transcriptVideoLink, transcriptVideoType);
    toggleDropdown();
  };

  useEffect(() => {    
    if (id) {
      dispatch(fetchJobApplicantsResult(token, id));
    }

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    window.dispatchEvent(new CustomEvent("routeChanged"));
    return () => {
      window.dispatchEvent(new CustomEvent("routeChanged"));
    };
  }, [location.pathname]);

  return (
    <>
      <Navbar />
      <section className="result_screen_new_sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="result_screen_new_sec_inner">
                <div className="result_screen_back">
                  <DashboardHeader
                    title=""
                    backButtonLink={handleBackButtonClick}
                    backButtonText="Back"
                    className="back_btn_main"
                  />
                </div>
                <div className="tab_main_sec_new">
                  <Tabs
                    activeKey={activeTabKey}
                    onChange={handleTabChange}
                    items={getTabItems()}
                  />
                  {activeTabKey ===
                    `/view-jobs-data/results/Transcript/${id}?jobId=${jobId}&page=${page}` &&
                    applicant?.transcript?.length > 0 && (
                      <div ref={dropdownRef} className="btn_main">
                        <button
                          type="button"
                          className="transcript_download_new"
                          onClick={toggleDropdown}
                        >
                          Download <i className="fa-solid fa-download"></i>
                        </button>
                        {isOpen && (
                          <ul className="show position_absolute_sec">
                            <li>
                              <button
                                className="dropdown-item"
                                onClick={handleDownloadPdf}
                              >
                                Transcript
                              </button>
                            </li>
                            <li>
                              <button
                                className="dropdown-item"
                                onClick={handleDownloadVideo}
                              >
                                Video
                              </button>
                            </li>
                          </ul>
                        )}
                      </div>
                    )}
                </div>

                <Routes>
                  <Route path="Profile/:id" element={<Profile />} />
                  <Route path="Written/:id" element={<Written />} />
                  {transcript && (
                    <Route path="Transcript/:id" element={<Transcript />} />
                  )}
                  {assessment && (
                    <Route path="Assessment/:id" element={<Assesment />} />
                  )}
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Results;
