import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { brand_awareness, sound_waves1, sound_waves_new } from "../../../../assets/images/images";
import { random } from "lodash";

const SpeakerCheck = (props) => {
  const [progress, setProgress] = useState(0);
  const [audio, setAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasPlayedOnce, setHasPlayedOnce] = useState(false);

  const startSpeakerTest = async () => {
    if (!audio) {
      return;
    }
    
    try {
      if (!isPlaying) {
        setHasPlayedOnce(true);
      }
  
      if (audio.paused) {
        await audio.play();
      } else {
        audio.pause();
      }
  
      setIsPlaying(!isPlaying);
  
      // Automatically stop the audio after 5 seconds
      await new Promise((resolve) => {
        setTimeout(() => {
          audio.pause();
          setIsPlaying(false);
          resolve();
        }, 5000);
      });
      props.permissionResultRef.current.speaker = true;
      props.setPermissionResult(random(1, 100));
    } catch (error) {
      console.error("Error during audio playback:", error);
  
      // Handle the error case
      props.permissionResultRef.current.speaker = false;
      props.setPermissionResult(random(1, 100));
    }
  };
  
  const troubleShoot = () => {
    setHasPlayedOnce(false);
    setIsPlaying(false);
    setAudio(null);
  
    const audioObj = new Audio(
      `https://haire.s3.ap-south-1.amazonaws.com/development/test.wav`
    );
    audioObj.loop = true;
    setAudio(audioObj);
  };

  const retryAgain = () => {
    // Handle the error case
    props.permissionResultRef.current.speaker = false;
    props.setPermissionResult(random(1, 100));
    setHasPlayedOnce(false);
    setIsPlaying(false);
    setAudio(null);
  
    const audioObj = new Audio(
      `https://haire.s3.ap-south-1.amazonaws.com/development/test.wav`
    );
    audioObj.loop = true;
    setAudio(audioObj);
    startSpeakerTest();
  };
  
  useEffect(() => {
    const audioObj = new Audio(
      `https://haire.s3.ap-south-1.amazonaws.com/development/test.wav`
    );
    audioObj.loop = true;
    setAudio(audioObj);
  
    return () => {
      if (audioObj) {
        audioObj.pause();
        audioObj.currentTime = 0;
      }
    };
  }, []);
  
  useEffect(() => {
    let intervalId;
    if (isPlaying) {
      intervalId = setInterval(() => {
        setProgress((oldProgress) => {
          const newProgress =
            oldProgress +
            (Math.random() > 0.5 ? 1 : -1) * (5 + Math.random() * 10);
          return Math.min(100, Math.max(0, newProgress));
        });
      }, 200);
    } else {
      clearInterval(intervalId);
      setProgress(0);
    }
  
    return () => clearInterval(intervalId);
  }, [isPlaying]);
  
  return (
    <>
      <div className="modal_permission_sec_inner_body_right_single">
        <div className="modal_permission_sec_inner_body_right_single_sec">
          <div className="modal_permission_sec_inner_body_right_single_sec_head">
            <div className="head_single_left_sec">
              <img src={brand_awareness} alt="" />
              <h5>Speaker check</h5>
            </div>
            {isPlaying === true && (
              <div className="head_single_right_sec">
                <h3>Playing</h3>
              </div>
            )}
            {isPlaying===false && hasPlayedOnce===true && (
              <div className="head_single_right_sec">
                <h3>Not Playing</h3>
              </div>
            )}
          </div>
          <div className="audio_check_sec">
            {isPlaying === true ? (
              <div className="waves_sec" onClick={startSpeakerTest}></div>
            ) : (
              <img src={sound_waves1} alt="" className="btm-25" />
            )}
          </div>
        </div>
        <div className="right_test_btn">
          {isPlaying !== true && !hasPlayedOnce && (
          <button type="button" className="test_btn_check" onClick={startSpeakerTest}>Test</button>
          )}
          {isPlaying===true && hasPlayedOnce===true && !audio && (
            <button type="button" className="test_btn_check troubleshoot" onClick={troubleShoot}>Troubleshoot</button>
          )}
          {hasPlayedOnce === true && isPlaying===false && (
            <>
              <button type="button" className="test_btn_check" onClick={retryAgain}>Retry</button>
              <span className="tested_class">Tested <FontAwesomeIcon icon={faCheck} /></span>
            </>
            )}
        </div>
      </div>
    </>
  );
};

export default SpeakerCheck;
