import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AiAvatarInterviewQuestion = ({handleModalClick}) => {
    const [addData, setAddData] = useState(false);
    const addModal = () => {
        setAddData(true);
    }
    const CloseModal = () => {
        setAddData(false);
    }

  return (
    <>
    <div className='recriuter_step_2_inner_btm_single'>
            <div className='recriuter_step_2_inner_btm_single_top'>
                <div className='recriuter_step_2_inner_btm_single_top_left new_left'>
                    <div className='toggle_sec'>
                        <label class="switch">
                            <input type="checkbox" />
                            <span class="slider round"></span>
                        </label>
                    </div>
                    <h5>
                        Custom AI Avatar Interview Questions
                        <button onClick={handleModalClick}>What are Custom AI Interview Questions?</button>
                    </h5>
                </div>
                <div className='recriuter_step_2_inner_btm_single_top_right'>
                    <h6>Free</h6>
                </div>
            </div>
            <div className='recriuter_step_2_inner_btm_single_btm_inner'>
                <p>Custom questions enable you to make interviews more aligned with your hiring preferences. You can add tailored questions that the AI avatar will ask the candidate.</p>
                <button className='fa_plus_btn' onClick={addModal}><i class="fas fa-plus"></i> Add</button>
            </div>
            <div className='ques_add_tag'>
                <span><i class="fas fa-check-circle"></i>2 Questions Added</span>
            </div>
    </div>

    <Modal show={addData} onHide={CloseModal} className='custome_questions'>
        <div className='custome_questions_inner'>
            <div className='custome_questions_inner_head'>
                <h6>Add Custom AI Avatar Interview Questions </h6>
                <p>Custom questions allow you to personalize the interview process to fit your hiring needs. By adding these tailored questions, the AI avatar will ask candidates exactly what you want to know, ensuring the interview aligns with your preferences and goals.</p>
            </div>
            <div className='custome_questions_inner_body'>
                <div className='custome_questions_single'>
                    <div className='custome_questions_single_left'>
                        <h6>1. What are Python's data types, and how are they categorized? Provide examples of each type.</h6>
                    </div>
                    <div className='custome_questions_single_right'>
                        <ul>
                            <li>
                                <Link><i class="fas fa-edit"></i></Link>
                            </li>
                            <li>
                                <Link><i class="fas fa-trash-alt"></i></Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='custome_questions_single_textarea'>
                    <textarea placeholder='Question : Type your question here' />
                </div>
                <div className='add_ques_btn'>
                    <button><i class="fas fa-plus"></i>Add Question</button>
                </div>
            </div>
        </div>
        <div className='add_cancel_btn_sec_modal'>
            <button className='draft_btn_new'>Cancel</button>
            <button className='save_btn_new'>Add</button>
        </div>
    </Modal>

    </>
  )
}

export default AiAvatarInterviewQuestion
