import React, { useState, useEffect } from "react";
import Webcam from "react-webcam";

const FACING_MODE_USER = { facingMode: "user" }; // Front Camera

const CameraAccess = (props) => {
  const webcamRef = React.useRef(null);
  const [cameraError, setCameraError] = useState(true); // Default to error
  const [cameraReady, setCameraReady] = useState(false); // Tracks if the camera stream is active
  const [videoConstraints, setVideoConstraints] = useState(FACING_MODE_USER);
  const [showError, setShowError] = useState(false);
  const validateCamera = async () => {
    try {
      const videoElement = webcamRef.current?.video;

      if (!videoElement) {
        // If no video element, mark as error
        setCameraError(true);
        setCameraReady(false);
        setTimeout(() => {
          if(!props.permissionResultRef.current.camera){
            setShowError(true);
          }
        }, 20000);
        return;
      }

      // Check if the video element is playing
      const isVideoPlaying = !videoElement.paused && !videoElement.ended && videoElement.readyState === 4;

      if (isVideoPlaying) {
        setCameraError(false);
        setCameraReady(true);
        if(!props.permissionResultRef.current.camera){
          props.permissionResultRef.current.camera = true;
          console.log(props.permissionResultRef.current);
        }
      } else {
        console.error("Camera validation", "Video is not playing");
        setCameraError(true);
        setCameraReady(false);
        setTimeout(() => {
          if(!props.permissionResultRef.current.camera){
            setShowError(true);
          }
        }, 20000);
      }
    } catch (error) {
      console.error("Camera validation error:", error);
      setCameraError(true);
      setCameraReady(false);
      setTimeout(() => {
        if(!props.permissionResultRef.current.camera){
          setShowError(true);
        }
      }, 20000);
    }
  };

  useEffect(() => {
    // First check on mount
    validateCamera();

    // Listen for events to trigger camera validation
    const videoElement = webcamRef.current?.video;
    if (videoElement) {
      videoElement.addEventListener("playing", validateCamera); // Check when the video starts playing
      videoElement.addEventListener("pause", validateCamera); // Check when the video pauses
      videoElement.addEventListener("ended", validateCamera); // Check when the video ends

      // Cleanup event listeners on unmount
      return () => {
        videoElement.removeEventListener("playing", validateCamera);
        videoElement.removeEventListener("pause", validateCamera);
        videoElement.removeEventListener("ended", validateCamera);
        // Stop all media tracks on component unmount
        const stream = webcamRef.current?.stream;
        if (stream) {
          stream.getTracks().forEach((track) => track.stop());
        }
      };
    };  }, []);

  return (
    <>
      <div className="modal_permission_sec_inner_body_left">
      <div
    className="camera_start_sec"
    style={{
      position: "relative",
      overflow: "hidden",
      width: "100%", // Ensure parent div takes full width
      height: "100%", // Ensure parent div takes full height
    }}
  >
    <Webcam
      audio={false}
      ref={webcamRef}
      screenshotFormat="image/jpeg"
      videoConstraints={videoConstraints}
      onUserMediaError={() => setCameraError(true)} // Handle webcam errors
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)", // Center the webcam feed
        width: "auto", // Maintain the natural aspect ratio
        height: "100%", // Fill the height of the parent div
        objectFit: "cover", // Ensure it covers the parent container
      }}
    />
          {!cameraReady && !showError && (
            <p style={{ fontWeight: "bold", marginTop: "10px" }}>
              Camera is starting...
            </p>
          )}
          {showError && (
            <p style={{ color: "red", fontWeight: "bold", marginTop: "10px", margin: "15px" , textAlign: "center"}}>
              Camera is not running. Please ensure your camera is connected, powered on, and permissions are granted.
            </p>
          )}
          {/* {cameraReady && !cameraError && (
            <p style={{ color: "green", fontWeight: "bold", marginTop: "10px" }}>
              Camera is active and streaming.
            </p>
          )} */}
        </div>
      </div>
    </>
  );
};

export default CameraAccess;
