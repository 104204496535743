import { useState, useEffect, useCallback } from "react";

const useEnhanceResumeSocket = (url) => {
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [socketLoading, setSocketLoading] = useState(false);

  useEffect(() => {
    const webSocket = new WebSocket(url);

    webSocket.onopen = () => {
      console.log("WebSocket connected");
    };

    webSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.message) {
        setMessages((prevMessages) => [...prevMessages, data.message]);
        setSocketLoading(false);
      }
    };

    webSocket.onclose = (event) => {
      console.log("WebSocket disconnected.",
        "Code:", event.code,
        "Reason:", event.reason || "No reason provided.");
      setSocketLoading(false); // Reset loading on close
    };

    webSocket.onerror = (error) => {
      console.error("WebSocket error:", error);
      setSocketLoading(false); // Reset loading on error
    };

    setSocket(webSocket); // Update state with WebSocket instance

    // Cleanup function to close WebSocket on unmount
    return () => {
      if (webSocket.readyState === WebSocket.OPEN) {
        webSocket.close();
      }
    };
  }, [url]);

  const sendMessage = useCallback(
    (message) => {
      if (socket?.readyState === WebSocket.OPEN) {
        setSocketLoading(true);
        socket.send(JSON.stringify(message));
      }
    },
    [socket]
  );

  const clearMessages = useCallback(() => {
    setMessages([]);
  }, []);

  return { messages, sendMessage, clearMessages, socketLoading };
};

export default useEnhanceResumeSocket;
