import React from "react";

import { Navbar } from "../../../../../components/DashboardComponents/Navbar/Navbar";
import styles from "./writtenAssessment.module.css";
import "../InterviewModule.css";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import InterviewModuleNav from "../InterviewModuleNav";

const WrittenAssessmentInstruction = () => {
  const navigate = useNavigate();
  const { jobId, jobname, companyName, assignment_id } = useParams();
  const decodedJobTitle = decodeURIComponent(jobname);
  const decodedCompanyName = decodeURIComponent(companyName);
  const navData = {
    jobname: decodedJobTitle,
    CompanyName: decodedCompanyName,
  };
  const jobDetails = useSelector((state) => state?.job?.details);
  const timeLimit = jobDetails?.assignment?.time_to_complete;
  const onStartWrittenAssessment = () => {
    navigate(
      `/applicant-dashboard/related-jobs/${jobId}/written-assessment-started/${jobname}/${companyName}/${assignment_id}`
    );
  };
  return (
    <>
      <InterviewModuleNav data={navData} />
      <section className={styles.asses_main_sec}>
        <div className="container-fluid">
          <div className={styles.container_main}>
            <h4 className={styles.title}>
              {jobname} - {companyName}
            </h4>

            <div className={styles.section}>
              <h6 className={styles.sectionTitle}>Time Limit:</h6>
              <ul>
                <li>
                  Complete the assignment within the allocated time of{" "}
                  {timeLimit} mins. Keep track of the timer displayed on the
                  screen.
                </li>
              </ul>
            </div>

            <div className={styles.section}>
              <h6 className={styles.sectionTitle}>Answer Format:</h6>
              <ul>
                <li>
                  Provide the most appropriate option for the MCQ question.
                </li>
                <li>
                  Write your code in the provided editor. Ensure it meets the required specifications.
                </li>
              </ul>
            </div>

            <div className={styles.section}>
              <h6 className={styles.sectionTitle}>Submission:</h6>
              <ul>
                <li>
                  Click on the "Submit" button once you’ve completed the
                  assignment. Ensure all questions are answered before
                  submitting.
                </li>
              </ul>
            </div>

            <div className={styles.section}>
              <h6 className={styles.sectionTitle}>Eligibility:</h6>
              <ul>
                <li>
                  A score of 70% or above is required to move forward to the
                  interview round.
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button
              className="assesment_btn"
              onClick={onStartWrittenAssessment}
            >
              Start Written Assessment
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default WrittenAssessmentInstruction;
