import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { Cross } from "../../assets/images/images";
import "./commonComponant.css";

const ChipsInputComponent = ({
  value,
  placeholder,
  onChipsChange,
  onChipRemoved = () => {},
  id,
  formatCase = "none",
  maxChips,
  onMaxChipsReached = () => {},
}) => {
  const [inputValue, setInputValue] = useState("");
  const [chips, setChips] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const formatInputValue = (input, formatCase) => {
    switch (formatCase) {
      case "camelCase":
        return input
          .split(" ")
          .map((word) => {
            // If the whole word is uppercase, return it as is
            if (word === word.toUpperCase()) {
              return word;
            }
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          })
          .join(" ");
      case "sentenceCase":
        return input
          .split(". ")
          .map((sentence) => {
            const words = sentence.split(" ");
            return words
              .map((word) => {
                // If the whole word is uppercase, return it as is
                if (word === word.toUpperCase()) {
                  return word;
                }
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
              })
              .join(" ");
          })
          .join(". ");
      default:
        return input;
    }
  };

  useEffect(() => {
    if (value && value.length > 0) {
      const valueArray = value?.split(",").map((item) => item.trim());
      setChips(valueArray);
    }
  }, [value]);

  const handleInputChange = (e) => {
    const newValue = e.target.value.slice(0, 300);
    setInputValue(newValue);
    checkForDuplicate(newValue);
  };

  const checkForDuplicate = (newValue) => {
    const trimmedInput = newValue.trim().toLowerCase();
    const foundDuplicate = chips.some(
      (chip) => chip.toLowerCase() === trimmedInput
    );
    setErrorMessage(foundDuplicate ? "This word has already added" : "");
  };

  const handleAddChip = () => {
    let trimmedInput = inputValue.trim();

    trimmedInput = formatInputValue(trimmedInput, formatCase);

    if (maxChips !== undefined && chips.length >= maxChips) {
      onMaxChipsReached();
      return;
    }
    if (
      trimmedInput &&
      !chips.some((chip) => chip.toLowerCase() === trimmedInput.toLowerCase())
    ) {
      const newChips = [...chips, trimmedInput];
      setChips(newChips);
      onChipsChange(newChips);
      setInputValue("");
      setErrorMessage("");
    } else {
      setErrorMessage("This word has already added");
    }
  };

  const handleRemoveChip = (chipToRemove) => {
    const newChips = chips.filter((chip) => chip !== chipToRemove);
    setChips(newChips);
    onChipsChange(newChips);
    onChipRemoved(chipToRemove);
  };

  return (
    <div className="chips-input-component" id={id}>
      <div className="chips-input-container">
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={(e) => e.key === "Enter" && handleAddChip()}
          placeholder={placeholder}
          maxLength={75}
        />
        {inputValue.trim() && (
          <Button
            className="enterkey_btn"
            type="primary"
            style={{
              display:
                errorMessage === "This word has already added"
                  ? "none"
                  : "block",
            }}
            onClick={handleAddChip}
          >
            <i class="fa-solid fa-arrow-right"></i>
          </Button>
        )}
      </div>
      <div className="seventy-line">
        {inputValue.length >= 75 ? (
          <span style={{ color: "#ff4d4f" }}>
            Maximum character limit (75) reached
          </span>
        ) : (
          <span >{`${inputValue.length}/75`}</span>
        )}
      </div>
      {errorMessage && (
        <div
          className="error-message"
          style={{ color: "#ff4d4f", fontSize: "14px", textAlign: "left" }}
        >
          {errorMessage}
        </div>
      )}
      <div className="chips-container">
        {chips !== null && chips?.length > 0 ? (
          chips?.map((chip, index) => (
            <span key={index} className="chip">
              <span>{chip}</span>
              <button type="button" onClick={() => handleRemoveChip(chip)}>
                <img src={Cross} alt="cross_icon" />
              </button>
            </span>
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ChipsInputComponent;
