import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

const QuestionTypeDropdown = ({
  options,
  selectedOptions,
  setSelectedOptions,
}) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleCheckboxChange = (value) => {
    setSelectedOptions((prev) =>
      prev.includes(value)
        ? prev.filter((option) => option !== value)
        : [...prev, value]
    );
  };

  const handleRemoveOption = (value) => {
    setSelectedOptions((prev) => prev.filter((v) => v !== value));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="question-type-dropdown new_ques_type">
      <label>
        Question Type<pre>*</pre>
      </label>
      <div className="checkbox-dropdown" ref={dropdownRef}>
        <div className="box_sec_new" onClick={toggleDropdown}>
          {selectedOptions.length > 0
            ? `${selectedOptions.length} Option(s) Selected`
            : "Select Question Type"}
          <span className="arrow">
            {isOpen ? (
              <FontAwesomeIcon
                icon={faChevronUp}
                style={{ color: "#000000" }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faChevronDown}
                style={{ color: "#000000" }}
              />
            )}
          </span>
        </div>

        {isOpen && (
          <div className="dropdown-menu">
            {options.map((option) => (
              <label key={option.value} className="dropdown-option">
                <input
                  type="checkbox"
                  checked={selectedOptions.includes(option.value)}
                  onChange={() => handleCheckboxChange(option.value)}
                />
                {option.label}
              </label>
            ))}
          </div>
        )}
      </div>
        <div className="main_sugg_ques">
          {selectedOptions.length > 0 && (
          <div className="selected_options_box">
            <ul>
              {selectedOptions.map((value) => {
                const option = options.find((opt) => opt.value === value);
                return (
                  <li key={value}>
                    {option?.label}
                    <span
                      className="remove_icon"
                      onClick={() => handleRemoveOption(value)}
                    >
                      <FontAwesomeIcon
                        icon={faTimes}
                        style={{ color: "#000000" }}
                      />
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        </div>
      
    </div>
  );
};

export default QuestionTypeDropdown;
