import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { toast } from "react-toastify";
// @ts-ignore
import { questionPoints } from "../utils/constants";

// TypeScript interfaces
interface TestCase {
  input: string;
  expected_output: string;
}

export interface Question {
  question: string;
  question_type: string;
  correct_answer: string;
  inputs: string[];
  expected_output: string;
  test_cases: TestCase[];
  score?: number; // optional score field to hold the points
}

interface GenerateQuestionsRequest {
  job_title: string;
  job_description: string;
  question_types: string;
  num_questions: number;
  previous_questions: Question[];
}

interface GenerateQuestionsResponse {
    total_collected: number;
    batch_start: number;
    batch_end: number;
    batch_questions: Question[];
    questions: Question[];
    status: string;
}

interface QuestionsState {
  questions: Question[];
  isLoading: boolean;
  error: string | null;
  generateQuestions: (
    token: string,
    params: GenerateQuestionsRequest,
    userId: string, // needed to construct the WebSocket URL
    generateType: "all" | "single" | "batch",
    index?: number
  ) => Promise<void>;
}

// Create Zustand store using WebSocket connection
const assignmentStore = create<QuestionsState>()(
  devtools(
    (set, get) => ({
      questions: [],
      isLoading: false,
      error: null,
      setQuestions: (questions: Question[]) => set({ questions }),

      generateQuestions: async (
        token: string,
        params: GenerateQuestionsRequest,
        userId: string,
        generateType: "all" | "single" | "batch",
        index?: number
      ) => {
        // Set loading state
        if(generateType !== "single") {
          set({ isLoading: true, error: null });
        }

        // Construct the WebSocket URL using the userId
        const wsUrl = `${process.env.REACT_APP_WEBSOCKET_URL}/generate_questions/${userId}/`;
        const ws = new WebSocket(wsUrl);

        // When the connection opens, send your payload
        ws.onopen = () => {
          ws.send(JSON.stringify(params));
        };

        // Listen for incoming messages (each batch of questions)
        ws.onmessage = (event) => {
          try {
            const data: GenerateQuestionsResponse = JSON.parse(event.data);
            // if (!data || !data || !data?.batch_questions) {
            //   throw new Error("Invalid data received from WebSocket");
            // }

            if(data?.status === 'batch_completed') {
              // Close the WebSocket connection
              // set({ questions: [...get().questions, ...data?.questions] });
            const batchQuestions = data?.questions;

            // Add a score field based on the question type
            const questionsWithScore = batchQuestions.map((question: Question) => {
              return { ...question, score: questionPoints[question.question_type] || 0 };
            });


            if(generateType === "all") {
              // Update the questions state with the new questions
              set({ questions: questionsWithScore });
            } else if(generateType === "batch") {
              // Append new questions to the existing questions
              set({ questions: [...params.previous_questions, ...questionsWithScore] });
              
            } else if(generateType === "single" && index !== undefined) {
              // const updatedQuestions = [...params.previous_questions].map((question, i) => {
              //   if(i === index) {
              //     return questionsWithScore[0];
              //   }
              //   return question;
              // }
              // );
              const updatedQuestions = [...params.previous_questions];
              updatedQuestions[index] = questionsWithScore[0];
              set({ questions: updatedQuestions });
            }
            ws.close();
          }
          } catch (err: any) {
            set({ error: err.message || "Error parsing WebSocket message" });
            console.error("Error parsing WebSocket message:", err);
            ws.close();
          }
        };

        // Handle errors
        ws.onerror = (error) => {
          set({ error: "WebSocket encountered an error", isLoading: false });
          toast.error("WebSocket encountered an error. Please try again.");
          ws.close();
        };

        // When the WebSocket closes, update the loading state
        ws.onclose = () => {
          set({ isLoading: false });
        };
      },
    }),
    { name: "questions-store" }
  )
);

export default assignmentStore;
