import React, { useState, useRef, useEffect } from 'react';
import { CircleFlag } from 'react-circle-flags';

const CustomCurrencyDropdown = ({ 
  value, 
  onChange, 
  currencyMapping, 
  placeholder = "Select Currency" 
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(value ?? null);
  const dropdownRef = useRef(null);

  // Find country code from currency value
  const getCountryCodeFromCurrency = (currencyValue) => {
    return Object.keys(currencyMapping).find(
      (key) =>
        currencyMapping[key]?.currency === currencyValue ||
        currencyMapping[key]?.symbol === currencyValue
    );
  };

  // Initialize selected country based on initial value
  useEffect(() => {
    if (value) {
      const countryCode = getCountryCodeFromCurrency(value);
      setSelectedCountry(countryCode);
    }
  }, [value]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (countryCode) => {
    setSelectedCountry(countryCode);
    setIsOpen(false);
    
    if (onChange) {
      onChange(currencyMapping[countryCode]?.currency);
    }
  };

  // Get display text for the selected option
  const getSelectedText = () => {
    if (!selectedCountry) return placeholder;
    const currency = currencyMapping[selectedCountry];
    return `${currency?.name} ${currency?.symbol}`;
  };

  // Convert country code for CircleFlag (lowercase for EU)
  const getCountryCodeForFlag = (code) => {
    return code === 'EU' ? 'eu' : code.toLowerCase();
  };

  return (
    <div className="custom-currency-dropdown" ref={dropdownRef}>
      <div 
        className="dropdown-header new_curr_dp_sec"
        onClick={toggleDropdown}
      >
        <div className="flex items-center dis_flex_new_curr_dp_sec">
          {selectedCountry && (
            <div className="mr-2">
              <CircleFlag countryCode={getCountryCodeForFlag(selectedCountry)} height="24" />
            </div>
          )}
          <span>{getSelectedText()}</span>
        </div>
        <div className="dropdown-arrow">
          <svg 
            width="10" 
            height="6" 
            viewBox="0 0 10 6" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
          >
            <path d="M1 1L5 5L9 1" stroke="#6B7280" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>
      </div>
      
      {isOpen && (
        <div className="dropdown-options new_curr_dp_sec_option">
          {Object.keys(currencyMapping).map(countryCode => (
            <div 
              key={countryCode}
              className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleSelect(countryCode)}
            >
              <div className="mr-2">
                <CircleFlag countryCode={getCountryCodeForFlag(countryCode)} height="24" />
              </div>
              <span>{`${currencyMapping[countryCode]?.name} ${currencyMapping[countryCode]?.symbol}`}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomCurrencyDropdown;