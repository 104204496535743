import { Button, Modal } from "antd";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const IdleWarningModal = ({
    isVisible,
    onContinue,
    onEndInterview,
    warningCount,
  }) => {
    return (
      <Modal
        className="interview_modal_sm"
        centered
        open={isVisible}
        closable={false}
        footer={null}
        width={600}
      >
        <div className="inter_panel_ready_sec_inner">
          <CountdownCircleTimer
            key={warningCount} // Use warning count as key to restart the timer
            isPlaying
            duration={15}
            colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
            colorsTime={[10, 6, 3, 0]}
            size={100}
            onComplete={() => {
              if (warningCount === 3) {
                onEndInterview();
              } else {
                onContinue(); // Continue interview if not at final warning
              }
            }}
          >
            {({ remainingTime }) => remainingTime}
          </CountdownCircleTimer>
          <h6>
            {warningCount < 3
              ? "Waiting for Your Response"
              : "Interview Session Ended"}
          </h6>
          <p>
            {warningCount < 3
              ? "No response detected for 60 seconds. Please click 'Continue' to resume the interview. If no action is taken, the session will end in 15 seconds."
              : "The interview session has ended due to inactivity. Please click 'Okay' to confirm and proceed."}
          </p>
  
          <Button onClick={onContinue} type="primary">
            {warningCount < 3 ? "Continue" : "Okay, Understood"}
          </Button>
        </div>
      </Modal>
    );
  };

  export default IdleWarningModal;