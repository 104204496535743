import { marked } from "marked";
import React, { useState } from "react";
import {
  full,
  full1,
  full2,
  share_img,
  salary_img,
} from "../../assets/images/images";
import {
  calculateDaysAgo,
  formatDate,
  formatCurrencyRange,
} from "../../utils/utils";
import { Typography } from "@mui/material";

const JobCard = ({ jobData, onClick, activeTab, onShare }) => {
  const getFirstValue = (obj) => {
    return obj && Object.values(obj)[0];
  };
  const [buttonColor, setButtonColor] = useState("");
  function truncateText(text, maxLength) {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  }
  const applied_jobs_status = jobData.job_application_details?.status;
  const isButtonDisabled = applied_jobs_status === "Not-Fit";
  const jobRecommendedStatus = jobData?.recommended_status;

  const handleButtonClick = (event) => {
    // Stop the event from bubbling up to the parent div
    event.stopPropagation();
    onClick();
  };

  const handleShareClick = (event) => {
    event.stopPropagation();
    if (onShare) onShare(event);
  };

  return (
    <div className="posted_jobs_main_sec_single" onClick={onClick}>
      <div className="posted_jobs_main_sec_single_top">
        <div className="sing_left_top">
          <img
            className="job_card_compney_image"
            src={jobData?.company_details?.company_profile_pic}
            alt="Meta Image"
          />
        </div>
        <div className="sing_right_top" onClick={handleShareClick}>
          <img src={share_img} alt="Share Image" />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h6 className="des_text_sec">{jobData?.job_details?.title}</h6>
        {jobRecommendedStatus && (
          <Typography
            width={90}
            height={30}
            fontSize={10}
            fontWeight={700}
            color={"#3D4852"}
            p={1}
            borderRadius={"8px"}
            bgcolor={
              jobRecommendedStatus?.recommendation_reason === "Recommended"
                ? "#EDF5FE"
                : "#F4F2F0"
            }
          >
            {jobRecommendedStatus?.recommendation_reason}
          </Typography>
        )}
      </div>

      <h2 className="comp_name_text">
        {jobData?.company_details?.company_name}
      </h2>
      {/* <p className="des_text_sec_sub ">
        {jobData?.company_details?.company_overview}
      </p> */}
      <div
        className="des_text_sec_sub"
        dangerouslySetInnerHTML={{
          __html: marked(jobData?.company_details?.company_overview || ""),
        }}
      ></div>
      <ul className="cont_list_sec">
        <li>
          <span>
            <img src={full} alt="Full Time" />
            {getFirstValue(jobData?.job_details?.employment_type)}
          </span>
        </li>
        <li>
          <span>
            <img src={full1} alt="Location" />
            {truncateText(jobData?.job_details?.location, 15)}
          </span>
        </li>
        {jobData?.job_details?.disclose_salary === false && (
          <li>
            <span>
              {/* <div className="currency">{jobData?.job_details?.currency}</div> */}
              <img src={salary_img} alt="Salary" />
              {formatCurrencyRange(
                jobData?.job_details?.salary_min,
                jobData?.job_details?.salary_max,
                jobData?.job_details?.currency
              )}{" "}
              Per Year
            </span>
          </li>
        )}
        <li>
          <span>
            <img src={full2} alt="Experience" />
            {getFirstValue(jobData?.job_details?.experience)}
            {getFirstValue(jobData?.job_details?.experience) === "0-1"
              ? " Yr Exp"
              : " Yrs Exp"}
          </span>
        </li>
      </ul>
      <div className="view_btn_inner">
        <button
          onClick={activeTab === "appliedJobs" ? null : handleButtonClick}
          disabled={isButtonDisabled}
          className={isButtonDisabled ? "under-review-disabled-btn" : ""}
        >
          {activeTab === "appliedJobs"
            ? applied_jobs_status === "Fit-For-Job"
              ? "Start Interview"
              : applied_jobs_status
            : "View"}
        </button>
        <span>
          {calculateDaysAgo(formatDate(jobData?.job_details?.published_date))}
        </span>
      </div>
    </div>
  );
};

export default JobCard;
