import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QuizList, Quiz, QuizState } from "../types/assignmentTypes";

const initialState: QuizState = {
    loading: false,
    quizzes: [],
    error: null,
};

const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    setQuizzes(state, action: PayloadAction<Quiz[]>) {
      state.quizzes = action.payload; // Replace the state with new quiz data
      return state;
    },
    updateQuiz(state, action: PayloadAction<Quiz>) {
      const index = state.quizzes.findIndex((quiz) => quiz.id === action.payload.id);
      if (index !== -1) {
        state.quizzes[index] = action.payload; // Update existing quiz
      }
    },
    setQuizLoading: (
        state: QuizState,
        action: PayloadAction<boolean>
    ) => {
        state.loading = action.payload;
    },
    setQuizError: (
        state: QuizState,
        action: PayloadAction<string>
    ) => {
        state.error = action.payload;
    },
  },
});

// Export action creators
export const { setQuizzes, updateQuiz, setQuizLoading, setQuizError } = quizSlice.actions;

// Export the reducer
export default quizSlice.reducer;
