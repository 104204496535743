import {
  DownOutlined,
  UpOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Tag, Collapse, Col, Tooltip } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { useSelector } from "react-redux";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-markers";
import { ThreeCircles } from "react-loader-spinner";
import "videojs-markers/dist/videojs.markers.css";
import { Search_grey } from "../../../../assets/images/images";
import {
  convertTimeToSeconds,
  formatDurationTime,
  getVideoType,
  parseTimeToSeconds,
} from "../../../../utils/utils";
import { detectBrowser } from "../../../../zustand/screenRecordStore";
import "./results.css";

const CustomExpandIcon = ({ isActive }) => {
  return isActive ? <UpOutlined /> : <DownOutlined />;
};
const Transcript = () => {
  const [activeKey, setActiveKey] = useState(null); // State to track active panel
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTranscript, setFilteredTranscript] = useState([]);
  const [highlightIndex, setHighlightIndex] = useState(0);
  const [currentTranscriptIndex, setCurrentTranscriptIndex] = useState(-1);
  const [videoReady, setVideoReady] = useState(false);
  const [preloadTimeout, setPreloadTimeout] = useState(null);
  const playerRef = useRef(null);
  const videoRef = useRef(null);
  const highlightRefs = useRef([]);
  const browser = detectBrowser();
  const applicant = useSelector((state) => state?.job?.applicationResult);
  const transcriptVideoLink = applicant?.interview_details?.video_link;
  const transcriptVideoType = getVideoType(transcriptVideoLink);

  useEffect(() => {
    if (applicant && applicant.transcript) {
      setFilteredTranscript([...applicant.transcript]);
    }
    setCurrentTranscriptIndex(0);
  }, [applicant?.transcript]);

  const scrollToHighlight = (index) => {
    const highlightEl = highlightRefs.current[index];
    if (highlightEl) {
      const { top, bottom } = highlightEl.getBoundingClientRect();
      const isInView = top >= 0 && bottom <= window.innerHeight;
      if (!isInView) {
        highlightEl.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  };

  // Optimize video preloading for VideoJS
  const setupVideoPreloading = (player) => {
    if (!player) return;

    // Set preload attribute to 'auto' to start loading immediately
    player.preload("auto");

    // Create a buffer for the first few seconds
    const preloadFirstSegment = () => {
      if (player.readyState() < 3) {
        // HAVE_FUTURE_DATA
        // Load the first segment by seeking to it
        player.currentTime(0);
        // Then pause to prevent auto-playback
        player.pause();

        // Start loading a bit more video in the background
        const preloadMoreSegments = () => {
          // Seek to a point further in the video to preload more content
          const initialPreloadTime = 10; // Preload first 10 seconds
          player.currentTime(initialPreloadTime);
          player.pause();
          // Return to the beginning so it's ready to play
          setTimeout(() => {
            player.currentTime(0);
            setVideoReady(true);
          }, 1000);
        };

        // Set a timeout to preload more segments
        setPreloadTimeout(setTimeout(preloadMoreSegments, 1000));
      } else {
        setVideoReady(true);
      }
    };

    // Start preloading once metadata is loaded
    player.on("loadedmetadata", preloadFirstSegment);
  };

  useEffect(() => {
    if (applicant?.transcript && videoRef.current) {
      // Initialize video player
      playerRef.current = videojs(videoRef.current, {
        controls: true,
        width: 800,
        height: 450,
        playbackRates: [0.5, 1, 1.5, 2],
        fluid: true,
        controlBar: {
          volumePanel: { inline: false },
          pictureInPictureToggle: true,
          fullscreenToggle: true,
        },
        // Set preload attribute to 'auto' to start loading data immediately
        preload: "auto",
      });

      // Set up preloading for the video
      setupVideoPreloading(playerRef.current);

      // Add markers to the video
      playerRef.current.markers({
        markerStyle: {
          width: "2px",
          backgroundColor: "red",
        },
        markers: applicant.transcript.map((item) => ({
          time: convertTimeToSeconds(item.question_asked_at),
          text: item.ai_question,
        })),
        onMarkerClick: (marker) => playerRef.current.currentTime(marker.time),
      });

      // Time update handler for precise transcript synchronization
      const timeUpdateHandler = () => {
        if (!playerRef.current) return;

        const currentTime = playerRef.current.currentTime();
        const currentIndex = applicant.transcript.findIndex((item, index) => {
          const currentTimeInSeconds = convertTimeToSeconds(
            item.question_asked_at
          );
          const nextItem = applicant.transcript[index + 1];
          const nextTimeInSeconds = nextItem
            ? convertTimeToSeconds(nextItem.question_asked_at)
            : Infinity;

          return (
            currentTime >= currentTimeInSeconds &&
            currentTime < nextTimeInSeconds
          );
        });

        if (currentIndex !== -1 && currentIndex !== currentTranscriptIndex) {
          setCurrentTranscriptIndex(currentIndex);
        }
      };

      // Add time update event listener
      playerRef.current.on("timeupdate", timeUpdateHandler);

      // Add listener for route changes
      const handleRouteChange = () => {
        if (playerRef.current) {
          playerRef.current.pause();
        }
      };

      window.addEventListener("routeChanged", handleRouteChange);

      // Cleanup: Stop the video and dispose of the player when component unmounts
      return () => {
        window.removeEventListener("routeChanged", handleRouteChange);
        if (preloadTimeout) {
          clearTimeout(preloadTimeout);
        }
        if (playerRef.current) {
          playerRef.current.pause();
          playerRef.current.dispose();
          playerRef.current = null;
        }
      };
    }
  }, [applicant?.transcript]);

  // Calculate total matches for search term
  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
    setHighlightIndex(0);
    if (applicant?.transcript) {
      const filtered = applicant.transcript.filter(
        (item) =>
          item?.candidate_answer
            .toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          item?.ai_question.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredTranscript(filtered);
    }
  };

  const handleTranscriptClick = (question_asked_at) => {
    const timeInSeconds = convertTimeToSeconds(question_asked_at);
    if (playerRef.current) {
      playerRef.current.currentTime(timeInSeconds + 10);
      playerRef.current.play();
    } else {
      console.error("Video player is not initialized properly.");
    }
  };

  const totalMatches = useMemo(() => {
    if (!searchTerm) return 0;

    let count = 0;
    filteredTranscript.forEach((item) => {
      const questionMatches = (
        item.ai_question.match(new RegExp(searchTerm, "gi")) || []
      ).length;
      const answerMatches = (
        item.candidate_answer.match(new RegExp(searchTerm, "gi")) || []
      ).length;
      const interpretationMatches = (
        item.candidate_answer.match(new RegExp(searchTerm, "gi")) || []
      ).length;
      count += questionMatches + answerMatches + interpretationMatches;
    });

    return count;
  }, [searchTerm, filteredTranscript]);

  // Simplified renderVideoComponent to use VideoJS only
  const renderVideoComponent = () => {
    return (
      <div data-vjs-player>
        <video
          ref={videoRef}
          src={transcriptVideoLink}
          className="video-js vjs-default-skin"
          controls
          preload="auto"
        ></video>
      </div>
    );
  };

  const handlePanelChange = async (key, question_asked_at) => {
    await handleTranscriptClick(question_asked_at);
    setActiveKey((prevKey) => (prevKey === key ? null : key));
  };

  return (
    <div className="Transcript_main Transcript_main_wrapper">
      <Col span={16}>
        <div className="video-player-container">
          <div className="video-player-section">
            {renderVideoComponent()}
            {!videoReady && (
              <div className="video-preload-indicator">
                <ThreeCircles color="#1865c1" height={40} width={40} />
                <p>Buffering video...</p>
              </div>
            )}
          </div>
        </div>
      </Col>
      <Col span={7}>
        {applicant?.transcript?.length > 0 && (
          <section className="Transcript_main_action1">
            <div className="Transcript_main_search_section">
              <div className="Transcript_main_title">
                <p>Interview Transcript</p>
              </div>
              <div className="Search_jobs">
                <img src={Search_grey} alt="search_job" />
                <input
                  type="search"
                  className="search_input_transcript"
                  placeholder="Search transcript..."
                  value={searchTerm}
                  onChange={handleSearch}
                />
                {filteredTranscript.length > 0 ? (
                  totalMatches > 0 ? (
                    <div className="word-count">
                      {`Found : ${totalMatches} results`}
                    </div>
                  ) : null
                ) : (
                  <div className="word-count">{`No Results Found`}</div>
                )}
              </div>
            </div>
            <div>
              <div className="Transcript_main_action min-vd">
                {filteredTranscript.length > 0 &&
                  filteredTranscript.map((transcript, index) => (
                    <Collapse
                      accordion
                      activeKey={activeKey}
                      ref={(el) => (highlightRefs.current[index] = el)}
                      className={`collaps_comp ${
                        currentTranscriptIndex === index
                          ? "highlighted-section"
                          : ""
                      }`}
                      onChange={() =>
                        handlePanelChange(
                          index.toString(),
                          transcript.question_asked_at
                        )
                      }
                      expandIconPosition="end"
                      expandIcon={({ isActive }) => (
                        <CustomExpandIcon isActive={isActive} />
                      )}
                      items={[
                        {
                          key: index,
                          label: (
                            <>
                              <p className="trans_question_main">
                                <strong className="ques_sec">
                                  Question {index + 1} : Hairo AI
                                </strong>{" "}
                                {(() => {
                                  // REPLACE THE ZERO WITH THE VARIABLE TO TOGGLE THE WARNING TAG
                                  if (0 > 15)
                                    return (
                                      <Tag className="custom_que_tag">
                                        Custom Question
                                      </Tag>
                                    );
                                })()}
                                <br />
                                <Highlighter
                                  highlightClassName="highlighted-text"
                                  searchWords={[searchTerm]}
                                  autoEscape={true}
                                  textToHighlight={transcript?.ai_question}
                                />
                              </p>
                              {(() => {
                                const delayInSeconds = parseTimeToSeconds(
                                  transcript?.time_to_start_answer
                                );
                                if (delayInSeconds > 60) {
                                  return (
                                    <>
                                      <Tag
                                        color="#941B001A"
                                        style={{
                                          color: "#941B00",
                                          fontSize: "9px",
                                          marginLeft: "0px",
                                          marginBottom: "0px",
                                          marginTop: "8px",
                                          marginRight: "4px",
                                          fontWeight: "700",
                                        }}
                                      >
                                        Response Delayed by 00:
                                        {delayInSeconds} sec
                                      </Tag>
                                      <Tooltip title="The candidate took longer than expected to respond to this question, indicating a possible delay in formulating their answer.">
                                        <InfoCircleOutlined
                                          style={{
                                            color: "rgba(33, 37, 41, 1)",
                                          }}
                                        />
                                      </Tooltip>
                                    </>
                                  );
                                }
                                return null;
                              })()}
                            </>
                          ),
                          children: (
                            <div>
                              <hr style={{ marginTop: "10px" }} />
                              <p className="trans_answer">
                                <strong className="strong_answer">
                                  <div className="candidate_ans_header">
                                    <div className="hearder_first">
                                      {applicant?.user_full_name}{" "}
                                      <div className="span_sec_text">
                                        {(() => {
                                          const delayInSeconds =
                                            parseTimeToSeconds(
                                              transcript?.time_to_start_answer
                                            );
                                          if (delayInSeconds > 20) {
                                            return (
                                              <Tooltip title="The candidate took longer than expected to respond to this question, indicating a possible delay in formulating their answer.">
                                                <Tag
                                                  color="#941B001A"
                                                  style={{
                                                    color: "#941B00",
                                                    fontSize: "9px",
                                                    marginLeft: "0px",
                                                    marginBottom: "0px",
                                                    marginTop: "8px",
                                                    marginRight: "4px",
                                                    fontWeight: "700",
                                                  }}
                                                >
                                                  Response Delayed by 00:
                                                  {delayInSeconds} sec
                                                </Tag>

                                                <InfoCircleOutlined
                                                  style={{
                                                    color:
                                                      "rgba(33, 37, 41, 1)",
                                                  }}
                                                />
                                              </Tooltip>
                                            );
                                          }
                                          return null;
                                        })()}
                                      </div>
                                    </div>
                                    {transcript?.answer_duration && (
                                      <>
                                        {formatDurationTime(
                                          transcript?.answer_duration
                                        )}
                                      </>
                                    )}
                                  </div>
                                </strong>{" "}
                                <Highlighter
                                  highlightClassName="highlighted-text"
                                  searchWords={[searchTerm]}
                                  autoEscape={true}
                                  textToHighlight={transcript?.candidate_answer}
                                />
                              </p>
                              <hr />
                              <p className="trans_interpretation">
                                <strong>
                                  AI interpretation :
                                  <br />
                                </strong>{" "}
                                <Highlighter
                                  highlightClassName="highlighted-text"
                                  searchWords={[searchTerm]}
                                  autoEscape={true}
                                  textToHighlight={
                                    transcript?.ai_interpretation
                                  }
                                />
                              </p>
                            </div>
                          ),
                        },
                      ]}
                    />
                  ))}
                {applicant?.interview_details?.tab_switch_count >= 3 && (
                  <div className="terminated_section">
                    <h4 className="terminated">Interview Terminated</h4>
                    <p className="terminated_p">
                      The candidate's interview was terminated due to frequently
                      being distracted by switching tabs, even after several
                      reminders.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </section>
        )}
      </Col>
    </div>
  );
};

export default Transcript;
