import React from 'react'
import "../../Home/home.css";
import "../../Home/home_responsive.css";
import "../../Home/New_Home.css";
import { Helmet } from 'react-helmet';
import { haire_gif_new,face,insta,linkedin_ic,yout,xTwitter} from "../../../assets/images/images";

const NewFooter = () => {
  return (
    <>
        <footer className='ftr_new_land'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12 col-md-12'>
                <div className='ftr_new_land_inner'>
                  <div className='ftr_new_land_inner_left'>
                    <div className='vide_sec'>
                      <img src={haire_gif_new} alt="" />
                    </div>
                    <p> © 2025 haire.ai. All rights reserved</p>
                  </div>
                  <div className='ftr_new_land_inner_midlle'>
                    <p>Powered by <a href='https://arcitech.ai/' target='_blank'>Arcitech.ai</a></p>
                  </div>
                  <div className='ftr_new_land_inner_right'>
                  <div className="social-icons-new">
                      <a href="https://www.facebook.com/profile.php?id=61556640899265">
                        <img src={face} alt="footer logo" />
                      </a>
                      <a href="https://www.instagram.com/haire.ai?igsh=MnNmZnUxbTc0aTk0">
                        <img src={insta} alt="footer logo" />
                      </a>
                      <a href="https://www.linkedin.com/company/hairedotai">
                        <img src={linkedin_ic} alt="footer logo" />
                      </a>
                      <a href="https://www.youtube.com/@HaireDotAI">
                        <img src={yout} alt="footer logo" />
                      </a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </footer>
    </>
  )
}

export default NewFooter
