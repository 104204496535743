import React from 'react'

const AiAvatarInterviewDuration = ({handleModalClick}) => {
    const qcInt = [
        {
            id:1,
            mainText : "for Quick Interview",
            radioText : "Up to 15 Mins",
            creditText : "1 Credit",
        },
        {
            id:2,
            mainText : "for Balanced Interview",
            radioText : "Up to 30 Mins",
            creditText : "2 Credit",
        },
        {
            id:3,
            mainText : "for In-depth Interview",
            radioText : "Up to 45 Mins",
            creditText : "3 Credit",
        },
    ];

  return (
    <>
        <div className='recriuter_step_2_inner_btm_single'>
            <div className='recriuter_step_2_inner_btm_single_top'>
                <div className='recriuter_step_2_inner_btm_single_top_left'>
                    <h5>
                        AI Avatar Interview Duration<pre>*</pre>
                        <button onClick={handleModalClick}>What is an AI Avatar Interview?</button>
                    </h5>
                </div>
                <div className='recriuter_step_2_inner_btm_single_top_right'>
                    <h6>Recommended : 30-45 Minutes</h6>
                </div>
            </div>
            <div className='recriuter_step_2_inner_btm_single_btm'>
                {qcInt.map((item) => {
                    return(
                        <div className='recriuter_step_2_inner_btm_single_btm_single' id={item.id}>
                            <h6>{item.mainText}</h6>
                            <div className='qc_body'>
                                <div className='qc_body_radio'>
                                    <label class="container">{item.radioText}
                                        <input type="radio" name="radio" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div className='qc_body_text'>
                                    <h6>{item.creditText}</h6>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    </>
  )
}

export default AiAvatarInterviewDuration
