import React from "react";
import { Typography } from "@mui/material";
import { questionPoints } from "../../../../../utils/constants";
import { capitalize } from "../../../../../utils/utils";

const ScoreDisplay = ({ manualQuestions }) => {
  if (!manualQuestions?.length) return null;

  const types = new Set(manualQuestions.map((q) => q?.question_type));

  return (
    <Typography fontSize={14} fontWeight={500}>
      {Array.from(types).map((type, index, arr) => (
        <span key={type}>
          <span>
            {questionPoints[type]}{" "}
            {questionPoints[type] > 1 ? "points" : "point"}
          </span>{" "}
          will be obtained for each correct {capitalize(type)} answer
          {index < arr.length - 1 ? "." : ""}
        </span>
      ))}
    </Typography>
  );
};

export default ScoreDisplay;