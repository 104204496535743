const AssessmentItem = ({ title, rating, description, companyCreated }) => (
  <li className="trans_question">
    <span>{title}: </span>
    <span className="rating_section">Rating : {rating}/10</span>
    <br />
    {description}
  </li>
);

export default AssessmentItem;
