import { create } from "zustand";
import { devtools } from "zustand/middleware";
// @ts-ignore
import instance from "../api/instance";

interface RecommendedInterviewsRequest {
  interview_time: number;
  open_position: number;
  job_title: string;
  job_description: string;
}

interface RecommendedInterviewsResponse {
  success: boolean;
  recommended_interview_count: number;
}

interface RecommendedInterviewsState {
  recommendedCount: number | null;
  isLoading: boolean;
  error: string | null;
  generateRecommendedCount: (
    params: RecommendedInterviewsRequest,
    token: string
  ) => Promise<void>;
}

const recommendedInterviewsStore = create<RecommendedInterviewsState>()(
  devtools(
    (set) => ({
      recommendedCount: null,
      isLoading: false,
      error: null,

      generateRecommendedCount: async (
        params: RecommendedInterviewsRequest,
        token: string
      ) => {
        try {
          set({ isLoading: true, error: null });

          const response = await instance.post<RecommendedInterviewsResponse>(
            "/assignment/generate-recommended-interviews/",
            params,
            {
              headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (response.data.success) {
            set({
              recommendedCount: response.data.recommended_interview_count,
              isLoading: false,
            });
          } else {
            throw new Error("Failed to generate recommended interview count");
          }
        } catch (err: any) {
          set({
            error:
              err.message ||
              "An error occurred while generating recommendations",
            isLoading: false,
          });
        }
      },
    }),
    { name: "recommended-interviews-store" }
  )
);

export default recommendedInterviewsStore;
