import { haire_gif_new } from "../../assets/images/images";
import { Modal } from "antd";
import "./ReusableModal.css";
import loginCodeStore from "../../zustand/loginCodeStore";
import { red } from "@mui/material/colors";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";

const ReusableModal = ({
  children,
  modalOpens,
  loading,
  isHeaderVisible = true,
}) => {
  const location = useLocation();
  const { modalOpen, initialCloseModal } = loginCodeStore();
  const DefaultFooter = (
    <p className="login_desc">
      {
        "By Registering with Haire.ai, you agree to our Terms of Use & Privacy Policy."
      }
    </p>
  );

  const DefaulHeader = (
    <img
      src={haire_gif_new}
      className="haire-logo"
      alt="Logo"
      onClick={() => {}}
    />
  );

  // Close modal on route change
  useEffect(() => {
    initialCloseModal();
  }, [location, initialCloseModal]);

  return (
    <Modal
      open={modalOpens}
      footer={isHeaderVisible ? DefaultFooter : null}
      title={isHeaderVisible ? DefaulHeader : null}
      className="verficiation_mod"
      id="Verficiation_mod"
      closeIcon={null}
      styles={{
        body: { minHeight: "235px", padding: modalOpen ? "50px" : "0px" },
        height: loading ? "468px" : "auto",
        overflow: "auto",
      }}
    >
      <div className="modal-close" onClick={initialCloseModal}>
        <CloseOutlined />
      </div>
      <div className="Container">{children}</div>
    </Modal>
  );
};

export default ReusableModal;
