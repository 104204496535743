import { Button, Card, Image, Modal, Progress, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { websocketUrl } from "../../api/instance";
import { clock } from "../../assets/images/images";
import useTryInterviewWebsocket from "./useTryInterviewWebsocket";
import "../../pages/Dashboard/ApplicantDashboard/InterviewModule/InterviewModule.css";
import { Bars } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import useAudioRecorder from "../../hooks/useAudioRecorder";
import { useWindowFocus } from "../../hooks/useWindowFocus";
import {
  trackTryHaireIdleTime,
  trackTryHaireTabSwitch,
} from "../../redux/actions/jobSeekerActions";
import ThreeDotLoader from "../../components/Loaders/ThreeDotLoader/ThreeDotLoader";
import WarningModal from "../Dashboard/ApplicantDashboard/InterviewModule/WindowFocus";
import HairoListening from "../../assets/gifs/HairoHeadNode_1.gif";
import HairoLipSync from "../../assets/gifs/HairoMute_1.gif";
import { ReactComponent as MicListening } from "../../assets/images/mike_listening.svg";
import { ReactComponent as DisableMicIcon } from "../../assets/images/disable-mic-icon.svg";
import "./TryHaire.css";
import TryHaireInterviewModuleNav from "./TryHaireInterviewNav";
import IdleWarningModal from "../Dashboard/ApplicantDashboard/InterviewModule/popups/IdleWarningPopup";
import CustomPopup from "../Dashboard/ApplicantDashboard/InterviewModule/popups/CustomPopup";
import { Typography } from "@mui/material";
import { Analyse } from "../Dashboard/ApplicantDashboard/InterviewModule/Analyse";

const TryInterview = ({ interviewStatusDetails }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location?.state?.data;
  const { interviewId, jobProfile, company } = useParams();
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.token);
  const url = `${websocketUrl}/interview_demo_process/${interviewId}/`;
  const [loadingResults, setLoadingResults] = useState(false);
  const [blurBackground, setBlurBackground] = useState(false);
  const onResultsReceived = () => {
    setLoadingResults(false);
    greetingHasBeenSent.current = false;
  };
  const [isEndModalVisible, setIsEndModalVisible] = useState(false);
  const [isSkipModalVisible, setIsSkipModalVisible] = useState(false);
  const initialCountDownDone = useRef(false);
  const [elapsedTime, setElapsedTime] = useState(0); // State for the timer value
  const timerRef = useRef(null);

  const decodedJobTitle = decodeURIComponent(jobProfile);
  const decodedCompanyName = decodeURIComponent(company);

  const navData = {
    jobProfile: decodedJobTitle,
    company: decodedCompanyName,
  };

  // Ref to keep track of the timeout to end the interview after inactivity
  const endInterviewTimeoutRef = useRef(null);

  // AUDIO
  const {
    combinedMessage,
    socketLoading,
    audioPath,
    setAudioPath,
    sendData,
    interviewResult,
    autoEnd,
    interviewPercentage,
    greetingHasBeenSent,
    setSocketLoading,
  } = useTryInterviewWebsocket(
    url,
    onResultsReceived,
    interviewStatusDetails,
    initialCountDownDone
  );
  const {
    startRecording,
    stopRecording,
    isRecording,
    isSpeaking,
    audioBlob,
    mediaRecorder,
  } = useAudioRecorder();
  const [hasSentData, setHasSentData] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [isAudioButtonDisabled, setIsAudioButtonDisabled] = useState(true);

  const [messageText, setMessageText] = useState("Analyzing your answer");
  // const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  //TIMER
  const [timer, setTimer] = useState(180); // Timer set for 3 minutes
  const [timerActive, setTimerActive] = useState(false);

  // TAB SWITCH
  const { isWindowFocused, setIsWindowFocused } = useWindowFocus();
  const [tabSwitchWarningVisible, setTabSwitchWarningVisible] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [tabSwitchCount, setTabSwitchCount] = useState(0);

  const [interviewUnderReview, setInterviewUnderReview] = useState(false);
  const [isEndInterviewClicked, setIsEndInterviewClicked] = useState(false);
  // Tabswitch code start
  useEffect(() => {
    if (!interviewUnderReview && showPopUp) {
      if (!isWindowFocused) {
        const newCount = tabSwitchCount + 1;
        setTabSwitchCount(newCount);

        if (newCount >= 1 && newCount < 4) {
          dispatch(trackTryHaireTabSwitch(interviewId, token));
          setTabSwitchWarningVisible(true);
        } else if (newCount >= 3) {
          setTabSwitchWarningVisible(false);
          handleEndInterview();
          // setInterviewUnderReview(true);
        }
      }
    }
  }, [isWindowFocused, interviewUnderReview]);
  // Tabswitch code end

  const [idleTime, setIdleTime] = useState(0);
  const [isIdleModalVisible, setIsIdleModalVisible] = useState(false);
  const [idleWarningCount, setIdleWarningCount] = useState(0);
  const idleTimerRef = useRef(null);
  const modalTimerRef = useRef(null);

  useEffect(() => {
    if (
      !isRecording &&
      combinedMessage.current &&
      combinedMessage.current !== "" &&
      combinedMessage.current !== "undefined"
    ) {
      setMessageText("Analyzing your answer");

      resetIdleTimer();

      // setIdleWarningCount(0);
    }
  }, [isRecording, combinedMessage.current]);

  // Stop idle time tracking when the user starts answering
  useEffect(() => {
    if (isRecording) {
      if (idleTimerRef.current) clearInterval(idleTimerRef.current); // Stop idle timer
      if (modalTimerRef.current) clearTimeout(modalTimerRef.current);
    }
  }, [isRecording]);

  const resetIdleTimer = () => {
    setIdleTime(0);
    if (idleTimerRef.current) clearInterval(idleTimerRef.current);
    idleTimerRef.current = setInterval(() => {
      setIdleTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const handleUserActivity = () => {
    if (!isIdleModalVisible && !isRecording) {
      resetIdleTimer();
    }
  };

  // Add event listeners for detecting user activity
  useEffect(() => {
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      if (idleTimerRef.current) clearInterval(idleTimerRef.current);
      if (modalTimerRef.current) clearTimeout(modalTimerRef.current);
    };
  }, [isIdleModalVisible]);

  useEffect(() => {
    if (idleTime >= 60 && idleWarningCount < 3 && !isRecording) {
      setIsIdleModalVisible(true);
      setIdleWarningCount((prevCount) => prevCount + 1);
      clearInterval(idleTimerRef.current);

      modalTimerRef.current = setTimeout(() => {
        if (isIdleModalVisible) {
          setIsIdleModalVisible(false);
          if (idleWarningCount === 3) {
            handleEndInterview();
          } else {
            resetIdleTimer();
          }
        }
      }, 15000);
    }
  }, [idleTime]);

  const handleContinueInterview = () => {
    setIsIdleModalVisible(false);
    if (modalTimerRef.current) clearTimeout(modalTimerRef.current);
    if (idleWarningCount === 3) {
      handleEndInterview();
    } else {
      resetIdleTimer();
    }
  };
  const handleCancelWarning = () => {
    setTabSwitchWarningVisible(false);
  };

  useEffect(() => {
    if (isAudioPlaying || socketLoading) {
      setIsAudioButtonDisabled(false);
    }
  }, [isAudioPlaying, socketLoading]);

  useEffect(() => {
    // Send data when the listening has stopped and there's transcript available
    if (!isRecording && audioBlob && !hasSentData) {
      sendData({
        data: {
          interview_stage: "processing",
          ai_question: combinedMessage.current,
          time_to_start_answer: elapsedTime,
        },
        candidateAudio: audioBlob,
      });
      setHasSentData(true); // Prevent multiple sends
    }
  }, [isRecording, audioBlob, combinedMessage.current, sendData]);

  useEffect(() => {
    // This will update whenever 'transcript' changes and listening has stopped.
    if (!isRecording && audioBlob) {
      setMessageText("Analyzing your answer");
    }
  }, [isRecording, audioBlob]);

  // VIDEO
  const videoRef = useRef(null);
  const stream = useRef(null);
  const [isCameraActive, setIsCameraActive] = useState(false);

  const startVideo = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
        videoRef.current.play();
      }
      stream.current = mediaStream;
      setIsCameraActive(true);
    } catch (err) {
      console.error("Error accessing microphone and camera", err);
    }
  };

  const stopVideo = () => {
    if (stream.current) {
      stream.current.getTracks().forEach((track) => track.stop());
      stream.current = null;
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
      setIsCameraActive(false);
      stopRecording();
      if (mediaRecorder) {
        mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      }
    }
  };

  const requestMediaPermissions = async () => {
    try {
      stream.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (err) {
      console.error("Error accessing microphone and camera", err);
    }
  };

  useEffect(() => {
    let newAudio = null;

    const playNewAudio = async () => {
      if (audioPath && !isEndInterviewClicked) {
        setIsAudioPlaying(true);

        newAudio = new Audio(
          `${process.env.REACT_APP_BACKEND_APP_URL}${audioPath}`
        );

        try {
          await newAudio.play();
        } catch (error) {
          // Handle play() promise rejection (usually due to the browser's autoplay policy)
          setIsAudioPlaying(false);
          console.error("Failed to play audio:", error);
        }

        newAudio.onended = () => {
          if (autoEnd) {
            handleEndInterview();
          } else {
            setIsAudioPlaying(false);
            setElapsedTime(0); // Reset elapsed time when audio ends
            timerRef.current = setInterval(() => {
              setElapsedTime((prevTime) => prevTime + 1); // Increment the elapsed time every second
            }, 1000);
          }
        };
      }
    };

    playNewAudio();

    // Cleanup previous audio instance when audioPath changes or component unmounts
    return () => {
      if (newAudio) {
        newAudio.pause();
        newAudio.onended = null;
      }
      clearInterval(timerRef.current);
    };
  }, [audioPath]);

  const [popupCompontStatus, setPopupCompontStatus] = useState(1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPopupCompontStatus((prevStatus) => {
        if (prevStatus < 4) {
          return prevStatus + 1;
        } else {
          clearInterval(intervalId);
          return 4;
        }
      });
    }, 2000);
    return () => clearInterval(intervalId);
  }, [popupCompontStatus]);

  const handleAutomaticProgression = () => {
    setShowPopUp(true);
    setshowCont(true);
    sethideCont(false);
  };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return (
        <div className="timer">
          <Link
            style={{
              color: "#0B4A96 !important",
              fontSize: "30px",
              fontWeight: 700,
            }}
            onClick={getReady}
          >
            Get ready
          </Link>
        </div>
      );
    }

    return (
      <div className="timer">
        <div className="value">{remainingTime}</div>
      </div>
    );
  };

  const endRenderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return (
        <div className="timer">
          <Link onClick={getEnd}>Thank you</Link>
        </div>
      );
    }

    return (
      <div className="timer">
        <div className="value">{remainingTime}</div>
        {/* <p>Thank you!</p> */}
      </div>
    );
  };

  const [showCont, setshowCont] = useState(false);
  const [hideCont, sethideCont] = useState(true);

  const getReady = () => {
    setshowCont(true);
    sethideCont(false);
  };

  const getEnd = () => {
    sethideCont(false);
  };

  const handleEndInterview = () => {
    if (idleWarningCount === 3) {
      dispatch(trackTryHaireIdleTime(interviewId, token));
    }
    sendData({
      data: {
        interview_stage: "end",
        ai_question: "",
        time_to_start_answer: elapsedTime,
      },
    });
    sethideCont(true);
    setIsEndInterviewClicked(true);
    setLoadingResults(true);
    setAudioPath("");
    setIsAudioPlaying(false);
    stopVideo();
    setLoadingResults(true);
    setBlurBackground(true);

    // Clear the inactivity timeout when ending the interview
    if (endInterviewTimeoutRef.current) {
      clearTimeout(endInterviewTimeoutRef.current);
      endInterviewTimeoutRef.current = null;
    }

    const timeoutId = setTimeout(() => {
      setLoadingResults(false);
      setBlurBackground(false);
      navigate(
        `/try-haire/interview/${interviewId}/assessment-and-transcript`,
        {
          state: { interviewId },
        }
      );
    }, 10000); // 10 seconds

    return () => {
      clearTimeout(timeoutId); // Cleanup timeout on component unmount
    };
  };

  const showEndModal = () => {
    // to handle browser back click
    window.history.pushState({ customBack: true }, "");
    window.addEventListener("popstate", () => showEndModal());
    setIsEndModalVisible(true);
    setShowPopUp(false);
  };

  const handleEndCancel = () => {
    setIsEndModalVisible(false);
    setIsWindowFocused(true);
  };

  const handleConfirmEndInterview = () => {
    handleEndInterview();
    setIsEndModalVisible(false);
  };

  const handleSkipCancel = () => {
    setIsSkipModalVisible(false);
    setIsWindowFocused(true);
  };

  const handleSkipQuestion = () => {
    stopRecording();
    setTimer(0);
    sendData({
      data: {
        interview_stage: "processing",
        ai_question: combinedMessage.current,
        candidate_answer: "[Candidate skipped this question ask next question]",
      },
    });
    setIsSkipModalVisible(false);
  };

  const handleStartRecording = () => {
    startRecording();
    setTimer(180); // Reset timer to 3 minutes
    setTimerActive(true); // Start the time
    setHasSentData(false);

    clearInterval(timerRef.current);

    if (endInterviewTimeoutRef.current) {
      clearTimeout(endInterviewTimeoutRef.current);
      endInterviewTimeoutRef.current = null;
    }
  };

  const handleStopRecording = () => {
    const delayInMilliseconds = 500;
    setTimeout(() => {
      stopRecording();
      setTimerActive(false);
    }, delayInMilliseconds);
    const timer = setTimeout(() => {
      setMessageText("Generating next question");
    }, 2500);
    return () => {
      clearTimeout(timer);
    };
  };

  useEffect(() => {
    let interval;
    if (timerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      handleStopRecording();
    }
    return () => clearInterval(interval);
  }, [timerActive, timer]);

  useEffect(() => {
    if (!isRecording && audioPath) {
      setTimer(180);
      setTimerActive(false);
    }
  }, [audioPath]);

  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    initialCountDownDone.current = false;
    requestMediaPermissions();
    const timeoutId = setTimeout(() => {
      handleAutomaticProgression();
      startVideo();
    }, 3000);

    const handlePopState = (event) => {
      showEndModal();
    };
    // Push initial state
    window.history.pushState({ customBack: true }, "");
    // to handle browser back click
    window.addEventListener("popstate", handlePopState);

    return () => {
      clearTimeout(timeoutId);
      stopVideo();
      initialCountDownDone.current = false;
      if (endInterviewTimeoutRef.current) {
        clearTimeout(endInterviewTimeoutRef.current);
      }
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    if (interviewUnderReview) {
      navigate(
        `/try-haire/interview/${interviewId}/assessment-and-transcript`,
        { state: { interviewId } }
      );
    }
  }, [interviewUnderReview, navigate, interviewId]);

  return (
    <>
      <TryHaireInterviewModuleNav data={navData} />
      {loadingResults ? (
        hideCont ? (
          <section className="get_ready_panel_sec">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="get_ready_panel_sec_inner">
                    <div className="get_ready_panel_sec_inner_box">
                      <div className="timer-wrapper">
                        <CountdownCircleTimer
                          isPlaying
                          size={240}
                          duration={3}
                          colors={["#0B4A96", "#0B4A96", "#0B4A96", "#0B4A96"]}
                          colorsTime={[10, 6, 3, 0]}
                          onComplete={() => ({
                            shouldRepeat: false,
                            delay: 1,
                          })}
                          strokeWidth={7}
                        >
                          {endRenderTime}
                        </CountdownCircleTimer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <Analyse />
        )
      ) : interviewResult ? (
        navigate(
          `/try-haire/interview/${interviewId}/assessment-and-transcript`,
          { state: { interviewId } }
        )
      ) : (
        <>
          {hideCont && (
            <section className="get_ready_panel_sec">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="get_ready_panel_sec_inner">
                      <Typography
                        variant="span"
                        color={"#0B4A96"}
                        fontWeight={700}
                        fontSize={28}
                      >
                        Your AI Avatar interview will start in
                      </Typography>
                      <div className="get_ready_panel_sec_inner_box">
                        <div className="timer-wrapper">
                          <CountdownCircleTimer
                            isPlaying
                            size={240}
                            duration={3}
                            colors={[
                              "#0B4A96",
                              "#0B4A96",
                              "#0B4A96",
                              "#0B4A96",
                            ]}
                            colorsTime={[10, 6, 3, 0]}
                            onUpdate={(remainingTime) => {
                              if (remainingTime == 1) {
                                setMessageText("");
                                setSocketLoading(true);
                                initialCountDownDone.current = true;
                              }
                            }}
                            onComplete={() => {
                              return {
                                shouldRepeat: false,
                                delay: 1,
                              };
                            }}
                            strokeWidth={7}
                          >
                            {renderTime}
                          </CountdownCircleTimer>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          {showCont && (
            <div
              id="int_panel_main"
              className={blurBackground ? "blur-background" : ""}
            >
              <section className="int_panel_body_sec">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="int_panel_body_sec_inner">
                        <div className="int_panel_body_sec_inner_top">
                          <div className="progress_section">
                            <Progress
                              percent={interviewPercentage}
                              format={(percent) => `${percent}% Completed`}
                              style={{
                                width: "100%",
                                height: "20px",
                              }}
                            />
                          </div>
                          <div className="timer">
                            <img src={clock} alt="" />
                            {timerActive ? formatTime() : "03:00"}
                          </div>
                        </div>
                        <Image
                          preview={false}
                          src={isAudioPlaying ? HairoLipSync : HairoListening}
                          style={{
                            position: "relative",
                            bottom: "50px",
                            height: "66vh",
                            aspectRatio: 16 / 9,
                          }}
                        />

                        <div
                          className={
                            !socketLoading &&
                            (combinedMessage.current == null ||
                              combinedMessage.current === "" ||
                              combinedMessage.current === "undefined")
                              ? "welcm_text_inner2"
                              : "welcm_text_inner"
                          }
                        >
                          <h6 style={{ userSelect: "none" }}>
                            {!socketLoading ? (
                              combinedMessage.current !== null &&
                              combinedMessage.current !== "" &&
                              combinedMessage.current !== "undefined" ? (
                                combinedMessage.current
                              ) : (
                                ""
                              )
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  userSelect: "none",
                                }}
                              >
                                <ThreeDotLoader />
                                <p
                                  className="generating_p"
                                  style={{
                                    userSelect: "none",
                                  }}
                                >
                                  {messageText}
                                </p>
                              </div>
                            )}
                          </h6>
                        </div>
                        <Card className="your_videoi_part_sec">
                          <h6>You</h6>
                          <video
                            ref={videoRef}
                            style={{ width: "100%", borderRadius: "10px" }}
                            autoPlay
                            muted
                            playsInline
                          ></video>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="int_panel_body_allow_btn_sec">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="int_panel_body_allow_btn_sec_inner">
                        <div className="record_vdeo_icn_sec">
                          <button
                            className={`tap_speak_button ${
                              isAudioPlaying ||
                              socketLoading ||
                              isAudioButtonDisabled
                                ? "slipQ_disable2"
                                : ""
                            }`}
                            onClick={() => {
                              if (isRecording) {
                                handleStopRecording();
                              } else {
                                handleStartRecording();
                              }
                            }}
                            disabled={
                              isAudioPlaying ||
                              socketLoading ||
                              isAudioButtonDisabled
                            }
                          >
                            {isRecording ? (
                              <Tooltip
                                className="tooltip_interview"
                                color="#464646"
                                title={
                                  "Tap this button to end your response and submit it."
                                }
                                placement="right"
                              >
                                <div className="tap_to_stop">
                                  <div
                                    style={{
                                      background:
                                        "linear-gradient(124.13deg, #1FB8C3 18.04%, #7977F7 86.09%)",
                                      height: 46,
                                      width: 46,
                                      borderRadius: 34,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Bars
                                      height="24"
                                      width="24"
                                      color={"#fff"}
                                      ariaLabel="bars-loading"
                                      wrapperStyle={{}}
                                      wrapperClass=""
                                      visible={true}
                                    />
                                  </div>
                                  {isRecording
                                    ? "Tap to stop"
                                    : "Tap to answer"}
                                </div>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                className="tooltip_interview"
                                color="#464646"
                                title={
                                  "Tap this button to talk and allow our AI to begin registering your response."
                                }
                                placement="right"
                              >
                                {isAudioPlaying ||
                                socketLoading ||
                                isAudioButtonDisabled ? (
                                  <DisableMicIcon />
                                ) : (
                                  <MicListening />
                                )}
                                {isRecording ? "Tap to stop" : "Tap to answer"}
                              </Tooltip>
                            )}
                          </button>
                        </div>
                        <div className="ent_int_btn">
                          <button onClick={showEndModal}>End Interview</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )}
          <CustomPopup
            isModalVisible={isEndModalVisible}
            handleCancel={handleEndCancel}
            handleConfirm={handleConfirmEndInterview}
            question={"Are you sure you want to end the interview?"}
            subtitle={"Ending the interview may impact your job application."}
          />
          <CustomPopup
            isModalVisible={isSkipModalVisible}
            handleCancel={handleSkipCancel}
            handleConfirm={handleSkipQuestion}
            question={"Are you sure you want to skip the question?"}
            subtitle={"Skipping the question may impact your job application."}
          />
          <IdleWarningModal
            isVisible={isIdleModalVisible}
            onContinue={handleContinueInterview}
            onEndInterview={handleEndInterview}
            warningCount={idleWarningCount}
            key={idleWarningCount} // Use the warning count as a key to restart the timer
          />
        </>
      )}
      {!interviewResult && (
        <WarningModal
          tabSwitchWarningVisible={tabSwitchWarningVisible}
          handleCancelWarning={handleCancelWarning}
          tabSwitchCount={tabSwitchCount}
        />
      )}
    </>
  );
};

export default TryInterview;
