import "./EmailLoginPopup.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { ThreeCircles } from "react-loader-spinner";
import loginCodeStore from "../../zustand/loginCodeStore";
import { Controller, useForm } from "react-hook-form";
import { CustomOtpInput } from "../CustomComponents/CustomOtpInput";
import { FormHelperText } from "@mui/material";
import userStore from "../../zustand/userStore";

const EmailLoginPopup = ({ timer, setTimer, startTimer }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
    reset,
  } = useForm();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(name, value); // Update the state in the store
    validateField(name, value);
  };

  const {
    formData,
    verifyLoginOtp,
    resendLoginOtp,
    formErrors,
    setFormData,
    closeModal,
    validateForm,
    loading,
    loginWithCode,
    validateField,
    openModal,
    initialCloseModal,
    modalOpen,
  } = loginCodeStore();

  const {
    registerWithEmailPassword,
    loading: registrationLoading,
    openModal: registrationOpenModal,
  } = userStore();

  const redirectUrl = useSelector((state) => state.auth.redirectUrl);

  const handleVerifyClick = async (data) => {
    const { otp } = data;

    if (!otp || otp.length !== 6) {
      toast.error("Please enter a valid 6-digit OTP");
      return;
    }

    const verified = await verifyLoginOtp(
      formData.email,
      parseInt(otp),
      dispatch,
      navigate,
      redirectUrl
    );
    if (verified) {
      toast.success("Logged in successfully!");
      closeModal();
      initialCloseModal();
    } else {
      toast.error("Verification Code is Invalid or Expired");
    }
  };

  const handleResendOtp = async () => {
    resendLoginOtp(formData.email);
    setValue("otp", ""); // Clear the OTP field
    setTimer(60); // Reset the timer to 60 seconds
    startTimer();
  };

  const handleSubmited = async (event) => {
    event.preventDefault();
    const isValid = await validateForm();
    if (isValid) {
      loginWithCode(
        formData.email,
        () => {
          setTimer(60); // Reset the timer to 60 seconds
          openModal(); //bool val to change the UI in the modal
          startTimer();
        },
        redirectUrl ?? location.state?.redirectTo,
        () => {
          registerWithEmailPassword(
            formData.email,
            "defaultPassword123",
            () => {
              // Reset the timer to 60 seconds
              initialCloseModal();
              registrationOpenModal();
              setTimer(60);
              startTimer();
            }
          );
        }
      );
    }
  };

  return !modalOpen ? (
    //EMAIL fields
    <div className="Email_popup_wrapper">
      <div className="label-wrapper">
        Email <span className="required">*</span>
      </div>

      <form onSubmit={handleSubmited}>
        <input
          className="email_input"
          type="email"
          name="email"
          placeholder="Enter email id"
          value={formData.email}
          onChange={handleChange}
          required
        />
        {formErrors.email && <div className="error">{formErrors.email}</div>}

        <button
          className={
            !formErrors.email
              ? "login_btn_blue_enable"
              : "login_btn_blue_disable"
          }
          type="submit"
        >
          {loading || registrationLoading? (
            <ThreeCircles
              visible={true}
              height="20"
              width="20"
              color="#fff"
              ariaLabel="loading-indicator"
            />
          ) : !formErrors.email ? (
            "Log In"
          ) : (
            "Proceed"
          )}
        </button>
      </form>
    </div>
  ) : (
    //OTP fields
    <div className="verification-container">
      <h6>Verification Code</h6>
      <p className="verification-modal-subtitle">
        We have send a verification code to{" "}
        <span className="verification-modal-email">{formData.email}</span>
        <br />
        That’s not my email.{" "}
        <span
          className="verification-modal-cta"
          onClick={() => {
            reset();
            closeModal();
          }}
        >
          Change email
        </span>
        .
      </p>
      <form
        onSubmit={handleSubmit(handleVerifyClick)}
        className="enter_otp_box_sec acc_veri"
      >
        <label>Enter Verification Code</label>
        <br />
        <Controller
          name="otp"
          control={control}
          defaultValue=""
          rules={{ required: true, validate: (value) => value.length === 6 }}
          render={({ field }) => <CustomOtpInput {...field} length={6} />}
        />
        {errors.otp && (
          <FormHelperText error>
            {errors.otp.type === "required"
              ? "OTP is required"
              : "OTP must be 6 digits"}
          </FormHelperText>
        )}
        <br />
        <div className="resend_btn_new">
          <button
            type="button"
            className="resend_otp_btn_new"
            disabled={timer > 0}
            onClick={handleResendOtp}
          >
            Resend Verification code
          </button>
          <span>{timer < 10 ? `00:0${timer}` : `00:${timer}`}</span>
        </div>
        <br />
        <div className="login_btn_blue send_verfy_btn">
          <button
            className="blue_btn_links"
            type="submit"
            disabled={loading || !isValid}
          >
            {loading ? (
              <ThreeCircles
                visible={true}
                height="20"
                width="20"
                color="#fff"
                ariaLabel="loading-indicator"
              />
            ) : (
              "Verify"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EmailLoginPopup;
