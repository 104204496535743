
const interviewGuidelinesData = [
    {
      title: "Interview Guidelines",
      sections: [
        {
          heading: "1. Prepare Your Environment",
          list: [
            "Choose a quiet, well-lit space with minimal distractions.",
            "Verify that your camera, microphone, and internet connection are working properly before starting."
          ],
        },
      ],
    },
    {
      title: "Additional Guidelines",
      sections: [
        {
          heading: "1. Browser & Tab Usage",
          list: [
            "Tab Limits: Avoid switching tabs more than three times. Exceeding this limit will result in a final warning and may terminate the session.",
            "Back Button: If you click the back button or switch browsers, a pop-up will ask if you want to end the interview. Confirming will terminate the session.",
            "Closing The Browser: Avoid manually closing the browser, as this will end the session permanently and prevent you from restarting.",
          ],
        },
        {
          heading: "2. Internet Connection",
          list: [
            "Connection Drop: Stay on the same page if your internet connection is lost; the interview will resume automatically when connectivity is restored.",
            "Refreshing: If necessary, refresh the page. You can remain on the platform for up to 30 minutes, and the recruiter will be notified of your status.",
          ],
        },
        {
          heading: "3. Technical Issues",
          list: ["For further support, contact haire@support.ai."],
        },
      ],
    },
  ];
  
  export default interviewGuidelinesData;
  