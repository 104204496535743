import React from "react";
import { Form, Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateJobDetails } from "../../../../../redux/reducers/jobReducer";

const AiAvatarInterviewDuration = ({ form, handleModalClick }) => {
    const jobDetails = useSelector((state) => state.job.details);
    const dispatch = useDispatch();
    const qcInt = [
        { id: 1, mainText: "for Quick Interview", radioText: "Up to 15 Mins", creditText: "1 Credit", value: "00:15:00" },
        { id: 2, mainText: "for Balanced Interview", radioText: "Up to 30 Mins", creditText: "2 Credits",  value: "00:30:00" },
        { id: 3, mainText: "for In-depth Interview", radioText: "Up to 45 Mins", creditText: "3 Credits", value: "00:45:00" },
    ];

    const interview_duration = jobDetails?.interview_duration; // Get current value from form

    const handleSelection = (value) => {
        form.setFieldsValue({ interview_duration: value }); // Update Ant Design Form state
        dispatch(
            updateJobDetails({
            ...jobDetails,
            interview_duration: value,
            })
        );
    };


    return (
        <Form.Item
            name="interview_duration"
            rules={[{ required: true, message: "Please select an interview duration" }]}
            className="custom_class_required"
        >
            <div className="recriuter_step_2_inner_btm_single">
                <div className="recriuter_step_2_inner_btm_single_top">
                    <div className="recriuter_step_2_inner_btm_single_top_left">
                        <h5>
                            AI Avatar Interview Duration<pre>*</pre>
                            <button type="button" onClick={handleModalClick}>
                                What is an AI Avatar Interview?
                            </button>
                        </h5>
                    </div>
                    <div className="recriuter_step_2_inner_btm_single_top_right">
                        <h6>Recommended: 30-45 Minutes</h6>
                    </div>
                </div>
                <div className="recriuter_step_2_inner_btm_single_btm">
                    {qcInt.map((item) => (
                        <div
                            key={item.id}
                            className="recriuter_step_2_inner_btm_single_btm_single"
                            onClick={() => handleSelection(item.value)}
                            style={{
                                border: interview_duration === item.value ? "0px solid transparent" : "0px solid transparent",
                                padding: "10px",
                                cursor: "pointer",
                            }}
                        >
                            <h6>{item.mainText}</h6>
                            <div className="qc_body">
                                <div className="qc_body_radio new_qx">
                                    <label className="container">
                                        {item.radioText}
                                        <Radio value={item.value}
                                            name="ai_interview_duration"
                                            checked={interview_duration === item.value}
                                            onChange={() => handleSelection(item.value)}>
                                            
                                        </Radio>
                                        {/* <input
                                            type="radio"
                                            value={item.value}
                                            name="ai_interview_duration"
                                            checked={interview_duration === item.value}
                                            onChange={() => handleSelection(item.value)}
                                        />
                                        <span className="checkmark"></span> */}
                                    </label>
                                </div>
                                <div className="qc_body_text">
                                    <h6>{item.creditText}</h6>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Form.Item>

    );
};

export default AiAvatarInterviewDuration;
