import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateJobDetails } from "../../../../../redux/reducers/jobReducer";
import GaugeChart from "react-gauge-chart";
import { info_cin_new } from "../../../../../assets/images/images";
import recommendedInterviewsStore from "../../../../../zustand/recommendInterviewStore";
import { useEffect } from "react";
import { calculateGaugePercentage, getFeedbackMessage } from "../../../../../utils/gaugeCalculation";
import { parseTimeToMinutes } from "../../../../../utils/utils";

const CreditInput = ({ form }) => {
  const token = useSelector((state) => state.auth.token);
  const jobDetails = useSelector((state) => state.job.details);
  const companyDetails = useSelector((state) => state.company?.details);
  const dispatch = useDispatch();

  const { generateRecommendedCount, recommendedCount, isLoading, error } =
    recommendedInterviewsStore();

  const validateCredits = (credits) => {
    if (credits > companyDetails?.company_credits) {
      form.setFields([
        {
          name: "credits_alloted",
          errors: ["Credits alloted should not exceed the available credits."],
        },
      ]);
    }
  };

  const handleCreditChange = (event) => {
    const value = event.target.value;
    form.setFieldsValue({ credits_alloted: value });
    dispatch(
      updateJobDetails({
        ...jobDetails,
        credits_alloted: value,
        budgetType: 2,
      })
    );
    validateCredits(value);
  };

  const gaugePercentage = calculateGaugePercentage(jobDetails?.credits_alloted,recommendedCount, jobDetails?.interview_duration);

  useEffect(() => {
    if (jobDetails?.interview_duration) {
      generateRecommendedCount(
        {
          interview_time: parseTimeToMinutes(jobDetails?.interview_duration),
          open_position: jobDetails?.no_of_vacancies,
          job_title: jobDetails?.title,
          job_description: jobDetails?.description,
        },
        token
      );
    }
  }, [
    jobDetails?.interview_duration,
    jobDetails?.no_of_vacancies,
    jobDetails?.title,
    jobDetails?.description,
  ]);

  return (
    <div className="recriuter_step_2_inner_btm_single brdr_none">
      <div className="recriuter_step_2_inner_btm_single_top">
        <div className="recriuter_step_2_inner_btm_single_top_left new_left">
          <h5>
            Budget for the Job<pre>*</pre>
            <div className="inof_icn new_budget_info">
              <img src={info_cin_new} alt="" />
              <div className="budget_sec_tool">
                <h6>
                  Budget for the job represents the total number of credits
                  available for this job listing. Credit usage will remain
                  within the assigned credit budget and will not exceed the
                  allocated limit.
                </h6>
              </div>
            </div>
            {/* <Tooltip
              title={
                "Budget for the job represents the total number of credits available for this job listing. Credit usage will remain within the assigned credit budget and will not exceed the allocated limit."
              }
            >
              <span className="inof_icn new_budget_info">
              <img src={info_cin_new} alt="" />
              </span>
            </Tooltip> */}
          </h5>
        </div>
        <div className="recriuter_step_2_inner_btm_single_top_right">
          <h6>Recommended: {recommendedCount} Interviews</h6>
        </div>
      </div>
      <div className="budget_job_inner_btm">
        <div className="budget_job_inner_btm_left">
          <p>
            Set the number of credits you want to allocate from your available
            balance for this job listing.
          </p>
          <input
            type="number"
            min={1}
            max={companyDetails?.company_credits}
            value={jobDetails?.credits_alloted}
            placeholder="Enter credits per job listing"
            onChange={handleCreditChange}
            onWheel={(e) => e.target.blur()}
          />
        </div>
        <div className="budget_job_inner_btm_right">
          <GaugeChart
            hideText
            needleColor="#272525"
            arcPadding={0}
            percent={gaugePercentage}
            needleBaseColor="#272525"
            colors={["#BAE6FD", "#60A5FA", "#1D4ED8"]}
            cornerRadius={0}
          />
          {getFeedbackMessage(gaugePercentage)}
        </div>
      </div>
    </div>
  );
};

export default CreditInput;
