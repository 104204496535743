import React from 'react'
import './Recruiter.css'
import TotalCreditSec from '../../components/RecruiterWrittenAssessment/TotalCreditSec'
import TotalCreditSecBtm from '../../components/RecruiterWrittenAssessment/TotalCreditSecBtm'
import TotalCreditBtns from '../../components/RecruiterWrittenAssessment/TotalCreditBtns'

const Recruiter = () => {
  return (
    <>
        <section className='recriuter_step_2'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-8 col-md-8 mx-auto'>
                        <div className='recriuter_step_2_inner'>
                            <TotalCreditSec/>
                            <TotalCreditSecBtm />
                            <TotalCreditBtns />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Recruiter
