import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../JobDetails/JobDetails.css";
import { Modal } from "react-bootstrap";
import instance from "../../../../api/instance";
import styles from "./written.module.css";
import CodeEditor from "../../../CodeEditor/CodeEditor";

const Written = () => {
  const token = useSelector((state) => state?.auth?.token);
  const applicant = useSelector((state) => state?.job?.applicationResult);
  const [assessmentResult, setAssessmentResult] = useState(null);

  const [show, setShow] = useState(false);
  const jobDetails = useSelector((state) => state?.job?.details);
  const [modalData, setModalData] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [totalSteps, setTotalSteps] = useState(0);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    if (applicant && jobDetails) {
      get_quiz_result();
    }
  }, [applicant, jobDetails]);

  const get_quiz_result = async () => {
    try {
      const response = await instance.get(
        `/assignment/${jobDetails?.assignment_id}/users/${applicant?.user}/detail/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setAssessmentResult(response.data);
      }
    } catch (error) {
      console.error("Error creating company:", error);
      setIsError(true);
    }
  };

  const goToNextStep = () => {
    setCurrentStep((prev) => {
      setCurrentQuestion(getQuizQuestionsByType(modalData.title)?.[prev + 1]);
      return prev + 1;
    });
  };
  const goToPrevStep = () => {
    setCurrentStep((prev) => {
      setCurrentQuestion(getQuizQuestionsByType(modalData.title)?.[prev - 1]);
      return prev - 1;
    });
  };

  const handleClose = () => {
    setShow(false);
    setModalData(null);
    setCurrentQuestion(null);
    setCurrentStep(0);
    setTotalSteps(0);
  };

  const getQuizQuestionsByType = (type) => {
    if (type === "total") {
      return assessmentResult?.user_responses;
    }
    return assessmentResult?.user_responses?.filter(
      (question) => question.question_type === type
    );
  };
  const mcqModal = (key) => {
    setShow(true);
    setModalData({ title: key });
    setTotalSteps(getQuizQuestionsByType(key).length);
    setCurrentQuestion(getQuizQuestionsByType(key)?.[0]);
  };

  if (isError || !assessmentResult) {
    return (
      <div className="Written_test_tab_main_sec">
        <div className="Written_test_tab_main_sec_inner">
          <table
            width="100%"
            cellPadding={0}
            cellSpacing={0}
            className="table_sec_written"
          >
            <thead>
              <tr>
                <td>Question Type</td>
                <td>No. of Questions</td>
                <td>Points Obtained</td>
                <td>Maximum Points</td>
                <td>View</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>NA</td>
                <td>NA</td>
                <td>NA</td>
                <td>NA</td>
                <td>NA</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="Written_test_tab_main_sec">
        {assessmentResult?.assignment_result?.status === "PASSED" ? (
          <h2 className={styles.heading}>
            Candidate has successfully cleared the written assessment
          </h2>
        ) : assessmentResult?.assignment_result?.status === "FAILED" ? (
          <h2 className={styles.heading_failed}>
            Unfortunately, candidate has failed written test.
          </h2>
        ) : (
          <h2 className={styles.heading_failed}></h2>
        )}

        <div className="Written_test_tab_main_sec_inner">
          <table
            width="100%"
            cellPadding={0}
            cellSpacing={0}
            className="table_sec_written"
          >
            <thead>
              <tr>
                <td>Question Type</td>
                <td>No. of Questions</td>
                <td>Points Obtained</td>
                <td>Maximum Points</td>
                <td>View</td>
              </tr>
            </thead>
            <tbody>
              {assessmentResult &&
                assessmentResult?.stats_by_question_type &&
                Object.entries(assessmentResult?.stats_by_question_type).map(
                  ([key, section], index) => (
                    <tr key={index}>
                      <td>
                        <strong>{key.toUpperCase()}</strong>
                      </td>
                      <td>{section.total_questions}</td>
                      {/* <td>{section.correct_questions}</td> */}
                      <td>{section.marks_obtained}</td>
                      <td>{section.total_marks}</td>
                      {key !== "total" ? (
                        <td
                          onClick={() => {
                            mcqModal(key);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <i class="fa-solid fa-arrow-up rotate"></i>
                        </td>
                      ) : (
                        <> </>
                      )}
                    </tr>
                  )
                )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} className="mcq_sec_new_main">
        <button type="button" className="close_icn" onClick={handleClose}>
          <i class="fas fa-times"></i>
        </button>
        <div className="mcq_sec_new_main_inner">
          <div className="mcq_sec_new_main_inner_head">
            <h6>{modalData?.title || ""} - Answers</h6>
            <span>
              Total Marks:{" "}
              {
                assessmentResult?.stats_by_question_type[modalData?.title]
                  ?.marks_obtained
              }
              /
              {
                assessmentResult?.stats_by_question_type[modalData?.title]
                  ?.total_marks
              }
            </span>
          </div>
          <div className="mcq_sec_new_main_inner_body">
            <section className="interview_mscq_module">
              <div className="interview_mscq_module_inner_head_list">
                <ul>
                  {[...Array(totalSteps || 0).keys()].map((item, index) => (
                    <li
                      key={index}
                      className={currentStep === index ? "active" : ""}
                    >
                      {index + 1}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="interview_mscq_module_inner">
                      <div className={`interview_mscq_module_inner_body`}>
                        {modalData?.title === "MCQ" ||
                        modalData?.title === "total" ? (
                          <>
                            {currentQuestion?.question_type === "MCQ" && (
                              <div className="ques_ans_sec">
                                <div className="ques_ans_text_sec_new">
                                  <h5>
                                    Answer:{" "}
                                    <span
                                      className={
                                        currentQuestion?.is_correct
                                          ? "answer_is_correct"
                                          : "answer_is_incorrect"
                                      }
                                    >
                                      {currentQuestion?.is_correct
                                        ? "Correct"
                                        : "Incorrect"}
                                    </span>
                                  </h5>
                                </div>
                                <div
                                  className="ques_ans_sec_main_top"
                                  style={{ marginTop: "20px" }}
                                >
                                  <h6>{currentQuestion?.question}</h6>
                                  <h6>{currentQuestion?.code}</h6>
                                </div>
                                <div className="ques_ans_sec_main_btm">
                                  <ul>
                                    {currentQuestion &&
                                      Object.entries(
                                        currentQuestion?.options
                                      ).map(([key, value], index) => (
                                        <li key={index}>
                                          <div className="ques_ans_single">
                                            <label
                                              className={`container_mcq
                                                    ${
                                                      currentQuestion?.answer ===
                                                        key &&
                                                      currentQuestion?.answer !==
                                                        currentQuestion?.correct_answer
                                                        ? "answer_is_incorrect"
                                                        : ""
                                                    }
                                            ${
                                              currentQuestion?.correct_answer ===
                                              key
                                                ? "answer_is_correct"
                                                : ""
                                            }
                                                `}
                                            >
                                              {key}) {value}
                                              <input
                                                type="radio"
                                                name="radio"
                                                value={key}
                                                checked={
                                                  currentQuestion?.answer ===
                                                  key
                                                }
                                                disabled
                                              />
                                              <span
                                                className={`checkmark_mcq ${
                                                  currentQuestion?.answer !==
                                                    currentQuestion?.correct_answer &&
                                                  currentQuestion?.correct_answer ===
                                                    key
                                                    ? "remove_checkmark"
                                                    : ""
                                                }`}
                                              ></span>
                                            </label>
                                          </div>
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                        {modalData?.title === "CODING" && (
                          <div className="">
                            <div className="ques_ans_sec_main_top">
                              <h6>{currentQuestion?.question}</h6>
                            </div>
                            <div className="code-view">
                              <CodeEditor
                                userCode={currentQuestion?.answer}
                                userOutput={currentQuestion?.coding_output}
                                isRecruiter={true}
                              />
                            </div>
                          </div>
                        )}
                        {(modalData?.title === "SHORT" ||
                          modalData?.title === "ESSAY") && (
                          <div className={styles.question_container}>
                            <div className="ques_ans_sec_main_top">
                              <h6>{currentQuestion.question}</h6>
                            </div>
                            <div className="ques_ans_sec_text_area_result">
                              <textarea
                                value={currentQuestion?.answer}
                                readOnly
                                type="text"
                                placeholder="Write Answer"
                              />
                            </div>
                          </div>
                        )}
                        <div className={`ques_ans_sec_btn`}>
                          {currentStep !== 0 ? (
                            <button
                              className="ques_btn_prev"
                              onClick={goToPrevStep}
                            >
                              Back
                            </button>
                          ) : (
                            <div></div>
                          )}
                          {currentStep < totalSteps - 1 && (
                            <>
                              <button
                                className="ques_btn_next"
                                onClick={goToNextStep}
                              >
                                Next
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Written;
