import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import {
  Credit,
  haire_gif_new,
  haire_logo,
} from "../../../assets/images/images";
import { usePricingModal } from "../../../contexts/PricingModalContext";
import { setRedirectUrl } from "../../../redux/reducers/authReducer";
import MenuList from "./MenuList";
import Notification from "./Notification";
import "./navbar.css";

export const Navbar = ({ creditsButtonRef }) => {
  const location = useLocation();
  const { showPricingModal } = usePricingModal();
  const hideHeaderRoutes = ["/login", "/sign-up", "/", "/forget-password"];
  const showHeader = !hideHeaderRoutes.includes(location.pathname);
  const role = useSelector(
    (state) => state.auth?.user?.role ?? state.auth?.user?.user_role
  );
  const isRecruiter = role === 1;
  const companyDetails = useSelector((state) => state.company?.details);

  const handleScroll = () => {
    const hmMainSec = document.querySelector(".hm_main_sec");
    const mobileHeaderSec = document.querySelector(".mobile_header_sec");

    if (window.scrollY > 100) {
      hmMainSec.classList.add("sticky_header", "animated");
      mobileHeaderSec.classList.add("sticky_header", "animated");
    } else {
      hmMainSec.classList.remove("sticky_header", "animated");
      mobileHeaderSec.classList.remove("sticky_header", "animated");
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getHeaderRoute = (role) => {
    switch (role) {
      case 1:
        return "/dashboard";
      case 2:
        return "/applicant-dashboard";
      default:
        return "/";
    }
  };

  return showHeader ? (
    <>
      {/* Desktop Navbar */}
      <nav className="navbar navbar-expand-lg navbar-light bg-light hm_main_sec desk_top">
        <div className="container-fluid">
          <Link className="navbar-brand2" to={getHeaderRoute(role)}>
            <img src={haire_gif_new} alt="Logo" />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav ms-auto d-flex align-items-center nav_bar_sec">
              <Notification />
              {isRecruiter ? (
                <button className="Credits_btn" onClick={showPricingModal} ref={creditsButtonRef}>
                  <img src={Credit} alt="" />
                  {companyDetails?.company_credits ?? 0} Credits
                </button>
              ) : (
                <></>
              )}


              {/* <img src={user_image} alt="User" class="rounded-circle me-2" /> */}

              <MenuList isRecruiter = {isRecruiter} />
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navbar */}
      <section className="mobile_header_sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="mobile_header_sec_inner">
                <div className="mobile_header_sec_inner_left">
                  <Link className="navbar-brand" href="#">
                    <img src={haire_logo} alt="LOgo" />
                  </Link>
                </div>
                <div className="mobile_header_sec_inner_right">
                  <div className="navbar-nav ms-auto d-flex align-items-center nav_bar_sec">
                    <Notification />

                    {/* <img
                      src={user_image}
                      alt="User"
                      class="rounded-circle me-2"
                    /> */}
                    {isRecruiter ? (
                      <button
                        className="Credits_btn"
                        onClick={showPricingModal}
                        ref={creditsButtonRef}
                      >
                        <img src={Credit} alt="" />
                        {companyDetails?.company_credits ?? 0} Credits
                      </button>
                    ) : (
                      <></>
                    )}
                    <MenuList />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  ) : null;
};

export const PublicNavbar = () => {
  const subscriptions = useSelector((state) => state.subscriptions);
  const { jobId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.token);
  const hideHeaderRoutes = ["/login", "/sign-up", "/", "/forget-password"];
  const showHeader = !hideHeaderRoutes.includes(location.pathname);

  const handleClick = (event) => {
    if (!token && jobId) {
      dispatch(setRedirectUrl(`/applicant-dashboard/related-jobs/${jobId}`));
      navigate("/sign-up");
      return;
    }
    event.preventDefault();
    // dispatch(signOut());
    navigate("/sign-up");
  };

  const handleScroll = () => {
    const hmMainSec = document.querySelector(".hm_main_sec");
    const mobileHeaderSec = document.querySelector(".mobile_header_sec");

    if (window.scrollY > 100) {
      hmMainSec.classList.add("sticky_header", "animated");
      mobileHeaderSec.classList.add("sticky_header", "animated");
    } else {
      hmMainSec.classList.remove("sticky_header", "animated");
      mobileHeaderSec.classList.remove("sticky_header", "animated");
    }
  };
  const handleDemoClick = (event) => {
    event.preventDefault();
    window.open(subscriptions?.zoho_link, '_blank');
  };

  const handleNavbarClick = (event) => {
    event.preventDefault();
    navigate("/")
  }

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return showHeader ? (
    <>
      {/* Desktop Navbar */}
      <nav className="navbar navbar-expand-lg navbar-light bg-light hm_main_sec desk_top">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src={haire_logo} alt="Logo" />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav ms-auto d-flex align-items-center nav_bar_sec">
              {/* <button onClick={handleLogin} className="join_now_btn">
                Join Us
              </button> */}
              <Link
                className="sign_up_btn_new after_mobile"
                onClick={handleDemoClick}
                style={{
                  border: "2px solid #1865c1",
                  background: "#fff",
                  color: "#020202",
                  marginRight: "10px",
                }}
              >
                Book a Demo
              </Link>
              <button
                className="sign_up_btn_new after_mobile"
                onClick={handleClick}
              >
                Sign Up / Login
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Navbar */}
      <section className="mobile_header_sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="mobile_header_sec_inner">
                <div className="mobile_header_sec_inner_left">
                  <Link className="navbar-brand" onClick={handleNavbarClick}>
                    <img src={haire_logo} alt="LOgo" />
                  </Link>
                </div>
                <div className="mobile_header_sec_inner_right">
                  <div className="navbar-nav ms-auto d-flex align-items-center nav_bar_sec">
                    {/* <button onClick={handleLogin} className="join_now_btn">
                      Join Us
                    </button> */}
                    <button
                      className="sign_up_btn_new after_mobile"
                      onClick={handleClick}
                    >
                      Sign Up / Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  ) : null;
};
