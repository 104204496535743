import {
  DeleteOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Progress,
  Radio,
  Space,
  Tooltip,
  Upload,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { baseURL, websocketUrl } from "../../../../api/instance";
import {
  GenerateAi,
  magic_stick,
  web_icn,
  web_icn_1,
  web_icn_2,
  web_icn_3,
  web_icn_4,
} from "../../../../assets/images/images";
import ChipsInputComponent from "../../../../components/CommonComponents/ChipsInput";
import GenerateWithAi from "../../../../components/CommonComponents/GenerateWithAi";
import LoaderApply from "../../../../components/CommonComponents/Loader/LoaderApply";
import UploadButton from "../../../../components/CustomComponents/UploadButton";
import useWebSocket from "../../../../hooks/useWebSocket";
import {
  fetchCompanyDetails,
  updateCompany,
} from "../../../../redux/actions/companyActions";
import {
  resetCompany,
  updateCompanyGallery,
} from "../../../../redux/reducers/companyReducer";
import "./MyCompany.css";

import DOMPurify from "dompurify";
import { marked } from "marked";
import Autocomplete from "react-google-autocomplete";
import { ThreeCircles } from "react-loader-spinner";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import TurndownService from "turndown";
import GalleryComponent from "../../../../components/CustomComponents/EditGallery";
import ImageGallery from "../../../../components/CustomComponents/ImageGallery";
import { no_of_employees1 } from "../../../../utils/constants";
import useFileUpload from "../../../../hooks/useFileUpload";
import useSuggestionsGenerator from "../../../../hooks/useAISuggestionsGenerator";

const MyCompany = () => {
  const turndownService = new TurndownService();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [progress, setProgress] = useState(0);
  const companyData = useSelector((state) => state?.company?.details);
  const user = useSelector((state) => state?.auth?.user);
  const token = useSelector((state) => state?.auth?.token);
  const { uploadFile } = useFileUpload();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [localFormData, setLocalFormData] = useState({});
  const [overviewText, setOverviewText] = useState(companyData?.overview || "");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [galleryUrls, setGalleryUrls] = useState(companyData?.gallery || "");
  const userId = companyData?.user;
  const url = `${websocketUrl}/company_profile_descrption/${userId}/`;
  const loading = useSelector((state) => state.company.loading);
  const [isLoading, setIsLoading] = useState(loading);
  const [imageUploadingLoader, setImageUploadingLoader] = useState(false);
  const companyID = user.company_id ?? companyData?.id;
  const [industryArray, setIndustryArray] = useState(
    typeof companyData?.industry === "string"
      ? companyData?.industry?.split(",")
      : Object.entries(companyData?.industry || {}).map(([key, value]) => value)
  );
  const [product_servicesArray, setProduct_servicesArray] = useState(
    typeof companyData?.product_services === "string"
      ? companyData?.product_services?.split(",")
      : Object.entries(companyData?.product_services || {}).map(
          ([key, value]) => value
        )
  );
  const [perksBenefitsArray, setPerksBenefitsArray] = useState(
    typeof companyData?.perks_benefits === "string"
      ? companyData?.perks_benefits?.split(",")
      : Object.entries(companyData?.perks_benefits || {}).map(
          ([key, value]) => value
        )
  );
  const [cultureArray, setCultureArray] = useState(
    typeof companyData?.culture === "string"
      ? companyData?.culture?.split(",")
      : Object.entries(companyData?.culture || {}).map(([key, value]) => value)
  );

  const aiIndustryTypes = useSuggestionsGenerator({
    type: "industry",
    keyword: industryArray.join(","),
    previousSuggestions: industryArray.join(","),
    jobProfile: "",
    experience: "",
  });

  const aiProducServices = useSuggestionsGenerator({
    type: "product & services",
    keyword: industryArray.join(","),
    previousSuggestions: product_servicesArray.join(","),
    jobProfile: "",
    experience: "",
  });

  const aiPerksBenefits = useSuggestionsGenerator({
    type: "perks & benefits",
    keyword: industryArray.join(","),
    previousSuggestions: perksBenefitsArray.join(","),
    jobProfile: "",
    experience: "",
  });

  const aiCultureEnvironment = useSuggestionsGenerator({
    type: "culture & environment",
    keyword: industryArray.join(","),
    previousSuggestions: cultureArray.join(","),
    jobProfile: "",
    experience: "",
  });

  const { sendData, socketLoading, combinedMessage } = useWebSocket(url);

  const galleryString = companyData?.gallery;

  const extractFileName = (url) => {
    return url.substring(url.lastIndexOf("/") + 1);
  };

  const defaultFileList = galleryString?.split(",").map((url, index) => ({
    uid: String(-index - 1),
    name: extractFileName(url.trim()),
    status: "done",
    url: url.trim(),
  }));

  const [fileList, setFileList] = useState(defaultFileList);
  const employeeRangeKey =
    companyData && companyData?.no_of_employees
      ? Object.values(companyData?.no_of_employees)[0]
      : null;

  const handleAIGeneration = () => {
    form
      .validateFields([
        "name",
        "address",
        "industry",
        "product_services",
        "perks_benefits",
        "culture",
      ])
      .then((values) => {
        // All validations passed, proceed with A.I. generation
        const updatedPayload = {
          company_name: values.name,
          company_address: values.address,
          industry_type: values.industry,
          products_and_services: values.product_services,
          perks_and_benefits: values.perks_benefits,
          culture_and_work_environment: values.culture,
          current_profile: "",
        };
        setProgress(0);
        sendData(updatedPayload);
        let progressValue = 0;
        const interval = setInterval(() => {
          progressValue += 1;
          setProgress((prevProgress) => {
            if (prevProgress < 99) {
              return progressValue;
            } else {
              clearInterval(interval);
              return prevProgress;
            }
          });
        }, 100);
      })
      .catch((errorInfo) => {
        // Validation failed
        scrollToFirstError(errorInfo.errorFields);
      });
  };

  function scrollToFirstError(errorFields) {
    if (errorFields && errorFields.length > 0) {
      setTimeout(() => {
        const firstFieldWithError = document.getElementById(
          errorFields[0].name
        );
        if (firstFieldWithError) {
          firstFieldWithError.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 100);
    }
  }

  const handleChange = async (info) => {
    if (info.file.status === "uploading") {
      setIsLoading(true);
      return;
    }
    if (info.file.status === "done") {
      const file = info.file.originFileObj;

      // Assume `token` is available as a prop or from some other source

      const fileUrl = await uploadFile(file, token);

      if (fileUrl) {
        setImageUrl(fileUrl);
        toast.success("Profile photo uploaded successfully");

        setIsLoading(false);
        // Update form field or state with the file URL
        form.setFieldsValue({ profile_pic: fileUrl });
      } else {
        setIsLoading(false);
        setImageUrl(
          "https://haire.s3.ap-south-1.amazonaws.com/production/2871791_7015+1.png"
        );
        toast.error("Error uploading the file");
      }
    } else if (info.file.status === "error") {
      setIsLoading(false);
      toast.error("Upload failed: An error occurred");
      setImageUrl(
        "https://haire.s3.ap-south-1.amazonaws.com/production/2871791_7015+1.png"
      );
    }
  };

  // const handleChange = async (info) => {
  //   if (info.file.status === "uploading") {
  //     setIsLoading(true);
  //     return;
  //   }
  //   if (info.file.status === "done") {
  //     // Assume `handleUpload` is a function that uploads the file and returns the URL
  //     const file = info.file.originFileObj;

  //     const uploadResults = await handleUpload([file], "companyProfilePic");
  //     const fileUrl = uploadResults.get(file) || uploadResults[file];
  //     if (fileUrl) {
  //       toast.success("Profile photo uploaded successfully");
  //       // Update local state with the new image URL
  //       setLocalFormData({ ...localFormData, profile_pic: fileUrl });
  //       setImageUrl(fileUrl);
  //       setIsLoading(false);
  //       form.setFieldsValue({ profile_pic: fileUrl });
  //     } else {
  //       setIsLoading(false);
  //       setImageUrl(
  //         "https://haire.s3.ap-south-1.amazonaws.com/production/2871791_7015+1.png"
  //       );
  //       toast.error("Error uploading the file");
  //     }
  //   } else if (info.file.status === "error") {
  //     setIsLoading(false);
  //     toast.error("Upload failed: An error occurred");
  //     setImageUrl(
  //       "https://haire.s3.ap-south-1.amazonaws.com/production/2871791_7015+1.png"
  //     );
  //   }
  // };

  useEffect(() => {
    if (galleryString) {
      const fileListFromGallery = galleryString
        .split(",")
        .map((url, index) => ({
          uid: String(-index - 1),
          name: extractFileName(url.trim()),
          status: "done",
          url: url.trim(),
        }));
      setFileList(fileListFromGallery);
    } else {
      setFileList([]);
    }
  }, [galleryString]);

  useEffect(() => {
    dispatch(fetchCompanyDetails(companyID, token, false));
  }, [dispatch]);

  useEffect(() => {
    if (companyData) {
      const formattedIndustry = formatArray(companyData.industry);
      const formattedProductServices = formatArray(
        companyData.product_services
      );
      const formattedPerksBenefits = formatArray(companyData.perks_benefits);
      const formattedCulture = formatArray(companyData.culture);

      setIndustryArray(formattedIndustry);
      setProduct_servicesArray(formattedProductServices);
      setPerksBenefitsArray(formattedPerksBenefits);
      setCultureArray(formattedCulture);
      form.setFieldsValue({
        ...companyData,
        industry: formattedIndustry.join(","),
        product_services: formattedProductServices.join(","),
        perks_benefits: formattedPerksBenefits.join(","),
        culture: formattedCulture.join(","),
        no_of_employees: employeeRangeKey
          ? parseInt(employeeRangeKey, 10)
          : companyData?.no_of_employees,
        name: capitalizeWords(companyData?.name),
      });
      setImageUrl(companyData.profile_pic);
    }
  }, [companyData, form, employeeRangeKey]);

  const formatArray = (data) => {
    if (typeof data === "string") {
      return data
        .split(",")
        .map((item) => item.trim())
        .map((item) => capitalizeFirstLetter(item));
    } else {
      return Object.entries(data || {}).map(([key, value]) => {
        return capitalizeFirstLetter(value);
      });
    }
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleIndustryTypes = (newChips) => {
    const validChips = newChips
      .map((chip) => chip.trim())
      .filter((chip) => chip !== "");
    setIndustryArray(validChips);
    form.setFieldsValue({ industry: validChips.join(",") });
    setLocalFormData((prevState) => ({
      ...prevState,
      industry: validChips.join(","),
    }));
  };

  const handleProductServices = (newChips) => {
    const validChips = newChips
      .map((chip) => chip.trim())
      .filter((chip) => chip !== "");
    setProduct_servicesArray(validChips);
    form.setFieldsValue({ product_services: validChips.join(",") });
    setLocalFormData((prevState) => ({
      ...prevState,
      product_services: validChips.join(","),
    }));
  };

  const handlePerks = (newChips) => {
    const validChips = newChips
      .map((chip) => chip.trim())
      .filter((chip) => chip !== "");
    setPerksBenefitsArray(validChips);
    form.setFieldsValue({ perks_benefits: validChips.join(",") });
    setLocalFormData((prevState) => ({
      ...prevState,
      perks_benefits: validChips.join(","),
    }));
  };

  const handleCulture = (newChips) => {
    const validChips = newChips
      .map((chip) => chip.trim())
      .filter((chip) => chip !== "");
    setCultureArray(validChips);
    form.setFieldsValue({ culture: validChips.join(",") });
    setLocalFormData((prevState) => ({
      ...prevState,
      culture: validChips.join(","),
    }));
  };

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleAddressChange = (place) => {
    const newAddress = place?.formatted_address;
    form.setFieldsValue({ address: newAddress });
    setLocalFormData((prevState) => ({ ...prevState, address: newAddress }));
  };

  useEffect(() => {
    if (companyData) {
      form.setFieldsValue({
        ...companyData,
        address: companyData.address,
      });
    }
  }, [companyData?.address, form]);

  useEffect(() => {}, [refreshKey]);

  useEffect(() => {
    if (companyData?.overview) {
      const htmlContent = marked(companyData.overview);
      setOverviewText(htmlContent);
    }
  }, [companyData?.overview]);

  useEffect(() => {
    if (combinedMessage !== null && combinedMessage !== "") {
      setProgress(100);
      setTimeout(() => {
        const htmlContent = marked(combinedMessage);
        form.setFieldsValue({ overview: htmlContent });
        setOverviewText(htmlContent);
      }, 500);
    }
  }, [combinedMessage, companyData]);

  const handleOverviewChange = (content) => {
    setOverviewText(content);
    const markdownContent = turndownService.turndown(content);
    form.setFieldsValue({ overview: markdownContent });
  };

  const getPlainText = (html) => {
    const cleanHtml = DOMPurify.sanitize(html);
    const doc = new DOMParser().parseFromString(cleanHtml, "text/html");
    return doc.body.textContent || "";
  };

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen);
    if (isEditModalOpen) {
      setLocalFormData({});
      setOverviewText("");
      form.resetFields();

      dispatch(fetchCompanyDetails(companyID, token, false));
    }
  };

  // const industryArray =

  let numberOfEmployees = companyData?.no_of_employees;
  let result;

  if (
    typeof numberOfEmployees === "number" ||
    no_of_employees1[numberOfEmployees]
  ) {
    result = no_of_employees1[numberOfEmployees];
  } else {
    result =
      Object.values(companyData?.no_of_employees || {})[0] || "Not specified";
  }

  const handleFormKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleFormChange = (_, allValues) => {
    setLocalFormData(allValues);
  };

  const handlePreview = () => {
    setPreviewVisible(true);
  };

  const handleRemove = () => {
    setImageUrl(null);
    form.setFieldValue({ profile_pic: null });
    setLocalFormData({ ...localFormData, profile_pic: null });
    form.setFields([
      {
        name: "profile_pic",
        errors: ["Please add company logo"],
      },
    ]);
  };

  const handleGalleryUpdate = (updatedGalleryUrls) => {
    setGalleryUrls(updatedGalleryUrls);
  };

  const handleSaveAndContinue = (e) => {
    e.preventDefault();
    form
      .validateFields()
      .then((values) => {
        if (!overviewText) {
          form.setFields([
            {
              name: "overview",
              errors: ["Please add company overview"],
            },
          ]);
          scrollToFirstError([
            {
              name: ["overview"],
              errors: ["Please add company overview"],
              warnings: [],
            },
          ]);
          return;
        }

        if (!imageUrl) {
          // Directly prompt the user to add a company logo if missing
          form.setFields([
            {
              name: "profile_pic",
              errors: ["Please add a company logo"],
            },
          ]);
          scrollToFirstError([
            {
              name: ["profile_pic"],
              errors: ["Please add a company logo"],
              warnings: [],
            },
          ]);
          return; // Stop execution if no profile picture is uploaded
        }
        const updatedDetails = {
          ...companyData,
          ...values,
          ...localFormData,
          name: capitalizeWords(values.name),
          overview: overviewText,
          file: galleryUrls,
        };

        dispatch(updateCompanyGallery(galleryUrls));
        dispatch(
          updateCompany(
            token,
            updatedDetails,
            user.company_id ? user.company_id : companyData.id
          )
        )
          .then(() => {
            toast.success("Edited successfully!");
            setRefreshKey((oldKey) => oldKey + 1);
            dispatch(fetchCompanyDetails(companyID, token, false));
          })
          .catch((error) => {
            toast.error("Failed to update company details. Please try again.");
            console.error("Failed to update company details:", error);
          });
        setIsEditModalOpen(false);
      })
      .catch((errorInfo) => {
        scrollToFirstError(errorInfo.errorFields);
        console.error("Failed to update company details:", errorInfo);
      });
  };

  const addSuggestedItem = (
    selectedItems,
    itemToAdd,
    setSelectedItems,
    handleChange
  ) => {
    // if (!selectedItems.includes(itemToAdd)) {
    if (
      selectedItems.findIndex(
        (item) => item.toLowerCase() === itemToAdd.toLowerCase()
      ) === -1
    ) {
      const updatedItems = [...selectedItems, itemToAdd];
      handleChange(updatedItems);
      setSelectedItems(updatedItems);
    }
  };

  const handleClick = () => {
    navigate("/company-details");
    dispatch(resetCompany());
  };

  if (!companyData?.id) {
    return (
      <div>
        <h2 className="header_font">My Company</h2>
        <div className="add_com_data_container">
          <div className="add_com_det_sec">
            <p>
              Ensure your company details are filled out to begin
              <br />
              posting jobs and strengthen your profile presence.
            </p>
            <div className="btn_comp_sec">
              <button className="btn_comp_sec_btn" onClick={handleClick}>
                <span>
                  <i className="fas fa-plus"></i>
                </span>
                Add Company Details
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="my_comp_dashboard_sec">
        <div className="my_comp_dashboard_sec_body">
          <div className="my_comp_dashboard_sec_body_head">
            <div className="my_comp_dashboard_left_logo">
              <div className="logo_sec_img">
                <img src={companyData?.profile_pic} alt="" />
              </div>
              <h6>{capitalizeWords(companyData?.name)}</h6>
            </div>
            <div className="my_comp_dashboard_left_edit">
              <button type="button" onClick={toggleEditModal}>
                <i className="fas fa-pencil-alt"></i> Edit
              </button>
            </div>
          </div>
          <div className="comp_prof_cont_sec">
            <div className="comp_prof_inner_text">
              <h5>Company Profile</h5>
              <div
                dangerouslySetInnerHTML={{
                  __html: marked(companyData?.overview || ""),
                }}
              ></div>
            </div>
            <div className="web_site_industy_sec">
              <div className="web_site_industy_sec_inner">
                <div className="web_site_industy_sec_inner_single">
                  <div className="web_site_industy_sec_inner_single_top">
                    <img src={web_icn} alt="" />
                    <h6>Website</h6>
                  </div>
                  <div className="web_site_industy_sec_inner_single_btm">
                    <h5>
                      <a
                        href={companyData?.website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {companyData?.website}
                      </a>
                    </h5>
                  </div>
                </div>
                <div className="web_site_industy_sec_inner_single">
                  <div className="web_site_industy_sec_inner_single_top">
                    <img src={web_icn_1} alt="" />
                    <h6>Industry</h6>
                  </div>
                  <div className="web_site_industy_sec_inner_single_btm">
                    <h5>
                      {industryArray?.map((industry, index) => (
                        <span key={index}>
                          {industry.charAt(0).toUpperCase() + industry.slice(1)}
                          {index < industryArray.length - 1 ? ", " : ""}
                        </span>
                      ))}
                    </h5>
                  </div>
                </div>
                <div className="web_site_industy_sec_inner_single">
                  <div className="web_site_industy_sec_inner_single_top">
                    <img src={web_icn_2} alt="" />
                    <h6>Company Size</h6>
                  </div>
                  <div className="web_site_industy_sec_inner_single_btm">
                    <h5>{result} Employees</h5>
                  </div>
                </div>
                <div className="web_site_industy_sec_inner_single">
                  <div className="web_site_industy_sec_inner_single_top">
                    <img src={web_icn_3} alt="" />
                    <h6>Address</h6>
                  </div>
                  <div className="web_site_industy_sec_inner_single_btm">
                    <h5>
                      {companyData?.address?.charAt(0).toUpperCase() +
                        companyData?.address?.slice(1)}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="web_site_industy_sec_inner_single">
                <div className="web_site_industy_sec_inner_single_top">
                  <img src={web_icn_4} alt="" />
                  <h6>Product And Services </h6>
                </div>
                <div className="web_site_industy_sec_inner_single_btm">
                  {/* <h5>{companyData.product_services}</h5> */}
                  {/* */}
                  <ul>
                    {product_servicesArray?.map((benefit, index) => (
                      <li key={index}>
                        {benefit?.replace(/\b\w/g, (char) =>
                          char.toUpperCase()
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="pers_benifits_sec">
              <h6>Perks & Benefits</h6>
              <ul>
                {perksBenefitsArray?.map((benefit, index) => (
                  <li key={index}>
                    {benefit?.replace(/\b\w/g, (char) => char.toUpperCase())}
                  </li>
                ))}
              </ul>
            </div>
            <div className="pers_benifits_sec">
              <h6>Culture and Work Environment</h6>
              <ul className="innovate_list">
                {cultureArray?.map((value, index) => (
                  <li key={index}>
                    {value?.replace(/\b\w/g, (char) => char.toUpperCase())}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {galleryUrls && <ImageGallery images={galleryUrls} />}
        </div>
      </div>

      <Modal
        show={isEditModalOpen}
        onHide={toggleEditModal}
        size="xl"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="custom_modal_container new_modl_cont"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Company Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom_modal_scrollable ">
          <div className="scrollable_modal">
            <Form
              onKeyDown={handleFormKeyDown}
              form={form}
              onValuesChange={handleFormChange}
              name="validateOnly"
              layout="vertical"
              autoComplete="off"
              className="company_detail_form"
            >
              <Form.Item
                name="profile_pic"
                valuePropName="fileList"
                rules={[
                  {
                    required: true,
                    message: "Please add company logo",
                  },
                ]}
              >
                <div id="profile_pic">
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={`${baseURL}job/mock-api/`}
                    beforeUpload={beforeUpload}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    accept="image/jpeg, image/png"
                  >
                    {isLoading ? (
                      <ThreeCircles
                        height="20"
                        width="20"
                        color="#1865C1"
                        ariaLabel="loading-indicator"
                      />
                    ) : imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "contain",
                          padding: "10px",
                        }}
                      />
                    ) : (
                      <UploadButton />
                    )}
                  </Upload>
                </div>

                {imageUrl && (
                  <div className="view_delete">
                    <Button
                      icon={<EyeOutlined />}
                      onClick={handlePreview}
                    ></Button>
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={handleRemove}
                    ></Button>
                  </div>
                )}
                <Modal
                  show={previewVisible}
                  onHide={() => setPreviewVisible(false)}
                  centered
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <img
                      src={imageUrl}
                      alt="Preview"
                      style={{ width: "100%" }}
                    />
                  </Modal.Body>
                </Modal>
                <span className="profile_pic">
                  Add Company Logo <span style={{ color: "red" }}>*</span>
                </span>
              </Form.Item>
              <Form.Item
                name="name"
                label="Company Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter company name",
                  },
                ]}
              >
                <Input placeholder="Enter Company Name" id="name" />
              </Form.Item>
              <Form.Item
                name="address"
                label="Company Address"
                rules={[
                  {
                    required: true,
                    message: "Please enter company address",
                  },
                ]}
              >
                <Autocomplete
                  id="address"
                  style={{ width: "100%" }}
                  apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                  onPlaceSelected={handleAddressChange}
                  defaultValue={form.getFieldValue("address")}
                  onChange={(event) => {
                    form.setFieldsValue({ address: event.target.value });
                  }}
                  options={{
                    types: ["establishment"],
                  }}
                  inputProps={{
                    autoComplete: "off",
                  }}
                />
              </Form.Item>

              <Form.Item
                name="industry"
                label={
                  <span>
                    <Tooltip title="Limit: 75 character per chip. Sentences will split at commas.">
                      <Button type="text" icon={<InfoCircleOutlined />} />
                    </Tooltip>
                    Industry Type{" "}
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please add at least one industry type",
                  },
                ]}
              >
                <ChipsInputComponent
                  id={"industry"}
                  key="industry"
                  placeholder="Add Industry Type"
                  values={industryArray}
                  onChipsChange={handleIndustryTypes}
                  formatCase="camelCase"
                />
              </Form.Item>

              <div style={{ paddingBottom: "30px" }}>
                <div className="suggestion_sec_new">
                  <ul className="list_item_sec">
                    {aiIndustryTypes?.map((industry, index) => (
                      <li
                        style={{ cursor: "pointer" }}
                        key={index}
                        onClick={() =>
                          addSuggestedItem(
                            industryArray,
                            industry,
                            setIndustryArray,
                            handleIndustryTypes
                          )
                        }
                      >
                        {industry}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <Form.Item
                name="product_services"
                label={
                  <span>
                    <Tooltip
                      title="Limit: 75 character per chip. Sentences will split at commas.
"
                    >
                      <Button type="text" icon={<InfoCircleOutlined />} />
                    </Tooltip>
                    Product And Services{" "}
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please enter product and services",
                  },
                ]}
              >
                <ChipsInputComponent
                  id={"product_services"}
                  key="product_services"
                  placeholder="Type a keyword and press enter"
                  value={product_servicesArray}
                  onChipsChange={handleProductServices}
                  formatCase="camelCase"
                />
              </Form.Item>

              <div style={{ paddingBottom: "30px" }}>
                <div className="suggestion_sec_new">
                  <ul className="list_item_sec">
                    {aiProducServices?.map((industry, index) => (
                      <li
                        style={{ cursor: "pointer" }}
                        key={index}
                        onClick={() =>
                          addSuggestedItem(
                            product_servicesArray,
                            industry,
                            setProduct_servicesArray,
                            handleProductServices
                          )
                        }
                      >
                        {industry}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <Form.Item
                name="perks_benefits"
                label={
                  <span>
                    <Tooltip
                      title="Limit: 75 character per chip. Sentences will split at commas.
"
                    >
                      <Button type="text" icon={<InfoCircleOutlined />} />
                    </Tooltip>
                    Perks and Benefits
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please enter Perks and benefits",
                  },
                ]}
              >
                <ChipsInputComponent
                  id={"perks_benefits"}
                  key="perks_benefits"
                  placeholder="Type a keyword and press enter"
                  value={perksBenefitsArray}
                  onChipsChange={handlePerks}
                  formatCase="camelCase"
                />
              </Form.Item>

              <div style={{ paddingBottom: "30px" }}>
                <div className="suggestion_sec_new">
                  <ul className="list_item_sec">
                    {aiPerksBenefits?.map((industry, index) => (
                      <li
                        style={{ cursor: "pointer" }}
                        key={index}
                        onClick={() =>
                          addSuggestedItem(
                            perksBenefitsArray,
                            industry,
                            setPerksBenefitsArray,
                            handlePerks
                          )
                        }
                      >
                        {industry}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <Form.Item
                name="culture"
                label={
                  <span>
                    <Tooltip
                      title="Limit: 75 character per chip. Sentences will split at commas.
"
                    >
                      <Button type="text" icon={<InfoCircleOutlined />} />
                    </Tooltip>
                    Culture and Work Environment
                  </span>
                }
                rules={[
                  {
                    required: true,
                    message: "Please enter Culture and Work Environment",
                  },
                ]}
              >
                <ChipsInputComponent
                  id={"culture"}
                  key="culture"
                  placeholder="Type a keyword and press enter"
                  value={cultureArray}
                  onChipsChange={handleCulture}
                  formatCase="camelCase"
                />
              </Form.Item>

              <div style={{ paddingBottom: "30px" }}>
                <div className="suggestion_sec_new">
                  <ul className="list_item_sec">
                    {aiCultureEnvironment?.map((industry, index) => (
                      <li
                        style={{ cursor: "pointer" }}
                        key={index}
                        onClick={() =>
                          addSuggestedItem(
                            cultureArray,
                            industry,
                            setCultureArray,
                            handleCulture
                          )
                        }
                      >
                        {industry}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <Form.Item
                name="overview"
                label="Company Overview"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      const plainTextValue = getPlainText(value).trim();
                      if (!plainTextValue) {
                        return Promise.reject(
                          new Error("Overview cannot be empty")
                        );
                      }
                      if (plainTextValue.length > 1000) {
                        return Promise.reject(
                          new Error("Overview cannot exceed 1000 characters")
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
              
                <button
                  type="button"
                  className="data_generator"
                  onClick={handleAIGeneration}
                >
                  <img src={magic_stick} alt="" /> Generate with A.I
                </button>
                {socketLoading ? (
                  <div className="new_data_sec_com">
                    <GenerateWithAi progress={progress} />
                  </div>
                ) : (
                  <>
                    <div className="react-quill-container">
                      <ReactQuill
                        id="overview"
                        key={"overview"}
                        theme="snow"
                        value={overviewText}
                        onChange={handleOverviewChange}
                        modules={MyCompany.modules}
                        formats={MyCompany.formats}
                        placeholder="Enter Company Overview.
                    For Example:
                      • Perks and benefits 
                      • Culture and work environment
                      • Career development Opportunities 
                      • Community Engagement and Responsibilities
                      • Latest News And Updates "
                        rows={4}
                      />
                    </div>
                    <div
                      style={{ marginTop: "5px", textAlign: "right" }}
                      className="lenght_data"
                    >
                      {getPlainText(overviewText).length}/1000{" "}
                    </div>
                    
                  </>
                )}
              </Form.Item>
              <Form.Item
                name="website"
                label="Company Website"
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid website URL",
                    pattern:
                      /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/,
                  },
                ]}
              >
                <Input placeholder="Eg: https://www.test.com" id="website" />
              </Form.Item>

              <Form.Item
                name="location"
                label="Location"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Radio.Group id="location">
                  <Radio value={1} className="workmode_location">
                    On-site
                  </Radio>
                  <Radio value={2} className="workmode_location">
                    Remote
                  </Radio>
                  <Radio value={3} className="workmode_location">
                    Hybrid
                  </Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="no_of_employees"
                label="Number of Employees"
                rules={[
                  {
                    required: true,
                    message: "Please select number of employees!",
                  },
                ]}
              >
                <Radio.Group id="no_of_employees">
                  <Radio.Button value={1} className="num0femp">
                    0 - 50
                  </Radio.Button>
                  <Radio.Button value={2} className="num0femp">
                    51 - 100
                  </Radio.Button>
                  <Radio.Button value={3} className="num0femp">
                    101 - 200
                  </Radio.Button>
                  <Radio.Button value={4} className="num0femp">
                    201-300
                  </Radio.Button>
                  <Radio.Button value={5} className="num0femp">
                    301-500
                  </Radio.Button>
                  <Radio.Button value={6} className="num0femp">
                    500+
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item name="file" label="Company Gallery">
                <GalleryComponent
                  initialUrls={galleryUrls}
                  onUpdate={handleGalleryUpdate}
                  setImageUploadingLoader={setImageUploadingLoader}
                  imageUploadingLoader={imageUploadingLoader}
                />
              </Form.Item>
            </Form>
            <div className="save-btn-container">
              {imageUploadingLoader ? (
                <button className="saveContinue" disabled>
                  <LoaderApply color="white" />
                </button>
              ) : (
                <button
                  className="saveContinue"
                  onClick={handleSaveAndContinue}
                >
                  {isEditModalOpen ? (
                    "Save & Continue"
                  ) : (
                    <LoaderApply color="white" />
                  )}
                </button>
              )}
            </div>
          </div>
          <div className="submit_btn_container">
            <Space></Space>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

MyCompany.modules = {
  toolbar: [["bold", "italic"]],
};

MyCompany.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];

export default MyCompany;

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
    return false;
  }
  const maxFileSizeMB = 5; // Maximum file size in MB
  const isLtMaxSize = file.size / 1024 / 1024 < maxFileSizeMB;
  if (!isLtMaxSize) {
    message.error(`Image must be smaller than ${maxFileSizeMB}MB!`);
    return false;
  }
  return isJpgOrPng && isLtMaxSize;
};
