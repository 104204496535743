import { create } from "zustand";
// @ts-ignore
import instance from "../api/instance";

interface PracticeInterviewState {
  practiceInterviewId: number | null;
  loading: boolean;
  error: string | null;
  createPracticeInterview: (
    token: string,
    jobId: string
  ) => Promise<number | null>;
}

const usePracticeInterviewStore = create<PracticeInterviewState>((set) => ({
  practiceInterviewId: null,
  loading: false,
  error: null,

  createPracticeInterview: async (token: string, jobId: string) => {
    set({ loading: true, error: null });

    try {
      const response = await instance.post(
        "/practice/interview/",
        { job_id: jobId },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const practiceInterviewId = response.data.practice_interview_id;
      set({
        practiceInterviewId,
        loading: false,
      });

      return practiceInterviewId;
    } catch (error: any) {
      set({
        error: error.response?.data?.message || error.message,
        loading: false,
      });
      return null;
    }
  },
}));

export default usePracticeInterviewStore;
