import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  dash_black,
  dash_blue,
  linkedin,
  linkicon,
  mail_icn,
  mail_icn_new,
  msg_black,
  msg_blue,
  my_jobs_black,
  my_jobs_blue,
  submenu,
  submenufill,
  wp_icn,
} from "../../../assets/images/images";
import { Navbar } from "../../../components/DashboardComponents/Navbar/Navbar";
import { usePricingModal } from "../../../contexts/PricingModalContext";
import { loadSubscriptions } from "../../../redux/actions/subscriptionActions";
import DashboardSideBar from "../DashboardSideBar/DashboardSideBar";
import "./Dashboard.css";
import DashboardMain from "./DashboardMain/DashboardMain";
import JobDetails from "./JobDetails/JobDetails";
import Monitization from "./Monitization/MonitizationPage";
import PricingModal from "./Monitization/components/PricingModal";
import MyCompany from "./MyCompany/MyCompany";
import MyJobs from "./MyJobs/MyJobs";
import ViewJobsData from "./MyJobs/ViewJobsData";
import ViewDemoJob from "./MyJobs/ViewDemoJob";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Invites from "../../../components/CommonComponents/Invites/Invites";
import { inviteCandidates } from "../../../redux/actions/jobActions";
import { fetchCompanyDetails } from "../../../redux/actions/companyActions";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { isPricingModalOpen, hidePricingModal } = usePricingModal();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const companyDetails = useSelector((state) => state?.company?.details);
  const companyID = user.company_id ?? companyDetails?.id;

  const [invitedItems, setInvitedItems] = useState([]);
  const creditsButtonRef = useRef(null);
  useEffect(() => {
    if (location.state?.showShareJobModal) {
      setShowModal(true);
      // Clear the state to prevent. showing the modal again if the user navigates back
      window.history.replaceState({}, document.title);
    }
  }, [location.state]);
  const handleClose = () => {
    setShowModal(false);
  };
  const tabList = [
    {
      key: "Dashboard",
      route: "",
      icon: dash_black,
      activeIcon: dash_blue,
      component: DashboardMain,
      active: true,
    },
    {
      key: "My Jobs",
      route: "my-jobs/*",
      icon: my_jobs_black,
      activeIcon: my_jobs_blue,
      component: MyJobs,
      active: true,
    },
    // {
    //   key: "Messages",
    //   route: "messages",
    //   icon: app_black,
    //   activeIcon: app_blue,
    //   component: Messages,
    // },
    {
      key: "My Company",
      route: "my-company",
      icon: msg_black,
      activeIcon: msg_blue,
      component: MyCompany,
      active: true,
    },
    // {
    //   key: "Settings",
    //   route: "settings",
    //   icon: set_black,
    //   activeIcon: set_blue,
    //   component: Settings,
    // },
    {
      key: "Subscription",
      route: "subscription",
      icon: submenu,
      activeIcon: submenufill,
      component: Monitization,
      active: true,
    },
  ];

  useEffect(() => {
    dispatch(loadSubscriptions());
    if (companyID) {
      dispatch(fetchCompanyDetails(companyID, token));
    }
  }, [dispatch]);

  const handleItemsChange = (items) => {
    setInvitedItems(items);
  };
  const handleReportSubmit = async () => {
    if (token && location.state?.slug && invitedItems.length > 0) {
      try {
        dispatch(inviteCandidates(token, undefined, invitedItems, location.state?.slug));
        setInvitedItems([]);
        toast.success("Invites sent successfully!");
      } catch (error) {
        console.error("Failed to send invites:", error);
        toast.error("Failed to send invites. Please try again.");
      }
    } else {
      toast.info("No candidates selected for invitation.");
    }
  };

  return (
    <>
      <Navbar creditsButtonRef={creditsButtonRef} />
      <section className="company_details_sec_new">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="company_details_sec_new_inner">
                {/* Dashboard Left Bar */}
                <DashboardSideBar tabList={tabList} />

                <div className="comp_det_rigt_main tab-content">
                  <Routes>
                    {tabList.map((tab, index) =>
                      tab.active ? (
                        <Route
                          key={index}
                          path={tab.route}
                          element={<tab.component creditsButtonRef={creditsButtonRef} />}
                        />
                      ) : null
                    )}
                    <Route
                      path="my-jobs/:jobType/:jobId/view-jobs-data"
                      element={<ViewJobsData />}
                    />
                    <Route
                      path="my-jobs/demo/:jobId/view-jobs-data"
                      element={<ViewDemoJob />}
                    />
                    <Route
                      path="my-jobs/:jobType/:jobId/edit"
                      element={<JobDetails />}
                    />
                  </Routes>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Pricing Modal start---------------------------------------- */}
      <PricingModal show={isPricingModalOpen} onHide={hidePricingModal} />
      {/* <div className="ResposiveContainer">
        <ResposiveContainer />
      </div> */}
      

      {/* <Modal
        show={showModal}
        onHide={handleClose}
        className="share_prof_sec_mod new_invite_mod_sec_new"
        
      >
        <div className="share_prof_sec_mod_inner">
          <button className="close_icn_new_sh" onClick={handleClose}>
            <i className="fas fa-times"></i>
          </button>
          <h6>Your job is live!</h6>
          <p>Start inviting candidates by sharing the job post!</p>
          <hr></hr>
          <div className="d-flex justify-content-between align-items-start">
            <ul>
            <li>
                <a
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                    `${window.location.origin}/jobs/${location.state?.slug}`
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedin} alt="linkedin" />
                </a>
              </li>
              <li>
                <a
                  href={`https://api.whatsapp.com/send?text=Check out this job! ${encodeURIComponent(
                    `${window.location.origin}/jobs/${location.state?.slug}`
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={wp_icn} alt="whatsapp" />
                </a>
              </li>

              <li>
                <a
                  href={`mailto:?subject=Check out this job!&body=Check out this job at ${encodeURIComponent(
                    `${window.location.origin}/jobs/${location.state?.slug}`
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={mail_icn_new} alt="gmail" />
                </a>
              </li>
            </ul>
            <button
              className="copy_link_btn"
              onClick={() => {
                navigator.clipboard
                  .writeText(
                    `${window.location.origin}/jobs/${location.state?.slug}` ||
                    "example.com/share-link"
                  )
                  .then(
                    () => {
                      toast.success("Link copied to clipboard!");
                    },
                    () => {
                      toast.error("Failed to copy the link.");
                    }
                  );
              }}
            >
              <img src={linkicon} alt="msg" />
              Copy
            </button>
          </div>

          <div className="copy_link_sec">

            <div className="copy_link_sec_input">
              <label>Or copy link</label>
            </div>
          </div>

          <div className="">
            <div className="invite_mod_inner">
              <Invites onItemsChange={handleItemsChange} />
              <div className="Invite_btn_main">
                <button
                  className="Invite_btn"
                  onClick={async () => {
                    await handleReportSubmit();
                    handleClose(); 
                  }}
                  disabled={invitedItems.length === 0}
                  style={{
                    backgroundColor:
                      invitedItems.length > 0 ? "#1865c1" : "#D9D9D9",
                    color: "white",
                    cursor: invitedItems.length > 0 ? "pointer" : "default",
                  }}
                >
                  Invite
                </button>
              </div>
            </div>

            <div className="">
              <div className="sub_footer">
                <div>
                  <p>
                    <i className="fas fa-lock"> </i>&nbsp; Only people invited
                    to this job post
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}

      <Modal
        show={showModal}
        onHide={handleClose}
        className="report_modal_sec new_invite_mod_sec_new"
      >
        <div className="report_modal_sec_inner">
          <div className="report_modal_sec_inner_head invite_modal">
            <div className="invite_modal_head">
              <h6>Your job is live!</h6>
              <p>Start inviting candidates by sharing the job post!</p>
            </div>
            <button onClick={handleClose}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="share_job_new_report">
            <div className="share_job_new_report_left">
                <h5>Share This Job</h5>
                <div className="share_prof_sec_mod_inner">

                  <ul>
                    <li>
                      <a
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                          `${window.location.origin}/jobs/${location.state?.slug}`
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={linkedin} alt="linkedin" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`https://api.whatsapp.com/send?text=Check out this job! ${encodeURIComponent(
                          `${window.location.origin}/jobs/${location?.slug}`
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={wp_icn} alt="whatsapp" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={`mailto:?subject=Check out this job!&body=Check out this job at ${encodeURIComponent(
                          `${window.location.origin}/jobs/${location?.slug}`
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={mail_icn_new} alt="gmail" />
                      </a>
                    </li>
                  </ul>
                </div>
            </div>
            <div className="share_job_new_report_right">
              <h6
                  className="copylink"
                  onClick={() => {
                    navigator.clipboard
                      .writeText(
                        `${window.location.origin}/jobs/${location.state?.slug}` ||
                          "example.com/share-link"
                      )
                      .then(
                        () => {
                          toast.success("Link copied to clipboard!");
                        },
                        () => {
                          toast.error("Failed to copy the link.");
                        }
                      );
                  }}
                >
                 <i class="fa-solid fa-link"></i>
                  Copy link
                </h6>
            </div>
          </div>
          <div className="report_modal_sec_inner_body">
            <span>Invite Top Candidates</span>
            
            <div className="invite_mod_inner">
              <Invites onItemsChange={handleItemsChange} />
              <div className="Invite_btn_main">
                <button
                  className="Invite_btn"
                  onClick={async () => {
                    await handleReportSubmit();
                    handleClose(); 
                  }}
                  disabled={invitedItems.length === 0}
                  style={{
                    backgroundColor:
                      invitedItems.length > 0 ? "#1865c1" : "#D9D9D9",
                    color: "white",
                    cursor: invitedItems.length > 0 ? "pointer" : "default",
                  }}
                >
                  Invite
                </button>
              </div>
            </div>

            <div className="new_sub_ftr">
              <div className="sub_footer">
                <div>
                  <p>
                    <i className="fas fa-lock"> </i>&nbsp; Only people invited to this job post can appear for AI Interview
                  </p>
                </div>
                {/* <div><p>can apply</p></div> */}
              </div>
            </div>
          </div>
        </div>
      </Modal>

    </>
  );
};

export default Dashboard;
