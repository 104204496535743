import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import useMultipleFileUpload from "../../hooks/useMultipleFileUpload";
import { useSelector } from "react-redux";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const UploadComponent = ({ fileList, setFileList, onS3UrlsChange }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const { uploadFiles } = useMultipleFileUpload();
  const token = useSelector((state) => state?.auth?.token);
  const previousFileListRef = useRef([]);

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (file.error) {
      return;
    }
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = async ({ fileList: newFileList }) => {
    // Check if files were added or removed
    const filesWereAdded = newFileList.length > previousFileListRef.current.length;
    setFileList(newFileList);
    previousFileListRef.current = newFileList;

    // Only proceed with upload logic if files were added
    if (filesWereAdded) {
      // Perform upload logic here if needed
    } else {
    }

    const jpgOrPngToastId = "jpgOrPngError";
    const sizeToastId = "sizeError";

    if (toast.isActive(jpgOrPngToastId)) {
      toast.dismiss(jpgOrPngToastId);
    }
    if (toast.isActive(sizeToastId)) {
      toast.dismiss(sizeToastId);
    }

    const filteredFiles = newFileList.filter((file) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      const isLessThan10MB = file.size / 1024 / 1024 < 10;

      if (!isJpgOrPng) {
        toast.error("You can only upload JPG/PNG files!", {
          toastId: jpgOrPngToastId,
        });
      } else if (!isLessThan10MB) {
        toast.error("Image must be smaller than 10MB!", {
          toastId: sizeToastId,
        });
      }

      return isJpgOrPng && isLessThan10MB;
    });

    setFileList(filteredFiles);
    const filesToUpload = filteredFiles.filter(
      (file) => !file.s3Uploaded && file.originFileObj && !file.error
    );

    if (filesToUpload.length > 0) {
      const s3Urls = await uploadFiles(
        filesToUpload.map((file) => file.originFileObj),
        token
      );

      if (s3Urls && s3Urls.length > 0 && filteredFiles.length === newFileList.length) {
        onS3UrlsChange(s3Urls);
        toast.success("Images uploaded successfully");
      } else if (filteredFiles.length !== newFileList.length) {
        // Don't show success toast if there were invalid files
      } else {
        toast.error("Error uploading images");
      }
    }
  };

  const handleRemove = (file) => {
    const newFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(newFileList);
    // previousFileListRef.current = newFileList;
    toast.success("Image deleted successfully!");
    return true;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Upload
        accept=".jpg,.jpeg,.png"
        action=""
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        onRemove={handleRemove}
        multiple={true}
        beforeUpload={() => false}
      >
        {fileList?.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal
        open={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="Preview" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};

export default UploadComponent;