import React from "react";
import { resumeicon } from "../../../../assets/images/images";
import { useSelector } from "react-redux";
import "../JobDetails/JobDetails.css";

const Profile = () => {
  const applicant = useSelector((state) => state?.job?.applicationResult);

  const getResumeFileExtension = (fileName) => {
    const ext = fileName?.split(".").pop().toLowerCase();
    switch (ext) {
      case "doc":
      case "docx":
        return "docx";
      case "pdf":
        return "pdf";
      case "png":
        return "png";
      case "jpg":
      case "jpeg":
        return "jpg";
      default:
        return ext;
    }
  };

  const getFormattedDate = (date) => {
    if (!date) return "";
    const dateObj = new Date(date);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      dateObj
    );
    return formattedDate;
  };

  return (
    <div className="Profile_tab_main_sec">
      <div className="Profile_tab_main_sec_upper">
        <div className="Profile_tab_main_sec_upper_left">
          <h6>Profile: {applicant?.user_full_name}</h6>
          <h5>Job Profile : {applicant?.job_title}</h5>
          <p>Timestamp : {getFormattedDate(applicant?.created_at)}</p>
        </div>
        <div className="Profile_tab_main_sec_upper_right">
          {applicant?.status === "Fit-For-Job" ? (
            <div className="resumePdf">N/A</div>
          ) : (
            <div className="resumePdf">
              <img src={resumeicon} alt="" />
              <a
                className="pdf"
                href={applicant?.resume}
                download={`${
                  applicant?.user_full_name
                }_Resume.${getResumeFileExtension(applicant?.resume)}`}
              >
                {applicant?.user_full_name}_Resume.
                {getResumeFileExtension(applicant?.resume)}
              </a>
            </div>
          )}
        </div>
      </div>
      <div className="Profile_tab_main_sec_below">
        <div className="Profile_tab_main_sec_below_single">
          <label>Email</label>
          <div className="Profile_tab_main_sec_below_single_body">
            <div className="input_box_new">{applicant?.candidate_email}</div>
          </div>
        </div>
        <div className="Profile_tab_main_sec_below_single">
          <label>Phone</label>
          <div className="Profile_tab_main_sec_below_single_body">
            <div className="input_box_new">{applicant?.candidate_phone}</div>
          </div>
        </div>
        <div className="Profile_tab_main_sec_below_single">
          <label>Annual Salary </label>
          <div className="Profile_tab_main_sec_below_single_body">
            <div className="select_box_reu">{applicant?.currency}</div>
            <input
              type="text"
              placeholder="Enter Annual Salary"
              className="input_box_new"
              value={applicant?.current_ctc}
              readOnly
            />
          </div>
        </div>
        {applicant?.ctc_options ? (
          <div className="Profile_tab_main_sec_below_single">
            <label>CTC Breakdown</label>
            <div className="text_box_sec">{applicant?.current_ctc}</div>
          </div>
        ) : null}
        <div className="Profile_tab_main_sec_below_single">
          <label>Notice Period</label>
          <div className="Profile_tab_main_sec_below_single_body">
            <div className="input_box_new">{applicant?.notice_period}</div>
          </div>
        </div>
        {applicant?.physically_challenged ? (
          <div className="Profile_tab_main_sec_below_single">
            <label>Assistance Benefits</label>
            <div className="input_box_new">
              {applicant?.physically_challenged}
            </div>
          </div>
        ) : null}
        <div className="Profile_tab_main_sec_below_single">
          <label>Location</label>
          <div className="Profile_tab_main_sec_below_single_body">
            <div className="input_box_new">{applicant?.candidate_location}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
