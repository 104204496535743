import React, { useState } from 'react';
import AiAvatarInterviewDuration from './AiAvatarInterviewDuration';
import AiAvatarInterviewQuestion from './AiAvatarInterviewQuestion';
import Allmodals from './Allmodals';
import AiAvatarWrittenTest from './AiAvatarWrittenTest';
import BudgetForJob from './BudgetForJob';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const TotalCreditSecBtm = () => {


  const content = (
    <OwlCarousel className='owl-theme' loop margin={10} nav items={1} dots={false}>
      <div className='item'><h4>Slide 1</h4></div>
      <div className='item'><h4>Slide 2</h4></div>
    </OwlCarousel>
  );

  const [modalData, setModalData] = useState([
    {
      show: false,
      title: "",
      videoSrc: "",
    },
    ]);

  const handleModalClick = (title, videoSrc , content) => {
    setModalData({
      show: true,
      title,
      videoSrc,
    });
  };
  
  const handleCloseModal = () => {
    setModalData({
      show: false,
      title: "",
      videoSrc: "",
    });
  };


  

  return (
    <>
      <div className="recriuter_step_2_inner_btm">
        <AiAvatarInterviewDuration
          handleModalClick={() => handleModalClick("AI Avatar Interview", "/videos/int-vide-sec.mp4")}
        />
        <AiAvatarInterviewQuestion
          handleModalClick={() => handleModalClick("Custom AI Avatar Interview Questions", "/videos/int-vide-sec-new.mp4")}
        />
        <AiAvatarWrittenTest
          handleModalClick={() => handleModalClick("Written Test", )}
        />
        <BudgetForJob />

        {modalData.show && (
          <Allmodals
            show={modalData.show}
            handleClose={handleCloseModal}
            title={modalData.title}
            videoSrc={modalData.videoSrc}
          />
        )}
      </div>
    </>
  );
};

export default TotalCreditSecBtm;
