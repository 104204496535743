export const calculateCreditsForDuration = (durationString) => {
  // Convert duration string to minutes
  const [hours, minutes] = durationString?.split(":").map(Number);
  const totalMinutes = hours * 60 + minutes;

  // Calculate credits based on 15-minute intervals (1 credit per 15 minutes)
  return Math.ceil(totalMinutes / 15);
};

export const calculateGaugePercentage = (allocatedCredits, recommendedInterviews, interviewDuration) => {
  if (!allocatedCredits || !interviewDuration) return 0;

  // Calculate base credits needed based on duration
  const baseCredits = calculateCreditsForDuration(
    interviewDuration
  );

  // Calculate total recommended credits (baseCredits * recommendedInterviews)
  const totalRecommendedCredits = baseCredits * (recommendedInterviews || 1);

  // Calculate the quotient (dividing by 3 for three sections)
  const quotient = totalRecommendedCredits / 3;

  // Define ranges
  const min = quotient;
  const mid = quotient * 2;
  const max = quotient * 3;

  // Calculate percentage based on allocated credits
  if (allocatedCredits <= min) {
    return (allocatedCredits / min) * 0.33;
  } else if (allocatedCredits <= mid) {
    return 0.33 + ((allocatedCredits - min) / (mid - min)) * 0.33;
  } else if (allocatedCredits <= max) {
    return 0.66 + ((allocatedCredits - mid) / (max - mid)) * 0.34;
  } else {
    return 1;
  }
};

// Get feedback message based on gauge percentage
export const getFeedbackMessage = (percentage) => {
  if (percentage >= 0.66) return "High chance of getting a perfect candidate";
  if (percentage >= 0.33)
    return "Moderate chance of getting a perfect candidate";
  return "Low chance of getting a perfect candidate";
};
