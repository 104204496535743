import React, { useState, useRef, useEffect } from "react";
import "../ConfirmInformation/ConfirmInformation.css";

import { Navbar } from "../../../../components/DashboardComponents/Navbar/Navbar";
import DashboardHeader from "../../../../components/CommonComponents/DashboardHeader";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useEnhanceFullResumeSocket from "../../../../hooks/useEnhanceFullResumeSocket";
import { websocketUrl } from "../../../../api/instance";
import { sum_img } from "../../../../assets/images/images";
import LoaderApply from "../../../../components/CommonComponents/Loader/LoaderApply";

import { ThreeCircles } from "react-loader-spinner";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import { updateEnhancedResume } from "../../../../redux/actions/jobSeekerActions";

import { Tooltip } from "antd";
import { getFileTypeFromString } from "../../../../utils/utils";
import { setIsResumeEnahnced } from "../../../../redux/reducers/authReducer";
import FileViewer from "react-file-viewer";

const ResumeEnhancement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector((state) => state.auth?.user?.id);
  const token = useSelector((state) => state.auth.token);
  const userDetail = useSelector((state) => state.auth?.user);
  const userResume = userDetail?.resume;
  const userResumeType = getFileTypeFromString(userResume ?? "");
  const resumeJson = useSelector((state) => state?.auth?.user?.resume_json);
  const [enhancedData, setEnhancedData] = useState(resumeJson ?? {});
  const [isLoading, setIsLoading] = useState(false);

  const url = `${websocketUrl}/jobseeker_full_profile_enhance/${userId}/`;
  const { fullmessages, sendFullMessage, socketLoadingForFullResume } =
    useEnhanceFullResumeSocket(url);

  const handleBack = () => {
    navigate("/resume-builder", { replace: true });
  };
  const [resumEnhanceLoading, setResumEnhanceLoading] = useState(false);

  const enhanceResume = async () => {
    setResumEnhanceLoading(true);
    await sendFullMessage(resumeJson.sections);
    dispatch(setIsResumeEnahnced(true));
  };

  useEffect(() => {
    try {
      if (fullmessages && fullmessages.length > 0) {
        setEnhancedData({ ...enhancedData, sections: fullmessages });
        const updatedPayloadData = {
          ...enhancedData,
          sections: fullmessages,
        };
        dispatch(updateEnhancedResume(token, userId, updatedPayloadData))
          .then(() => {
            setIsLoading(false);
            setResumEnhanceLoading(false);
            navigate("/confirm-information", {
              state: { uploadresume: resumeJson },
            });
          })
          .catch((error) => {
            console.error("Error updating job seeker:", error);
            setIsLoading(false);
          });
      } else {
        console.error("No enhanced_resume key in WebSocket response");
      }
    } catch (error) {
      console.error(
        "Error parsing WebSocket response. Waiting for more chunks...",
        error
      );
    }
  }, [fullmessages]);

  return (
    <>
      <Navbar />
      <section className="confim_info_sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="confim_back_btn"></div>
              <div className="confim_info_sec_inner">
                <DashboardHeader title="" backButtonLink={handleBack} />
                <div className="confim_info_sec_inner_head">
                  <h6>
                    Would you like to continue with your resume or enhance it?
                  </h6>
                </div>
                <div className="confim_info_sec_inner_body">
                  {userResume &&
                    (userResumeType.includes("image/") ? (
                      <img
                        src={userResume}
                        alt="Preview"
                        style={{ width: "100%", height: "auto" }}
                      />
                    ) : userResumeType ===
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                      <>
                        <div style={{ width: "100%", height: "80vh" }}>
                          <FileViewer filePath={userResume} fileType={"docx"} />
                        </div>
                      </>
                    ) : userResumeType === "application/pdf" ? (
                      <div
                        style={{
                          width: "100%",
                          height: "80vh",
                          overflow: "auto",
                        }}
                      >
                        <FileViewer filePath={userResume} fileType={"pdf"} />
                      </div>
                    ) : (
                      <div>Unsupported file format.</div>
                    ))}
                  <div className="login_btn_blue_new confim_info_sec_inner_btns">
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <Link className="enhance" onClick={enhanceResume}>
                        {isLoading ? (
                          <span
                            style={{
                              display: "inline-block",
                              padding: "0px 12px", // Adjust padding as needed
                              position: "relative",
                            }}
                          >
                            <LoaderApply color="#007BFF !important" />
                          </span>
                        ) : (
                          <>
                            <img src={sum_img} alt="" /> Enhance Resume with AI
                          </>
                        )}
                      </Link>
                      <br />
                      <br />
                      <Tooltip
                        color="#464646"
                        title={`Click "Enhance Resume with AI" to improve and update the content of your existing resume seamlessly.`}
                      >
                        <FontAwesomeIcon icon={faCircleInfo} />
                      </Tooltip>
                    </div>

                    <div
                      style={{
                        width: "35%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Link
                        className="continue"
                        disabled={isLoading}
                        to={"/confirm-information"}
                      >
                        {isLoading ? (
                          <span
                            style={{
                              display: "inline-block",
                              padding: "0px 12px", // Adjust padding as needed
                              position: "relative",
                            }}
                          >
                            <LoaderApply color="#007BFF !important" />
                          </span>
                        ) : (
                          "Continue"
                        )}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {socketLoadingForFullResume && (
        <Modal
          show={resumEnhanceLoading}
          onHide={() => setResumEnhanceLoading(false)}
          className="summary_sec_new"
        >
          <div className="summary_sec_new_inner">
            <div className="summary_sec_new_inner_cont">
              <p style={{ textAlign: "center" }}>Enhancing...</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  // top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <ThreeCircles
                  visible={true}
                  height="20"
                  width="20"
                  color="#1865c1"
                  ariaLabel="loading-indicator"
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ResumeEnhancement;