import { Typography } from "@mui/material";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const ResultsCountdown = ({ renderTime, onUpdate, onComplete }) => {
  return (
    <section className="get_ready_panel_sec">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="get_ready_panel_sec_inner">
            <Typography variant="span" color={"#0B4A96"} fontWeight={700} fontSize={28}>
                Your AI Avatar interview will start in
                </Typography>
              <div className="get_ready_panel_sec_inner_box">
                <div className="timer-wrapper">
                  <CountdownCircleTimer
                    isPlaying
                    size={240}
                    duration={3}
                    colors={["#0B4A96", "#0B4A96", "#0B4A96", "#0B4A96"]}
                    colorsTime={[10, 6, 3, 0]}
                    onUpdate={onUpdate}
                    onComplete={onComplete}
                    strokeWidth={7}
                  >
                    {renderTime}
                  </CountdownCircleTimer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResultsCountdown;
