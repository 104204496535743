import { Modal } from "antd";
import InterviewModuleNav from "../InterviewModuleNav";
import { hairoAvatar } from "../../../../../assets/images/images";

const ScreenSharePopup = ({ navData, isModalVisible, enableScreenShare }) => {
  return (
    <>
      <InterviewModuleNav data={navData} />
      <section className="int_panel_body_sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="int_panel_body_sec_inner">
                <img
                  src={hairoAvatar}
                  alt=""
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        open={isModalVisible}
        backdrop="static"
        keyboard={false}
        className="allow_micro_phone_pop"
        closable={false}
        width={800}
        centered
        footer={null}
      >
        <div className="InterviewModal_main">
          <div className="InterviewModal_main_inner">
            <div className="test_speaker">
              <h2>Allow Screen Recording</h2>
              <p className="Transcript_box" style={{ marginTop: "40px" }}>
                Please allow screen sharing to proceed with the interview
                smoothly.
                <br />
                <b> Make sure you allow your entire screen</b>
              </p>
            </div>
          </div>
          <div className="space-bt">
            <button className="blue_bg_btn" onClick={enableScreenShare}>
              Allow Screen Recording
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ScreenSharePopup;
