import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Hambuger_menubar, haire_gif_new } from "../../assets/images/images";
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";

const HomeHeader = () => {
  const subscriptions = useSelector((state) => state.subscriptions);
  const navigate = useNavigate();
  const isDesktop = useMediaQuery("(min-width:832px)");
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.body.classList.add("menu-open");
    } else {
      document.body.classList.remove("menu-open");
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    navigate("/user-role");
  };

  const handleDemoClick = (event) => {
    event.preventDefault();
    window.open(subscriptions?.zoho_link, '_blank'); 
  };

  const menuItems = (
    <ul>
      <li>
        <button className="sign_up_btn_new after_mobile" onClick={handleClick}>
          Sign Up
        </button>
      </li>
      <li className="res-book-demo">
        <Link
          className="sign_up_btn_new after_mobile"
          onClick={handleDemoClick}
          style={{
            border: "2px solid #1865c1",
            background: "#fff",
            color: "#020202",
            marginRight: "10px",
          }}
        >
          Book a Demo
        </Link>
      </li>
      <li>
        <a href="/about-us">About Us</a>
      </li>
      <li>
        <a href="/features">Features</a>
      </li>
      {/* {window.innerWidth > 767 && (
        <> */}
      <li>
        <a href="/jobs">Jobs</a>
      </li>
      {/* </>
      )} */}
      <li>
        <NavLink to="/Pricing">Pricing</NavLink>
      </li>
      <li>
        <a href="/contact-us">Contact Us</a>
      </li>
    </ul>
  );

  // const [activeLink, setActiveLink] = useState(null);

  // const handleLinkClick = (index) => {
  //     setActiveLink(index);
  // };

  return (
    <>
      {/* Navigation Section */}
      <header className="head_sec_pro_rect">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="head_sec_pro_rect_inner">
                <div className="head_sec_pro_rect_inner_logo">
                  <Link to="/">
                    <img src={haire_gif_new} alt="" />
                  </Link>
                </div>
                <div className="head_sec_pro_rect_inner_menu">
                  <div className="navbar">
                    <ul>
                      <li>
                        <NavLink to="/about-us">About Us</NavLink>
                      </li>
                      <li>
                        <NavLink to="/features">Features</NavLink>
                      </li>
                      {/* <li>
                        <NavLink to="/jobs">Jobs</NavLink>
                      </li> */}
                      <li>
                        <NavLink to="/Pricing">Pricing</NavLink>
                      </li>
                      <li>
                        <NavLink to="/contact-us">Contact Us</NavLink>
                      </li>
                    </ul>
                  </div>
                  {isDesktop ? (
                    <div>
                      <Link
                        className="sign_up_btn_new after_mobile"
                        onClick={handleDemoClick}
                        style={{
                          border: "2px solid #1865c1",
                          background: "#fff",
                          color: "#020202",
                          marginRight: "10px",
                        }}
                      >
                        Book a Demo
                      </Link>
                      <button
                        className="sign_up_btn_new after_mobile"
                        onClick={handleClick}
                      >
                        Sign up / Login
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}

                  {/* <div className="Book-demo">
                    <button class="Book_demo_btn" onClick={handleDemoClick}>
                      Book a Demo
                    </button>
                    <button
                      className="sign_up_btn_new after_mobile gradient-button"
                      onClick={handleClick}
                    >
                      Sign up / Login
                    </button>
                  </div> */}
                  <div className="d-flex">
                    <Link
                      className="sign_up_btn_new after_mobile responsive-book-btn book-btn-color"
                      onClick={handleDemoClick}
                      style={{
                        border: "2px solid #1865c1",
                        background: "#fff",
                        color: "#020202",
                        marginRight: "10px",
                      }}
                    >
                      Book a Demo
                    </Link>
                    <button onClick={toggleMenu} className="hammenu">
                      <img src={Hambuger_menubar} alt="" />
                    </button>
                  </div>
                </div>
                {isOpen && (
                  <div className="responsive_menu">
                    {/* Replace with your actual menu items */}
                    <button onClick={toggleMenu} className="clos_icn_menu">
                      <i className="fas fa-times"></i>
                    </button>
                    {menuItems}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default HomeHeader;
