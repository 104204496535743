import React from 'react'
import { Modal } from 'react-bootstrap'


const Allmodals = ({ show = false, handleClose , title = "", videoSrc = "" , content}) => {
  return (
    <div>
       <Modal show={show} onHide={handleClose} className='modal_main_sec'>
          <button onClick={handleClose} className='modal_close_btn'>
            <i class="fas fa-times"></i>
          </button>
          <div className='modal_main_sec_inner'>
            <h6>{title} </h6>
            <video width="100%" height="100%" controls autoPlay>
              <source src={videoSrc} type="video/mp4" />
            </video>
            <div>{content}</div>
          </div>
      </Modal>
    </div>
  )
}

export default Allmodals