import React, {useEffect, useState} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck , faMicrophone , faMicrophoneSlash } from "@fortawesome/free-solid-svg-icons";
import useAudioRecorder from "../../../../hooks/useAudioRecorder";
import useTranscriptionStore from "../../../../zustand/speechToTextStore";
import { useSelector } from "react-redux";
import "../InterviewModule/InterviewModule.css";

import {
    mic_check,microphone_off_icon,wave_gif
  } from "../../../../assets/images/images";
import { random } from 'lodash';
const MicrophoneCheck = (props) => {

    const [testStatus, setTestStatus] = useState(false);

    const startMicroPhoneTest = () => {
        setTestStatus(true);
    }

    const token = useSelector((state) => state?.auth?.token);
    const [testStarted, setTestStarted] = useState(false);
    const [hasReceivedInput, setHasReceivedInput] = useState(false);
    const [isTroubleshoot, setIsTroubleshoot] = useState(false);

    const { startRecording, stopRecording, isRecording, isSpeaking, audioBlob, error: recorderError } = useAudioRecorder();
    const { transcript, isLoading, error, transcribeAudio, clearTranscript } = useTranscriptionStore();

    useEffect(() => {
        clearTranscript();
        setHasReceivedInput(false);
        setTestStarted(false);
    }, [clearTranscript]);

    const handleButtonClick = () => {
        if (isRecording) {
            stopRecording();
        } else {
            clearTranscript();
            setHasReceivedInput(false);
            startRecording();
            setTestStarted(true);
        }
    };

    const troubleShoot = () => {
        console.log("retryTest");
        setTestStatus(false);
        setTestStarted(false);
        setHasReceivedInput(false);
        setIsTroubleshoot(false);
        props.permissionResultRef.current.microphone = false;
        props.setPermissionResult(random(1, 100));
    }
    const retryTest = () => {
        console.log("retryTest");
        setTestStatus(false);
        setTestStarted(false);
        setHasReceivedInput(false);
        setIsTroubleshoot(false);
        props.permissionResultRef.current.microphone = false;
        props.setPermissionResult(random(1, 100));
        startMicroPhoneTest();
    }

    useEffect(() => {
        if (isSpeaking) {
          clearTranscript();
        } else if (audioBlob) {
            const reader = new FileReader();
            reader.readAsDataURL(audioBlob);
            reader.onloadend = async () => {
                const base64data = reader.result?.toString().split(",")[1];
                if (base64data) {
                    try {
                        const newTrans = await transcribeAudio(base64data, token);
                        setHasReceivedInput(true);
                        stopRecording();
                        if(newTrans) {
                            props.permissionResultRef.current.microphone = true;
                            props.setPermissionResult(random(1, 100));
                            console.log("New Transcript", props.permissionResultRef.current);
                        } else {
                            setIsTroubleshoot(true);
                            props.permissionResultRef.current.microphone = false;
                            props.setPermissionResult(random(1, 100));
                            console.log("No Transcript", props.permissionResultRef.current);
                        }
                    } catch (error) {
                        console.error("Error during audio transcription:", error);
                        setIsTroubleshoot(true);
                        props.permissionResultRef.current.microphone = false;
                        props.setPermissionResult(random(1, 100));
                    }
                }
            };
            reader.onerror = () => {
                console.log("Microphone error", reader.error);
                setIsTroubleshoot(true);
                props.permissionResultRef.current.microphone = false;
                props.setPermissionResult(random(1, 100));
            };
        }
    }, [isSpeaking, audioBlob, transcribeAudio, clearTranscript, token]);

    
    useEffect(() => {
        return () => {
            stopRecording();
        };
    }, [])

    useEffect(() => {
        if (recorderError) {
            console.error("Error accessing the microphone", recorderError);
        }
    }, [recorderError]);
    
    

  return (
    <>
        <div className="modal_permission_sec_inner_body_right_single">
            <div className="modal_permission_sec_inner_body_right_single_sec">
                <div className="modal_permission_sec_inner_body_right_single_sec_head">
                    <div className="head_single_left_sec">
                        <img src={mic_check} alt="" />
                        <h5>Microphone check</h5>
                    </div>
                    {
                        testStarted===true && isRecording ? (
                            <div className="head_single_right_sec">
                                <h3>Listening</h3>
                            </div>
                        ) : testStarted===true && isRecording===false && hasReceivedInput===true ? (
                            <div className="head_single_right_sec">
                                {/* <h3>Not Listening</h3> */}
                            </div>
                        ) : null
                    }
                </div>
                <div className="microphone_check_sec">
                    {testStarted===true && isRecording===true ? <img src={wave_gif} alt="" /> : <img src={microphone_off_icon} alt="" />}
                    {
                        testStatus===false ?
                            <input type="text" placeholder="Click the 'Test' button, then speak to test the microphone" />
                        : testStatus===true && testStarted===false && isRecording===false ? 
                            <>
                                <input type="text" placeholder="Click on microphone and start speaking." />
                                <button type="button" className="speak_sec" onClick={handleButtonClick}>
                                    <FontAwesomeIcon icon={faMicrophoneSlash} />
                                </button>
                            </>
                        : testStarted===true && isRecording===true ? 
                            <>
                                <input type="text" placeholder="Click on microphone and stop speaking." />
                                <button type="button" className="speak_sec" onClick={handleButtonClick}>
                                    <FontAwesomeIcon icon={faMicrophone} />

                                </button>
                            </>
                        : testStarted===true && isRecording===false && hasReceivedInput===true && transcript!=="" ?
                            <>
                                <input type="text" placeholder={transcript} readOnly value={transcript} />
                            </> 
                        : isRecording===false && transcript==="" && isLoading===true ?
                            <>
                                <input type="text" placeholder="Processing..." />
                            </> 
                        : <div>            
                                <p style={{ color: "red", fontWeight: "bold", marginTop: "10px", margin: "15px" , textAlign: "center"}}>
                                    {recorderError}
                                </p>
                            </div>
                    }
                </div>
            </div>
            <div className="right_test_btn">
                {
                    testStatus===false &&
                    <button type="button" className="test_btn_check" onClick={startMicroPhoneTest}>Test</button>
                }
                {
                    isTroubleshoot && !transcript &&
                    <button type="button" className="test_btn_check troubleshoot" onClick={troubleShoot}>Troubleshoot</button>
                }
                {
                    testStarted===true && isRecording===false && hasReceivedInput===true && transcript!=="" &&
                    <>
                        <button type="button" className="test_btn_check" onClick={retryTest}>Retry</button>
                        <span className="tested_class">Tested <FontAwesomeIcon icon={faCheck} /></span>
                    </>
                }
            </div>
        </div>
    </>
  )
}

export default MicrophoneCheck