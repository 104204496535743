import { create } from 'zustand';
// @ts-ignore
import instance from "../api/instance";
import { getSupportedMimeType } from './screenRecordStore';
import axios, { AxiosError } from 'axios';

// Worker store implementation
interface UploadWorkerState {
  worker: Worker | null;
  uploadId: string | null;
  isRecording: boolean;
  currentPart: number;
  
  initWorker: () => void;
  startRecordingSession: (token: string, interviewId: string) => Promise<void>;
  uploadChunk: (token: string, chunk: Blob, interviewId: string) => void;
  stopRecordingSession: (token: string, interviewId: string) => Promise<void>;
}

// Create the store
const screenRecordingSessionStore = create<UploadWorkerState>((set, get) => ({
  worker: null,
  uploadId: null,
  isRecording: false,
  currentPart: 1,
  
  initWorker: () => {
    // Create worker if it doesn't exist
    if (!get().worker) {
      // Declare worker type to fix ESLint errors
      const worker = new Worker(new URL('../../src/workers/upload-worker.ts', import.meta.url), { type: 'module' });
      
      // Handle worker messages
      worker.onmessage = (event) => {
        const { type, error, remainingTasks } = event.data;
        
        if (type === 'error') {
          console.error('[Main] Worker error:', error);
        } else if (type === 'success') {
          console.log('[Main] Task completed, remaining tasks:', remainingTasks);
        }
      };
      
      set({ worker });
      console.log('[Main] Upload worker initialized');
    }
  },
  
  startRecordingSession: async (token: string, interviewId: string) => {
    try {
      // Initialize worker if needed
      get().initWorker();
      
      // Get supported file type
      const { fileExtension } = getSupportedMimeType();
      
      // Create form data for initiating multipart upload
      const formData = new FormData();
      formData.append('file_type', fileExtension);
      
      // Make API call to initiate multipart upload using axios instance
      const response = await instance.post(
        `/interviews/initiate-multipart-upload/${interviewId}/`, 
        formData,
        {
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      
      // Extract upload ID from response
      const uploadId = response.data.upload_id;
      
      // Update state with new upload ID
      set({ 
        uploadId, 
        isRecording: true, 
        currentPart: 1 
      });
      
    } catch (error) {
      // Handle axios errors
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        console.error('[Main] API Error starting recording session:', {
          status: axiosError.response?.status,
          data: axiosError.response?.data,
          message: axiosError.message
        });
      } else {
        console.error('[Main] Error starting recording session:', error);
      }
      throw error;
    }
  },
  
  uploadChunk: (token: string, chunk: Blob, interviewId: string) => {
    const { worker, uploadId, isRecording } = get();
    
    if (!worker || !uploadId || !isRecording) {
      console.warn('[Main] Cannot upload chunk: worker or uploadId not initialized');
      return;
    }

    const { fileExtension } = getSupportedMimeType();
    
    // Send chunk to worker for processing
    worker.postMessage({
      type: 'upload',
      chunk,
      uploadId,
      interviewId,
      partNumber: get().currentPart,
      token,
      fileType: fileExtension
    });
  },
  
  stopRecordingSession: async (token: string, interviewId: string) => {
    const { worker, uploadId, isRecording } = get();
    
    if (!worker || !uploadId || !isRecording) {
      console.warn('[Main] Cannot stop recording: worker or uploadId not initialized');
      return;
    }
    
    try {
      const { fileExtension } = getSupportedMimeType();
      
      // Send complete message to worker
      worker.postMessage({
        type: 'complete',
        uploadId,
        interviewId,
        token,
        fileType: fileExtension
      });
      
      // Reset state
      set({ 
        isRecording: false, 
        uploadId: null, 
        currentPart: 1 
      });
      
    } catch (error) {
      // Handle axios errors
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        console.error('[Main] API Error stopping recording session:', {
          status: axiosError.response?.status,
          data: axiosError.response?.data,
          message: axiosError.message
        });
      } else {
        console.error('[Main] Error stopping recording session:', error);
      }
      throw error;
    }
  }
}));

export default screenRecordingSessionStore;