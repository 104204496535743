import { Modal } from "react-bootstrap";
import { linkedin, mail_icn, mail_icn_new, wp_icn } from "../../../../assets/images/images";
import { toast } from "react-toastify";

const ShareJobModal = ({show,onClose,slug}) => {
  return (
    <Modal
      show={show}
      onHide={onClose}
      className="share_prof_sec_mod"
      size="xl"
    >
      <div className="share_prof_sec_mod_inner">
        <button className="close_icn_new_sh" onClick={onClose}>
          <i className="fas fa-times"></i>
        </button>
        <h6>Share</h6>
        <ul>
        <li>
            <a
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                `${window.location.origin}/jobs/${slug}`
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} alt="linkedin" />
            </a>
          </li>
          <li>
            <a
              href={`https://api.whatsapp.com/send?text=Check out this job! ${encodeURIComponent(
                `${window.location.origin}/jobs/${slug}`
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={wp_icn} alt="whatsapp" />
            </a>
          </li>
          <li>
            <a
              href={`mailto:?subject=Check out this job!&body=Check out this job at ${encodeURIComponent(
                `${window.location.origin}/jobs/${slug}`
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={mail_icn_new} alt="gmail" />
            </a>
          </li>
          
        </ul>
        <div className="copy_link_sec">
          <label>Or copy link</label>
          <div className="copy_link_sec_input">
            <input
              type="text"
              readOnly
              value={
                `${window.location.origin}/jobs/${slug}` ||
                "example.com/share-link"
              }
            />
            <button
              onClick={() => {
                navigator.clipboard
                  .writeText(
                    `${window.location.origin}/jobs/${slug}` ||
                      "example.com/share-link"
                  )
                  .then(
                    () => {
                      toast.success("Link copied to clipboard!");
                    },
                    () => {
                      toast.error("Failed to copy the link.");
                    }
                  );
              }}
            >
              Copy
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShareJobModal;
