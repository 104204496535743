import React from "react";
import styles from "./tooltip.module.css";

const TooltipVersion1 = ({ text }) => {
  return (
    <div className={styles.tooltipContainer}>
      <div className={styles.tooltipBox}>
        {text}
        <div className={styles.tooltipArrow}></div>
      </div>
    </div>
  );
};

export default TooltipVersion1;