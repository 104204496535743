import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Form } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateJobDetails } from "../../../../redux/reducers/jobReducer";
import InterviewDuration from "./form_q_components/InterviewDuration";
import Allmodals from "./form_q_components/Allmodals";
import CreditInput from "./form_q_components/CreditInput";
import InterviewQuestion from "./form_q_components/InterviewQuestions";
import WrittenTestQuestions from "./form_q_components/WrittenTestQuestions";
import TotalCreditSec from "../../../../components/RecruiterWrittenAssessment/TotalCreditSec";

const Step2New = ({ form }) => {
  const dispatch = useDispatch();
  const jobDetails = useSelector((state) => state.job.details);
  const [modalData, setModalData] = useState([
    {
      show: false,
      title: "",
      videoSrc: "",
    },
  ]);

  const handleModalClick = (title, videoSrc, content) => {
    setModalData({
      show: true,
      title,
      videoSrc,
    });
  };

  const handleCloseModal = () => {
    setModalData({
      show: false,
      title: "",
      videoSrc: "",
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="main_both_class">
        <TotalCreditSec />
        <section className="form_sec_new_inner_first recriuter_step_2_inner_btm">
          <Form
            form={form}
            onValuesChange={(changedValues, allValues) => {
              dispatch(updateJobDetails(allValues));
            }}
          >
            <InterviewDuration
              form={form}
              handleModalClick={() =>
                handleModalClick(
                  "AI Avatar Interview",
                  "/videos/int-vide-sec.mp4"
                )
              }
            />
            {/* Interview Questions Component */}
            <Form.Item
              name="custom_questions"
              rules={[
                {
                  required: jobDetails?.is_active_custom_questions ? true : false,
                  message: "Please add custom questions",
                },
              ]}
            >
              <InterviewQuestion
                form={form}
                handleModalClick={() =>
                  handleModalClick(
                    "AI Avatar Interview",
                    "/videos/int-vide-sec-new.mp4"
                  )
                }
              />
            </Form.Item>
            {/* Interview Questions Component */}
            <Form.Item
              name="assignment"
              rules={[
                {
                  required: jobDetails?.is_active_written_assessment ? true : false,
                  message: "Please create written test",
                },
              ]}
            >
              <WrittenTestQuestions form={form} />
            </Form.Item>
            {/* Interview Questions Component */}
            <Form.Item
              name="credits_alloted"
              rules={[{ required: true, message: "Credits required" }]}
            >
              <CreditInput form={form} />
            </Form.Item>
          </Form>
        </section>
      </div>
      {modalData.show && (
        <Allmodals
          show={modalData.show}
          handleClose={handleCloseModal}
          title={modalData.title}
          videoSrc={modalData.videoSrc}
        />
      )}
    </LocalizationProvider>
  );
};

export default Step2New;
