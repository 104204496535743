import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import "../../pages/SignIn/signin.css";
import { signInWithGoogle } from "../../redux/actions/authActions";
import { useNavigate } from "react-router-dom";
import { Google_img } from "../../assets/images/images";
//PRA IMPORT
import loginCodeStore from "../../zustand/loginCodeStore";
import userStore from "../../zustand/userStore";
//PRA IMPORT
const GoogleInUp = (props) => {
  const redirectUrl = useSelector((state) => state.auth.redirectUrl);
  const { role } = userStore();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //TO CLOSE THE MODAL AS USER SELECT THE GOOLGE LOGIN
  const { initialCloseModal } = loginCodeStore();

  const loginWithGoogle = async (e) => {
    e.preventDefault();
    dispatch(
      signInWithGoogle(navigate, redirectUrl, props.jobSeekerRole ?? role)
    );
    initialCloseModal();
  };

  return (
    <>
      <button onClick={loginWithGoogle} className="signup_google_sec">
        <ReactSVG src={Google_img} disabled={props?.disabled} />
        {props.forContent}
      </button>
    </>
  );
};

export default GoogleInUp;
