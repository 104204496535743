import React, { useEffect, useRef, useState } from "react";
import InterviewModuleNav from "./InterviewModuleNav";
import "./InterviewModule.css";
import interviewGuidelinesData from "../../../../constants/AiinterviewGuidelineData";
import LoaderApply from "../../../../components/CommonComponents/Loader/LoaderApply";
import { Link, useLocation, useParams } from "react-router-dom";

import Modal from "react-bootstrap/Modal";

import TestInternetConnection from "./TestInternetConnection";
import MicrophoneCheck from "./MicrophoneCheck";
import SpeakerCheck from "./SpeakerCheck";
import CameraAccess from "./CameraAccess";
import useInternetStatus from "../../../../hooks/useInternateStatus";

const AiInterviewGuideLine = () => {
  const { jobId, jobname, companyName, interviewId, assignment_id } = useParams();
  const location = useLocation();
  // Accessing the state data
  const routeStateData = location.state;
  const isOnline = useInternetStatus();
  const decodedJobTitle = decodeURIComponent(jobname);
  const decodedCompanyName = decodeURIComponent(companyName);
  const navData = {
    jobname: decodedJobTitle,
    CompanyName: decodedCompanyName,
  };
  const [permissionResult, setPermissionResult] = useState("");
  let permissionResultRef = useRef({
    camera: false,
    internet: false,
    microphone: false,
    speaker: false,
  });

  const [loading, setLoading] = useState(false);

  const [modshow, setModShow] = useState(false);
  const handleCloseMod = () => {
    setLoading(false);
    setModShow(false);
    permissionResultRef.current = {
      camera: false,
      internet: false,
      microphone: false,
      speaker: false,
    };
  };
  const handleShowMod = () => {
    setLoading(true);
    setModShow(true);
  };

  return (
    <>
      <InterviewModuleNav data={navData} />
      <section className="int_panel_body_sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="ai_inter_guide_sec">
                <div className="ai_inter_guide_sec_head">
                  <h6>AI interview Guidelines</h6>
                </div>
                <div className="ai_inter_guide_sec_body">
                  {interviewGuidelinesData.map((guideline, index) => (
                    <div className="ai_inter_guide_sec_body_single" key={index}>
                      <h5>{guideline.title}</h5>
                      {guideline.sections.map((section, idx) => (
                        <div
                          className="ai_inter_guide_sec_body_single_inner"
                          key={idx}
                        >
                          <h4>{section.heading}</h4>
                          <ul>
                            {section.list.map((item, i) => (
                              <li key={i}>{item}</li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="practice_ai_ftr_sec">
        <div className="practice_ai_ftr_sec_inner">
          <button className="common_blue_btn" onClick={handleShowMod}>
            {loading ? <LoaderApply /> : "Proceed AI Avatar Interview"}
          </button>
          {/* <p>
            Note: This is a practice interview to explore the process and
            enhance your understanding.
          </p> */}
        </div>
      </section>
      <Modal
        show={modshow}
        onHide={handleCloseMod}
        className="modal_permission_sec"
        backdrop="static"
      >
        <div className="modal_permission_sec_inner">
          <div className="modal_permission_sec_inner_head">
            <h6>System Setup and Test</h6>
          </div>
          <div className="modal_permission_sec_inner_body">
            <CameraAccess
              permissionResultRef={permissionResultRef}
            />
            <div className="modal_permission_sec_inner_body_right">
              <TestInternetConnection
                permissionResultRef={permissionResultRef}
                setPermissionResult={setPermissionResult}
              />
              <MicrophoneCheck
                permissionResultRef={permissionResultRef}
                setPermissionResult={setPermissionResult}
              />
              <SpeakerCheck
                permissionResultRef={permissionResultRef}
                setPermissionResult={setPermissionResult}
              />
            </div>
          </div>
          <div className="modal_btn_sec">
            {permissionResult &&
            permissionResultRef.current.camera &&
            permissionResultRef.current.internet &&
            permissionResultRef.current.microphone &&
            permissionResultRef.current.speaker &&
            isOnline ? (
              <Link
                className="mod_btn_blue"
                to={`/applicant-dashboard/related-jobs/${jobId}/start-interview/${interviewId}/ai-interview-tour/${jobname}/${companyName}/${assignment_id}`}
                state={routeStateData}
              >
                Proceed to interview
              </Link>
            ) : (
              <Link className="mod_btn_blue disa_btn" disabled>
                Proceed to interview
              </Link>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AiInterviewGuideLine;
