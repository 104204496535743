import React from 'react'
import { Link } from 'react-router-dom'

const TotalCreditBtns = () => {
  return (
    <>
      <div className='save_enter_btn_main'>
            <Link to="#" className='draft_btn'>Save as draft</Link>
            <Link to="#" className='save_btn'>Save & Continue</Link>
      </div>
    </>
  )
}

export default TotalCreditBtns
