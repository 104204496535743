import { combineReducers, Action } from "redux";
import otpVerificationReducer from "./otpVerificationsReducer";
import companyReducer from "./companyReducer";
import jobReducer from "./jobReducer";
import dashboardCountReducer from "./dashboardCountReducer";
import authReducer from "./authReducer";
import locationReducer from "./locationReducer";
import { RESET_APP } from "../actions/resetActions";
import subscriptionReducer from "./subscriptionReducer";
import paymentsReducer from "./paymentsReducer";
import creditsReducer from "./creditsReducer";
import quizSlice from "./practiceAssignmentReducer";
const appReducer = combineReducers({
  auth: authReducer,
  otpVerification: otpVerificationReducer,
  company: companyReducer,
  job: jobReducer,
  count: dashboardCountReducer,
  location: locationReducer,
  subscriptions: subscriptionReducer,
  payments: paymentsReducer,
  credits: creditsReducer,
  quizess: quizSlice,
});

// RootState type can be used to type the state parameter
export type RootState = ReturnType<typeof appReducer>;

// Type the action parameter using Redux's Action type, optionally extending it with any custom action types you have
const rootReducer = (state: RootState | undefined, action: Action<string>) => {
  if (action.type === RESET_APP) {
    state = undefined; // Reset the state to undefined, forcing reducers to return their initial state
  }

  return appReducer(state, action);
};
export default rootReducer;
