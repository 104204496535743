import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateJobDetails } from "../../../../../redux/reducers/jobReducer";
import { toast } from "react-toastify";

const AiAvatarInterviewQuestion = ({ form, handleModalClick }) => {
  const dispatch = useDispatch();
  const jobDetails = useSelector((state) => state.job.details);
  
  // Questions is an array of strings.
  const [questions, setQuestions] = useState(jobDetails?.custom_questions || []);
  // newQuestion holds the text for adding a new question (the bottom text area)
  const [newQuestion, setNewQuestion] = useState("");
  // editing holds the inline editing values for individual questions.
  // When a question is in "edit mode" its index is used as a key.
  const [editing, setEditing] = useState({});

  const [modalVisible, setModalVisible] = useState(false);

  // const is_active_custom_questions = jobDetails?.is_active_custom_questions;

  // Open modal if custom questions are enabled
  const openModal = () => {
    // if (!is_active_custom_questions) {
    //   toast.info(
    //     "Please enable Custom AI Avatar Interview Questions before adding."
    //   );
    //   return;
    // }
    setModalVisible(true);
  };

  // Close modal and reset states
  const closeModal = () => {
    setModalVisible(false);
    setNewQuestion("");
    setEditing({});
  };

  const cancelAddingQuestions = () => {
    closeModal();
    // Reset to the initial state from jobDetails
    setQuestions(jobDetails?.custom_questions || []);
  };

  // Save the current questions list to Redux and close the modal
  const onClickAddQuestion = () => {
    form.setFieldsValue({ is_active_custom_questions: questions.length > 0 });
    dispatch(
      updateJobDetails({
        ...jobDetails,
        custom_questions: questions,
        is_active_custom_questions: questions.length > 0,
      })
    );
    closeModal();
  };

  // ---------------- Inline Editing Handlers ----------------

  // When the edit icon is clicked for a specific question,
  // initialize that row’s editing value with the current question text.
  const startEditing = (index) => {
    setEditing((prev) => ({ ...prev, [index]: questions[index] }));
  };

  // Update the inline editing value for a given question.
  const handleEditingChange = (index, value) => {
    setEditing((prev) => ({ ...prev, [index]: value }));
  };

  // Save the inline edit by updating the questions list.
  const saveEdit = (index) => {
    if (!editing[index].trim()) return; // avoid empty question updates
    const updatedQuestions = [...questions];
    updatedQuestions[index] = editing[index];
    setQuestions(updatedQuestions);
    // Remove the editing value for that index
    setEditing((prev) => {
      const newEditing = { ...prev };
      delete newEditing[index];
      return newEditing;
    });
    form.setFieldsValue({ custom_questions: updatedQuestions });
  };

  // Cancel inline editing for a given question
  const cancelEdit = (index) => {
    setEditing((prev) => {
      const newEditing = { ...prev };
      delete newEditing[index];
      return newEditing;
    });
  };

  // ---------------- Delete Handler ----------------

  const handleDelete = (index) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
    form.setFieldsValue({ custom_questions: updatedQuestions });
  };

  // ---------------- Toggle Custom Questions ----------------

  const handleCheck = (e) => {
    form.setFieldsValue({ is_active_custom_questions: e.target.checked });
    dispatch(
      updateJobDetails({
        ...jobDetails,
        is_active_custom_questions: e.target.checked,
      })
    );
  };

  // ---------------- New Question (Add) Handler ----------------

  const handleAddNewQuestion = () => {
    if (!newQuestion.trim() || questions.length >= 5) return;
    const updatedQuestions = [newQuestion, ...questions];
    setQuestions(updatedQuestions);
    form.setFieldsValue({ custom_questions: updatedQuestions });
    setNewQuestion("");
  };

  return (
    <>
      {/* Button to open modal */}
      <div className="recriuter_step_2_inner_btm_single">
        <div className="recriuter_step_2_inner_btm_single_top">
          <div className="recriuter_step_2_inner_btm_single_top_left new_left">
            <div className="toggle_sec">
              <label className="switch">
                <input
                  type="checkbox"
                  // value={jobDetails?.is_active_custom_questions}
                  checked={jobDetails?.is_active_custom_questions || false}
                  onChange={handleCheck}
                  disabled={questions.length === 0}
                />
                <span className="slider round"></span>
              </label>
            </div>
            <h5>
              Custom AI Avatar Interview Questions{" "}
              <button onClick={handleModalClick}>
                What are Custom AI Interview Questions?
              </button>
            </h5>
          </div>
          <div className="recriuter_step_2_inner_btm_single_top_right">
            <h6>Free</h6>
          </div>
        </div>
        <div className="recriuter_step_2_inner_btm_single_btm_inner">
          <p>
            Custom questions enable you to make interviews more aligned with your
            hiring preferences. You can add tailored questions that the AI avatar will ask
            the candidate.
          </p>
          <button className="fa_plus_btn" onClick={openModal}>
            <i className="fas fa-plus"></i>{" "}
            {questions.length > 0 ? "Edit" : "Add"}
          </button>
        </div>
        {questions.length > 0 && (
          <div className="ques_add_tag">
            <span>
              <i className="fas fa-check-circle"></i>
              {questions.length} Questions Added
            </span>
          </div>
        )}
      </div>

      {/* Modal for Adding/Editing Questions */}
      <Modal
        show={modalVisible}
        onHide={cancelAddingQuestions}
        className="custome_questions"
        backdrop="static"
      >
        <div className="custome_questions_inner">
          <div className="custome_questions_inner_head">
            <h6>Custom AI Avatar Interview Questions</h6>
            <p>
              Custom questions allow you to personalize the interview process to fit your
              hiring needs. By adding these tailored questions, the AI avatar will ask
              candidates exactly what you want to know, ensuring the interview aligns with
              your preferences and goals.
            </p>
          </div>
          <div className="custome_questions_inner_body">
            {/* Render existing questions with inline editing */}
            {questions.length > 0 && (
              <div className="ques_add_tag">
                {questions.map((question, index) => (
                  <div key={index} className="custome_questions_single">
                    <div className="custome_questions_single_left" style={{ width: "90%" }}>
                      <h6 style={{display: "flex", alignItems: "center"}}>
                        {index + 1}.{" "}
                        {editing.hasOwnProperty(index) ? (
                          <Input
                            value={editing[index]}
                            onChange={(e) =>
                              handleEditingChange(index, e.target.value)
                            }
                            className="edit-question-interview-input"
                          />
                        ) : (
                          question
                        )}
                      </h6>
                    </div>
                    <div className="custome_questions_single_right">
                      <ul>
                        {editing.hasOwnProperty(index) ? (
                          <>
                            <li onClick={() => saveEdit(index)}>
                              <i className="fas fa-check"></i>
                            </li>
                            <li onClick={() => cancelEdit(index)}>
                              <i className="fas fa-times"></i>
                            </li>
                          </>
                        ) : (
                          <>
                            <li onClick={() => startEditing(index)}>
                              <i className="fas fa-edit"></i>
                            </li>
                            <li onClick={() => handleDelete(index)}>
                              <i className="fas fa-trash-alt"></i>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {/* Text area for adding a new question */}
            <Form.Item rules={[]}>
              {questions.length >= 5 ? (
                <p className="max-questions-message">
                  Maximum of 5 custom questions allowed. Edit an existing question if needed.
                </p>
              ) : (
                <Input.TextArea
                  placeholder="Type your question here"
                  value={newQuestion}
                  onChange={(e) => setNewQuestion(e.target.value)}
                  rows={5}
                  className="custome_questions_single_textarea"
                />
              )}
            </Form.Item>

            <div className="add_ques_btn">
              <button
                onClick={handleAddNewQuestion}
                disabled={!newQuestion.trim() || questions.length >= 5}
                className={`job-details-modal-add-question-button ${
                  !newQuestion.trim() || questions.length >= 5
                    ? "job-details-modal-add-question-button-disabled"
                    : ""
                }`}
              >
                <i className="fas fa-plus"></i> Add Question
              </button>
            </div>
          </div>
        </div>
        <div className="job-details-add-cancel-btn-sec-modal">
          <button onClick={cancelAddingQuestions} className="draft_btn">
            Cancel
          </button>
          <button onClick={onClickAddQuestion} className="save_btn">
            {/* {questions.length > 0 ? "Update" : "Save"} */}
            Save
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AiAvatarInterviewQuestion;
